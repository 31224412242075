import { IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { useEffect } from "react";
import styled from "styled-components";
import { utils } from "../../../helpers/utils";
import useLang from "../../../hooks/useLang";
import useModal from "../../../hooks/useModal";
import useZarboutans from "../../../hooks/useZarboutans";
import { intl } from "../../../translations";
import Button from "../../Common/Buttons/Button";
import DirectionMap from "../../Common/DirectionMap/DirectionMap";
import {
  DirectionIcon,
  ListenedIcon,
  VisitedIcon,
} from "../../Common/ZarboutanIcons/ZarboutanIcons";
import AudioDescription from "./AudioDescription/AudioDescription";
import AudioPlayer from "./AudioPlayer/AudioPlayer";
import BackAndStopButton from "./BackAndStopButton";

const Zarboutan = (props) => {
  const { lang } = useLang();
  const { openModal } = useModal();
  const {
    currentZarboutan,
    loadZarboutan,
    onZarboutanPlay,
    setZarboutanIdVisited,
    onOpenDescription,
    onCloseDescription,
    isDescriptionOpen,
    isDescriptionButtonVisible,
  } = useZarboutans();
  const zarboutanId = props?.match?.params?.id;

  const openDirectionModal = () => {
    openModal({
      title: currentZarboutan.name[lang],
      content: <DirectionMap coordinates={currentZarboutan.coordinates} />,
    });
  };

  useEffect(() => {
    if (zarboutanId) {
      loadZarboutan(zarboutanId);
      if (utils.getSearchParams(props?.location?.search).qr) {
        setZarboutanIdVisited(zarboutanId);
      }
      utils.replaceUrl(props.location.pathname);
      var audioDurationElem = document.querySelector(".audio-duration");
      if (audioDurationElem) audioDurationElem.style.display = "block";
    } else {
      props.history.push("/home");
    }
  }, [zarboutanId]);

  return (
    <IonPage>
      {!currentZarboutan ? null : (
        <>
          {" "}
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <BackAndStopButton routerProps={props} />
              </IonButtons>
              <IonButtons slot="end" style={{ paddingRight: "1rem" }}>
                {currentZarboutan.isVisited && (
                  <VisitedIcon
                    style={{ margin: "0 12px" }}
                    className="animate__animated animate__rotateIn animate__delay-2s"
                  />
                )}
                {currentZarboutan.isListened && (
                  <ListenedIcon
                    style={{ margin: "0 12px" }}
                    className="animate__animated animate__bounceIn animate__delay-1s"
                  />
                )}
                <DirectionIcon
                  style={{ margin: "0 12px" }}
                  className=""
                  onClick={openDirectionModal}
                />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent scrollY={false}>
            <ZarboutanContainer>
              <ZarboutanNameContainer className="animate__animated animate__fadeInDown">
                <div className="zarboutan-name ">
                  <div className="zarboutan-location">{currentZarboutan.locationName[lang]}</div>
                  <span>{currentZarboutan.name[lang]}</span>
                </div>
              </ZarboutanNameContainer>
              <PlayerSection isDescriptionOpen={isDescriptionOpen}>
                <img
                  alt=""
                  className="bg-image animate__animated animate__zoomIn"
                  src="/assets/images/player-bg-noring.svg"
                />
                <div className="audio-name">
                  {currentZarboutan.audioDescription.audioName[lang]}
                </div>
                <PlayerContainer isDescriptionOpen={isDescriptionOpen}>
                  <AudioPlayer onPlay={onZarboutanPlay} />
                  {!isDescriptionOpen && isDescriptionButtonVisible && (
                    <Button
                      className="subtitles-btn"
                      text={intl.Pages.Zarboutan.DisplaySubtitles[lang]}
                      noArrow
                      small
                      noBorder
                      transparent
                      onClick={onOpenDescription}
                    />
                  )}
                </PlayerContainer>

                <div className="audio-duration">
                  {intl.Pages.Zarboutan.Duration[lang]} :{" "}
                  {currentZarboutan.audioDescription.duration} {intl.Pages.Zarboutan.Minutes[lang]}
                </div>
              </PlayerSection>
              {isDescriptionOpen && <AudioDescription closeDescription={onCloseDescription} />}
            </ZarboutanContainer>
          </IonContent>
        </>
      )}
    </IonPage>
  );
};

const ZarboutanContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: url(/assets/images/splash-bg.png);
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: 100%;
`;

const ZarboutanNameContainer = styled.div`
  text-align: center;
  font-weight: 400;
  color: black;
  font-size: 24px;
  height: 8%;
  text-align: center;
  margin-bottom: 16px;

  & .zarboutan-name {
    position: relative;
    height: 100%;
    text-transform: uppercase;

    & .zarboutan-location {
      font-size: 12px;
    }
  }
`;

const PlayerSection = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;

  & .bg-image {
    max-width: none;
    position: relative;
    width: 130%;
    left: -15%;
  }

  & .audio-name {
    position: absolute;
    color: var(--ion-color-primary);
    z-index: 9;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    display: ${({ isDescriptionOpen }) => (isDescriptionOpen ? "none" : "block")};
    white-space: nowrap;
  }
  & .audio-duration {
    position: absolute;
    color: var(--ion-color-primary);
    z-index: 9;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
    display: ${({ isDescriptionOpen }) => (isDescriptionOpen ? "none" : "block")};
  }
`;

const PlayerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ isDescriptionOpen }) => (isDescriptionOpen ? "50%" : "100%")};
  transition: 500ms;

  & .subtitles-btn {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    text-decoration: underline;
    color: var(--ion-color-primary);
  }
`;

export default Zarboutan;
