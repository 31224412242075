import { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { audioDescriptionConfigAtom } from "../../../../atoms/zarboutanAtoms";
import useAudio from "../../../../hooks/useAudio";
import useZarboutans from "../../../../hooks/useZarboutans";

const useAudioDescription = () => {
  const [config] = useAtom(audioDescriptionConfigAtom);
  const { currentZarboutan } = useZarboutans();
  const { status, goToTime } = useAudio();
  const currentConfig = config[currentZarboutan.id];
  const [activeSentenceIndex, setActiveSentenceIndex] = useState(0);

  useEffect(() => {
    if (activeSentenceIndex == currentConfig.sentences.length - 1 && window.umami) {
      window.umami(`Story listening complete (${currentZarboutan.audioDescription.audioName.FR})`);
    }
  }, [activeSentenceIndex]);

  useEffect(() => {
    let newActiveSentenceIndex = 0;
    currentConfig.sentences.forEach((sentence, sindex) => {
      if (status.progressTime >= sentence.startSecond && status.progressTime < sentence.endSecond) {
        newActiveSentenceIndex = sindex;
      }
    });
    if (newActiveSentenceIndex != activeSentenceIndex) {
      setActiveSentenceIndex(newActiveSentenceIndex);
      var sentenceElem = document.querySelector(`#sentence${newActiveSentenceIndex}`);
      if (sentenceElem) {
        sentenceElem.scrollIntoView({ block: "center" });
      }
    }
  }, [status.progressTime]);

  const getStatus = (sindex) => {
    return {
      isPast: sindex < activeSentenceIndex,
      isActive: sindex == activeSentenceIndex,
    };
  };

  const goToSentence = (sentence) => {
    goToTime(sentence.startSecond);
  };

  return {
    activeSentenceIndex,
    config: config[currentZarboutan.id],
    currentTime: status.progressTime,
    isPaused: !status.isPlaying,
    goToTime,
    getStatus,
    goToSentence,
  };
};

export default useAudioDescription;
