import React from "react";
import styled from "styled-components";
import useLang from "../../../hooks/useLang";
import { SlideText, SlideTitle } from "../../../StyledComponents/StyleComponents";
import { intl } from "../../../translations";
import Button from "../../Common/Buttons/Button";
import GoToNextSlideButton from "./GoToNextSlideButton";
import SlideContainer from "./SlideContainer";

const LanguageSlide = () => {
  const { lang, changeLang } = useLang();

  const langButtons = [
    { lang: "CR", text: "Kréol", selected: lang == "CR" },
    { lang: "FR", text: "Français", selected: lang == "FR" },
    { lang: "EN", text: "English", selected: lang == "EN" },
  ];

  return (
    <SlideContainer
      imageName="language.jpg"
      nextButton={
        <GoToNextSlideButton text={intl.Pages.Onboarding.LanguageSlide.ButtonText[lang]} />
      }
    >
      <SlideTitle>{intl.Pages.Onboarding.LanguageSlide.Title[lang]}</SlideTitle>
      <SlideText>{intl.Pages.Onboarding.LanguageSlide.Text[lang]}</SlideText>
      <StyledBtnContainer>
        {langButtons.map((btn) => (
          <Button
            key={`langbtn${btn.text}`}
            className={`${btn.selected ? "selected" : ""}`}
            noArrow
            onClick={() => changeLang(btn.lang)}
            text={btn.text}
            style={{ width: 150, height: 42, minHeight: 42 }}
          />
        ))}
      </StyledBtnContainer>
    </SlideContainer>
  );
};

const StyledBtnContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
export default LanguageSlide;
