import styled from "styled-components";
import { keyframes } from "styled-components";
import useAudio from "../../../../../hooks/useAudio";

const bars = [
  { id: 1, index: Math.random() * 4 },
  { id: 2, index: Math.random() * 4 },
  { id: 3, index: Math.random() * 4 },
  { id: 4, index: Math.random() * 4 },
  { id: 5, index: Math.random() * 4 },
  { id: 6, index: Math.random() * 4 },
  { id: 7, index: Math.random() * 4 },
];

const Wave = ({ isActive }) => {
  const { status } = useAudio();

  return (
    <Bars>
      {bars.map((bar) => (
        <Bar
          isActive={isActive}
          index={bar.index}
          uid={bar.id}
          key={`bar${bar.id}`}
          className="bar"
        ></Bar>
      ))}
    </Bars>
  );
};

const danceAnim = keyframes`
0% {
  transform:scaleY(0.5)
}
40% {
  transform:scaleY(0.9) ;
}
100% {
  transform:scaleY(0.5) ;
}
`;

const Bars = styled.div`
  position: absolute;
  width: 130%;
  height: 100%;
  left: -15%;
  /* width: 159%;
  height: 74%;
  left: -28%; */
  bottom: 0;
  z-index: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-radius: 50%;
  overflow: hidden;
  padding-bottom: 2px;
  transform: scale(0.98);

  & div {
    background: rgba(255, 255, 255, 0.19);
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    width: calc(100% / 8);
    height: 60%;
    box-shadow: 0px -15px 15px -14px rgba(49, 125, 108, 0.13);
  }
`;

const Bar = styled.div`
  transform-origin: bottom;
  transform: scaleY(0.5);
  transition: 400ms;
  animation-name: ${danceAnim};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-delay: ${({ index }) => index}s;
  animation-play-state: ${({ isActive }) => (isActive ? "running" : "paused")};
`;

export default Wave;
