import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

export const listenedZarboutansAtom = atomWithStorage("FAHAM_LISTENED", []);
export const visitedZarboutansAtom = atomWithStorage("FAHAM_VISITED", []);
export const isDescriptionOpenAtom = atom(false);
export const zarboutansAtom = atom([
  {
    isActive: true,
    comingAt: {
      FR: "Date à venir",
      EN: "upcoming date",
      CR: "Date à venir",
    },
    id: "1",
    name: {
      FR: "le tevelave",
      EN: "le tevelave",
      CR: "le tevelave",
    },
    locationName: {
      FR: "les avirons",
      EN: "les avirons",
      CR: "les avirons",
    },
    audioDescription: {
      audioName: {
        FR: "L'enfant de la forêt",
        EN: "The child of the forest",
        CR: "Zanfan la foré",
      },
      duration: 10,
      file: {
        url: "zanfan-la-fore.mp3",
        id: "1",
      },
    },
    coordinates: {
      lat: "-21.205865",
      lng: "55.356049",
    },
  },
  {
    isActive: true,
    comingAt: {
      FR: "Date à venir",
      EN: "upcoming date",
      CR: "Date à venir",
    },
    id: "9",
    imageOffset: { x: 8 },
    name: {
      FR: "boucle du curcuma",
      EN: "boucle du curcuma",
      CR: "boucle du curcuma",
    },
    locationName: {
      FR: "saint-joseph",
      EN: "saint-joseph",
      CR: "saint-joseph",
    },
    audioDescription: {
      audioName: {
        FR: "Mademoiselle Safran",
        EN: "Miss Saffron",
        CR: "Mamzél Safran",
      },
      duration: 14,
      file: {
        url: "mamzel-safran.mp3",
        id: "9",
      },
    },
    coordinates: {
      lat: "-21.32763739866224",
      lng: "55.61007257116442",
    },
  },
  {
    isActive: true,
    comingAt: {
      FR: "1er octobre",
      EN: "october 1st",
      CR: "1er octobre",
    },
    id: "7",
    imageOffset: { x: -12 },
    name: {
      FR: "domaine vidot",
      EN: "domaine vidot",
      CR: "domaine vidot",
    },
    locationName: {
      FR: "saint-pierre",
      EN: "saint-pierre",
      CR: "saint-pierre",
    },
    audioDescription: {
      audioName: {
        FR: "Lo Rwa Yab",
        EN: "Lo Rwa Yab",
        CR: "Lo Rwa Yab",
      },
      duration: 15,
      file: {
        url: "lo-rwa-yab.mp3",
        id: "8",
      },
    },
    coordinates: {
      lat: "-21.312323093417113",
      lng: "55.548514672127716",
    },
  },
  {
    isActive: true,
    comingAt: {
      FR: "8 Octobre",
      EN: "October 8",
      CR: "8 Octobre",
    },
    id: "10",
    imageOffset: { x: 0 },
    name: {
      FR: "puits arabe",
      EN: "puits arabe",
      CR: "puits arabe",
    },
    locationName: {
      FR: "saint-philippe",
      EN: "saint-philippe",
      CR: "saint-philippe",
    },
    audioDescription: {
      audioName: {
        FR: "Lo kèr dann tramay",
        EN: "Lo kèr dann tramay",
        CR: "Lo kèr dann tramay",
      },
      duration: 12,
      file: {
        url: "lo-ker-dann-tramay.mp3",
        id: "4",
      },
    },
    coordinates: {
      lat: "-21.340396343437043",
      lng: "55.803327442293764",
    },
  },
  {
    isActive: true,
    comingAt: {
      FR: "12 Novembre",
      EN: "November 12",
      CR: "12 Novembre",
    },
    id: "8",
    name: {
      FR: "domaine du relais",
      EN: "domaine du relais",
      CR: "domaine du relais",
    },
    locationName: {
      FR: "petite-île",
      EN: "petite-île",
      CR: "petite-île",
    },
    audioDescription: {
      audioName: {
        FR: "Po in lodèr lamour",
        EN: "Po in lodèr lamour",
        CR: "Po in lodèr lamour",
      },
      duration: 10,
      file: {
        url: "po_in_loder_lamour.mp3",
        id: "9",
      },
    },
    coordinates: {
      lat: "-21.3262828578608",
      lng: "55.58542899501494",
    },
  },
  {
    isActive: true,
    comingAt: {
      FR: "Date à venir",
      EN: "upcoming date",
      CR: "Date à venir",
    },
    id: "5",
    name: {
      FR: "l'arboretum",
      EN: "l'arboretum",
      CR: "l'arboretum",
    },
    locationName: {
      FR: "entre-deux",
      EN: "entre-deux",
      CR: "entre-deux",
    },
    audioDescription: {
      audioName: {
        FR: "Zoizo la liberté",
        EN: "Zoizo la liberté",
        CR: "Zoizo la liberté",
      },
      duration: 8,
      file: {
        url: "zoizo-la-liberte.mp3",
        id: "6",
      },
    },
    coordinates: {
      lat: "-21.224020302783764",
      lng: "55.47531553787978",
    },
  },
  {
    isActive: true,
    comingAt: {
      FR: "Date à venir",
      EN: "upcoming date",
      CR: "Date à venir",
    },
    id: "3",
    name: {
      FR: "les makes",
      EN: "les makes",
      CR: "les makes",
    },
    locationName: {
      FR: "saint-louis",
      EN: "saint-louis",
      CR: "saint-louis",
    },
    audioDescription: {
      audioName: {
        FR: "Zékli zétoil",
        EN: "Zékli zétoil",
        CR: "Zékli zétoil",
      },
      duration: 11,
      file: {
        url: "zeklizetoil.mp3",
        id: "3",
      },
    },
    coordinates: {
      lat: "-21.21083849160313",
      lng: "55.39914281037934",
    },
  },
  {
    isActive: true,
    comingAt: {
      FR: "Date à venir",
      EN: "upcoming date",
      CR: "Date à venir",
    },
    id: "4",
    name: {
      FR: "Plateau des chênes",
      EN: "Plateau des chênes",
      CR: "Plateau des chênes",
    },
    locationName: { FR: "cilaos", EN: "cilaos", CR: "cilaos" },
    audioDescription: {
      audioName: {
        FR: "Zakaranda an flèr",
        EN: "Zakaranda an flèr",
        CR: "Zakaranda an flèr",
      },
      duration: 12,
      file: {
        url: "zakaranda-an-flèr.mp3",
        id: "4",
      },
    },
    coordinates: {
      lat: "-21.12652498843544",
      lng: "55.47414852461891",
    },
  },
  {
    isActive: true,
    comingAt: {
      FR: "Date à venir",
      EN: "upcoming date",
      CR: "Date à venir",
    },
    id: "2",
    name: {
      FR: "les canots",
      EN: "les canots",
      CR: "les canots",
    },
    locationName: {
      FR: "étang salé",
      EN: "étang salé",
      CR: "étang salé",
    },
    audioDescription: {
      audioName: {
        FR: "In Kanot dann piédbwa",
        EN: "In Kanot dann piédbwa",
        CR: "In Kanot dann piédbwa",
      },
      duration: 10,
      file: {
        url: "in-kanot-dann-piedbwa.mp3",
        id: "2",
      },
    },
    coordinates: {
      lat: "-21.247747",
      lng: "55.363183",
    },
  },
  {
    isActive: true,
    comingAt: {
      FR: "Date à venir",
      EN: "upcoming date",
      CR: "Date à venir",
    },
    id: "6",
    name: {
      FR: "notre-dame de la paix",
      EN: "notre-dame de la paix",
      CR: "notre-dame de la paix",
    },
    locationName: {
      FR: "le tampon",
      EN: "le tampon",
      CR: "le tampon",
    },
    audioDescription: {
      audioName: {
        FR: "La nuit domi rouvèr",
        EN: "La nuit domi rouvèr",
        CR: "La nuit domi rouvèr",
      },
      duration: 10,
      file: {
        url: "la-nuit-domi-rouvèr.mp3",
        id: "6",
      },
    },
    coordinates: {
      lat: "-21.237187",
      lng: "55.571552",
    },
  },
]);
export const currentZarboutanAtom = atom(null);
export const isDescriptionButtonVisibleAtom = atom(false);
export const audioDescriptionConfigAtom = atom({
  1: {
    sentences: [
      {
        startSecond: 0,
        endSecond: 29,
        text: {
          CR: "Tévelave... Isi-an-ô i port lo non Tévelave. La grande forêt an langaz malougasé.",
          FR: "Tévelave... Les gens d'ici l'appellent Tévelave. La grande forêt en langue malgache.",
          EN: "",
        },
      },
      {
        startSecond: 29,
        endSecond: 38,
        text: {
          CR: "Akout, lo margonyman brouyar dann la pant, lo batbaté tanbour bann Faons, akout...",
          FR: "Écoute, le murmure du brouillard qui monte, les tambours du Roi Phaonce, écoute...",
          EN: "",
        },
      },
      {
        startSecond: 38,
        endSecond: 50,
        text: {
          CR: "Isi dann tan davan lo tan zavan dann rin ranpar, zistoir té krik piédboi té ki krak.",
          FR: "Ici du temps où les mauvais âmes couraient les remparts, les histoires krikaient, les arbres craquaient.",
          EN: "",
        },
      },
      {
        startSecond: 50,
        endSecond: 55,
        text: {
          CR: "Gramoun i di isi-an-ô navé in boug navé po lo non : Bonom.",
          FR: "Les vieux racontent qu'ici était un homme qui avait pour nom : Bonom.",
          EN: "",
        },
      },
      {
        startSecond: 55,
        endSecond: 60,
        text: {
          CR: "Bonom lé né terla, rant dé rasine boi-d'zakasia.",
          FR: "Bonom était né là, entre les racines du bois d'acacia.",
          EN: "",
        },
      },
      {
        startSecond: 60,
        endSecond: 70,
        text: {
          CR: "Ki k'lété son moman-papa, lo zistoir minm i ansouvyin pa. Mé sat nou nankor souvnans, sé ké li té tonm gardyin la foré.",
          FR: "Qui était son père, sa mère, l'histoire même ne s'en souvient pas. Mais ce dont elle se souvient toujours, c'est qu'il était le gardien de la forêt.",
          EN: "",
        },
      },
      {
        startSecond: 70,
        endSecond: 83,
        text: {
          CR: "Frèr fanjan, dalon fouzér, kamarad flèrzone. Dopi lo promié ti-mizing rasine, ziska lo dernié bék boi d'péroké. Li té pran l'soin.",
          FR: "Frère du fanjan, ami des fougères, compagnon des fleurs jaunes. Depuis la première racine jusqu'au dernier bec des pieds-de-perroquet. Il prenait soin de la forêt entière.",
          EN: "",
        },
      },
      {
        startSecond: 83,
        endSecond: 89,
        text: {
          CR: "Poin d'fanm, poin d'zanfan, mé in gran fami piédboi.",
          FR: "Ni femme, ni enfant, mais une immense famille d'arbres.",
          EN: "",
        },
      },
      {
        startSecond: 89,
        endSecond: 103,
        text: {
          CR: "É anparmi, l'arbre roi. Un immense tamarinier, le dernier de la longue lignée des tamarins rois des Sakalaves. Venu dans le ventre d'un oiseau fécond de la grande île jusqu'au pays bourbon.",
          FR: "Et parmi eux, l'arbre roi. Un immense tamarinier, le dernier de la longue lignée des tamarins rois des Sakalaves. Venu dans le ventre d'un oiseau fécond de la Grande Ile jusqu'au pays Bourbon.",
          EN: "",
        },
      },
      {
        startSecond: 103,
        endSecond: 113,
        text: {
          CR: "Li lavé pous la, néna tanto troizan zan de sa. É dopi li té li té vey si son roiyom, Tévelave. La grande forêt.",
          FR: "Il avait poussé là, il y a trois cents ans de cela. Et depuis, il veillait sur son royaume, Tévelave. La grande forêt.",
          EN: "",
        },
      },
      {
        startSecond: 113,
        endSecond: 119,
        text: {
          CR: "É Bonom té ki vey si lu.",
          FR: "Et bonom veillait sur lui.",
          EN: "",
        },
      },
      {
        startSecond: 119,
        endSecond: 125,
        text: {
          CR: "Bonom lavé touzour viv la minm, din boukan d'boi dann lonbraz son dalon lo roi.",
          FR: "Bonom avait toujours vécu ici, dans une vieille case à l'ombre de son ami le roi.",
          EN: "",
        },
      },
      {
        startSecond: 124,
        endSecond: 129,
        text: {
          CR: "In zour, dann lèr fénoir, li antann déor in voi d'fanm.",
          FR: "Un jour, presque à la nuit, il entendit une voix de femme.",
          EN: "",
        },
      },
      {
        startSecond: 129,
        endSecond: 138,
        text: {
          CR: "« J'ai soif ». Au pied du gran tamarinier in madame. « J'ai soif ».",
          FR: "« J'ai soif ». Au pied du grand tamarinier, une femme. « J'ai soif ».",
          EN: "",
        },
      },
      {
        startSecond: 138,
        endSecond: 144,
        text: {
          CR: "Lo promié mé lo pli zoli ké li lavé zamé vu. « J'ai soif ».",
          FR: "La première et la plus belle qu'il n'ait jamais vu. « J'ai soif ».",
          EN: "",
        },
      },
      {
        startSecond: 144,
        endSecond: 159,
        text: {
          CR: "San fason, li la rouv la port. San rod ki ké lété, ou ké té sort lo manzélé, li la kour sof in tizane. Lo kèr kontanté tinn, kinm in ti-ninstan, son tousélminm.",
          FR: "Sans façon, il lui avait ouvert la porte. Sans chercher qui elle était, d'où elle venait, il lui avait fait de la tisane. Le coeur content d'éteindre, même un instant, sa solitude.",
          EN: "",
        },
      },
      {
        startSecond: 159,
        endSecond: 171,
        text: {
          CR: "É kom dé moun té ki koné dopi lontan, Bonom ék lo madame la koz tout la nwit. Kan bardzour la komans féklèr la rasine tamarinié él la ropran somin.",
          FR: "Et comme deux vieux amis, ils parlèrent toute la nuit, et à l'aube, elle s'en alla.",
          EN: "",
        },
      },
      {
        startSecond: 171,
        endSecond: 184,
        text: {
          CR: "Dann la fré granmatin la, Bonom la santi son kèr plin. In gine ék sagrin artrouv son tousélminm, mé sirtou ék in santiman li té koné minm pa l'nom.",
          FR: "Dans le froid du début du jour, Bonom sentit son coeur se remplir d'un peu de chagrin et de solitude, mais surtout d'un sentiment dont il ne savait même pas le nom.",
          EN: "",
        },
      },
      {
        startSecond: 184,
        endSecond: 191,
        text: {
          CR: "« Zanakal, je m'appelle Zanakal. »",
          FR: "« Zanakal, je m'appelle Zanakal. »",
          EN: "",
        },
      },
      {
        startSecond: 191,
        endSecond: 203,
        text: {
          CR: "Sé pa si son kèr té ki tanbour sitantelamn for ké lavé travérs la foré, sé pa... Mé tibrine té pinn rantré ké lo madame té la.",
          FR: "Qui sait si le tambour de son coeur avait cogné jusqu'à traverser la forêt, qui sait... Mais la nuit arrivait à peine que la femme était déjà là.",
          EN: "",
        },
      },
      {
        startSecond: 203,
        endSecond: 212,
        text: {
          CR: "Dobout dosou pié tamarin : « Je m'appelle Zanakal, et j'ai soif. »",
          FR: "Debout sous le tamarinier : « Je m'appelle Zanakal, et j'ai soif. »",
          EN: "",
        },
      },
      {
        startSecond: 212,
        endSecond: 219,
        text: {
          CR: "Gramoun la di komsa : « fo trouv la sours avan trouv la soif ». É Bonom té ki koné tout tizane la foré.",
          FR: "Les vieux disent « trouve la source avant d'avoir soif ». Et Bonom connaissait par coeur toutes les tisanes de la forêt.",
          EN: "",
        },
      },
      {
        startSecond: 219,
        endSecond: 228,
        text: {
          CR: "Flérzone po rafréshisman, bois darnét po rumatisme, boi zolivnoir po la tansion, faham po la grip, bois de jolicoeur...",
          FR: "Flèrzone pour les inflammations, boi-darnét pour les rhumatismes, boi-zoliv-noir pour la tension, faham pour la grippe, bois de jolicoeur...",
          EN: "",
        },
      },
      {
        startSecond: 228,
        endSecond: 243,
        text: {
          CR: "Bois de jolicoeur po détousél la nwit, po arnoirsi féklèr, po défann soley lévé, po démavouz lo kor kan Zanakal la ropran somin dann granmatin.",
          FR: "Bois de jolicoeur pour tuer les nuits de solitude, pour assombrir l'aube, pour rendormir le soleil, pour gommer la tristesse du départ de Zanakal au petit matin.",
          EN: "",
        },
      },
      {
        startSecond: 243,
        endSecond: 258,
        text: {
          CR: "Asoir, él la di asoir. Bonom lo kèr té ki bat dann do. Asoir, asoir, asoir, asoir, asoir.",
          FR: "« À ce soir ». Bonom avait le coeur qui lui déchirait la poitrine. « À ce soir, à ce soir, à ce soir, à ce soir ».",
          EN: "",
        },
      },
      {
        startSecond: 258,
        endSecond: 265,
        text: {
          CR: "Prés in lané d'asoir lavé pasé. In lané do lo zerbaz si l'fé, a tizane lo kèr in a lot.",
          FR: "Presqu'une année de « à ce soir »  était passée. Une année près du feu, à se tisaner le coeur.",
          EN: "",
        },
      },
      {
        startSecond: 265,
        endSecond: 269,
        text: {
          CR: "Navé poin d'sours, poin d'santié, poin d'gafourn, kél ti i koné pa l'nom.",
          FR: "Il n'y avait pas une source, pas un sentier, pas une caverne, dont elle ne savait le nom.",
          EN: "",
        },
      },
      {
        startSecond: 269,
        endSecond: 285,
        text: {
          CR: "Él té ki koz langaz toulit, timizo, timiz, papang. Ék Zanakal, Bonom lavé trouv son flèr faham, son zorkidé sovaz, son fi la foré.",
          FR: "Elle parlait la langue des toulits, des tangs, des pétrels, des papangues. Avec Zanakal, Bonom avait trouvé sa fleur Faham, son orchidée sauvage, sa fille de la forêt.",
          EN: "",
        },
      },
      {
        startSecond: 285,
        endSecond: 294,
        text: {
          CR: "In soir, li la mét tout son kouraz dann son kréd'min é dosou l'pié tamarin, li la pass aznou.",
          FR: "Un soir, tout son courage entre ses mains, sous le tamarinier, il s'était mis à genoux.",
          EN: "",
        },
      },
      {
        startSecond: 294,
        endSecond: 299,
        text: {
          CR: "« és, és, wi... »",
          FR: "« Est-ce que tu... »",
          EN: "",
        },
      },
      {
        startSecond: 299,
        endSecond: 314,
        text: {
          CR: "Oui la di Zanakal. So soir la, po promié foi, la nwit la pass san Kozé, tout zot bann kozman angaroté din shonbli lamour.",
          FR: "Oui, avait dit Zanakal. Ce soir-là, pour la première fois, ils ne parlèrent pas, la parole baillonnée par l'amour.",
          EN: "",
        },
      },
      {
        startSecond: 314,
        endSecond: 320,
        text: {
          CR: "Ziska gran matin , ousa ké Zanakal la ropran somin la foré.",
          FR: "Jusqu'au matin, où Zanakal avait repris le chemin de la forêt.",
          EN: "",
        },
      },
      {
        startSecond: 320,
        endSecond: 328,
        text: {
          CR: "Gramoun i di ankor : forstan kour dérièr soley ou riskab oubli la nuit.",
          FR: "Les vieux disent encore : à trop chercher le soleil, on en oublie la nuit.",
          EN: "",
        },
      },
      {
        startSecond: 328,
        endSecond: 343,
        text: {
          CR: "Bonom an longèr d'zourné té ki atann fénoir. É tout granmatin té ki mét son kèr an traka. É si in soir él té i arvyin pi, é si dann la zourné él té i trouv in not, é si, é si...",
          FR: "Bonom passait ses journées à attendre que la nuit tombe. Et à chaque aube, son coeur était torturé. Et si elle ne revenait plus, et si elle en trouvait un plus beau, et si, et si...",
          EN: "",
        },
      },
      {
        startSecond: 343,
        endSecond: 354,
        text: {
          CR: "« Ai confiance ». La di Zanakal é sé sat Bonom la fé. Konfians.",
          FR: "« Aie confiance ». Dit Zanakal et c'est ce que Bonom apprit à faire. Confiance.",
          EN: "",
        },
      },
      {
        startSecond: 354,
        endSecond: 356,
        text: {
          CR: "Sé tin zoli mo zot i trouv pa ?",
          FR: "C'est un joli mot vous ne trouvez pas ?",
          EN: "",
        },
      },
      {
        startSecond: 356,
        endSecond: 390,
        text: {
          CR: "Toulézannéfmoi, in zour Zanakal la mét o monn in tibaba, dann bèr la rasine lo gran tamarinié. É kom navé poin d'pli zoli mo po di zot lamour zot lapél a lu : Confiance.",
          FR: "Une année à neuf mois, une nuit Zanakal mis au monde un enfant, au milieu des racines du grand tamarinier. Et puisqu'il n'y avait pas de plus joli mot pour dire leur amour, l'enfant s'appela : Confiance.",
          EN: "",
        },
      },
      {
        startSecond: 390,
        endSecond: 403,
        text: {
          CR: "É lo tan la pasé, la vi dou kom in briz van la foré. Ziska in soir de gran siklone ou ké Zanakal lavé rant dann la kaz ék dann son zié in gran sagrin.",
          FR: "Et la vie passait, douce comme une brise de vent de forêt. Jusqu'à ce soir de cyclone où Zanakal était venue les yeux remplis de chagrin.",
          EN: "",
        },
      },
      {
        startSecond: 403,
        endSecond: 407,
        text: {
          CR: "« Bonom sof in tizane, fo ni koz. »",
          FR: "« Bonom fais-moi une tisane, il faut qu'on parle. »",
          EN: "",
        },
      },
      {
        startSecond: 407,
        endSecond: 424,
        text: {
          CR: "« Daborinn, mi di aou mersi, po lamour, po nout baba, po tout granmatin ou té angard amoin partir, san douté ké kom la nwit sak soir moin sré la, koté ou, koté zot. »",
          FR: "« D'abord, je te remercie pour l'amour et notre enfant, pour tous les matins où tu me regardais partir, sans douter que comme la nuit je reviendrai avec toi, avec vous, avec nous. »",
          EN: "",
        },
      },
      {
        startSecond: 424,
        endSecond: 430,
        text: {
          CR: "« Zanakal sa mon nom ou koné. Dann mon péyi i vé dir Zanfan la foré. »",
          FR: "« Zanakal, c'est mon nom, tu le sais. Dans mon pays, cela signifie Enfant de la forêt. »",
          EN: "",
        },
      },
      {
        startSecond: 430,
        endSecond: 445,
        text: {
          CR: "« Avan ét out madam, la moman nout zanfan, moin lété, mi lé ankor, in zéspri, sat lo gran tamarinié. Sat la minm dopi sitan d'tanzané ou pran lo soin. Mersi osi po sa. »",
          FR: "« Avant d'être ta femme, la mère de notre enfant, j'étais, je suis encore, l'âme du grand tamarinier. Celui-là même dont tu t'occupes depuis des années. Merci aussi pour ça. »",
          EN: "",
        },
      },
      {
        startSecond: 445,
        endSecond: 451,
        text: {
          CR: "« Granmatin i ariv la mi sa partir, mé so kou si sora po d'bon. »",
          FR: "« À l'aube je vais partir, pour de bon. »",
          EN: "",
        },
      },
      {
        startSecond: 451,
        endSecond: 465,
        text: {
          CR: "« Dann la pant la foré mi atann avansé in larmé bushron. Zot i di komsa dann sato lo roi na in zarboutan la brizé, é ké naryin ka moin minm, lo pli gran piédboi la foré po niabout siport lo poi lo son palé. »",
          FR: "« Dans la pente, j'entends s'avancer une armée de bûcherons. Ils racontent qu'un des piliers du château du roi s'est brisé, et que je suis le seul arbre capable de le remplacer. »",
          EN: "",
        },
      },
      {
        startSecond: 465,
        endSecond: 471,
        text: {
          CR: "« Bonom i rét anou in nwit »",
          FR: "« Bonom il nous reste une nuit. »",
          EN: "",
        },
      },
      {
        startSecond: 471,
        endSecond: 482,
        text: {
          CR: "Bonom komdesi ranpar lavé rouv sou son pié. Li la pran son sab paré po sann okip bann dékoupèr de l'amour. Mé Zanakal la pass dovan : C'est mon devoir kél la di.",
          FR: "Bonom sentait la forêt s'ouvrir sous ses pieds. Il prit son sabre prêt à se battre avec les bûcherons. Mais Zanakal l'en empêcha : « c'est mon devoir » dit-elle.",
          EN: "",
        },
      },
      {
        startSecond: 482,
        endSecond: 492,
        text: {
          CR: "Astèr i rét aou po fé sat ou la touzour byin fé : prann soin lo restan, lo plisméyèr : nout baba.",
          FR: "Il te reste à faire ce que tu fais le mieux : prendre soin de ce qui reste, de notre enfant.",
          EN: "",
        },
      },
      {
        startSecond: 492,
        endSecond: 507,
        text: {
          CR: "Bonom sanm Zanakal la koz tout la nwit ziska granmatin, é pi, kom tout matin, él la anbras son baba, Bonom, é él la parti. Po d'bon.",
          FR: "Bonom et Zanakal discutèrent toute la nuit. À l'aube, comme à chaque fois, elle embrassa l'enfant, Bonom et s'en alla pour de bon.",
          EN: "",
        },
      },
      {
        startSecond: 507,
        endSecond: 525,
        text: {
          CR: "Sak kou de ash té ki tay lo kèr Bonom an morso. An fin de matiné, din gran dézord, lo gran tamarinié la tonbé.",
          FR: "Chaque coup de hache ouvrait le coeur de Bonom. En fin de matinée, le grand tamarinier tomba.",
          EN: "",
        },
      },
      {
        startSecond: 525,
        endSecond: 533,
        text: {
          CR: "Zistoir i rakont ké kan bann bushron lo roi la sey ral lo kor défin piédboi, lo tamarinié té ki bouz pa.",
          FR: "L'histoire raconte que quand les bûcherons du roi essayèrent de déplacer l'arbre, il ne bougeait pas.",
          EN: "",
        },
      },
      {
        startSecond: 533,
        endSecond: 555,
        text: {
          CR: "An ranfor la kri 200 solda gabié kosto, mé lo tamarinié té ki bouz pa minm. Alorse la mét bonpé bél zorgano, la shinn, ék gro kordaz, lanmar ansanm 30 bourik, 50 shévo. Zéro kalbas la fimé granboi lo tamarinié té ki bouz pa.",
          FR: "En renfort, on fit appeler 200 vaillants soldats, mais l'arbre ne bougeait toujours pas. On mit des organeaux, des chaines, de grosses cordes et on y attacha trente ânes, cinquante chevaux. Mais rien n'y fit, l'arbre ne bougeait pas.",
          EN: "",
        },
      },
      {
        startSecond: 555,
        endSecond: 577,
        text: {
          CR: "Bout'tan lo bann zouvrié la pran ropo, zot kokod'tét trakasé ék parkoman ral piédboi-la. I di sé t'alors ké lo ti-baba d'Zanakal, ké té gainye pinn marshé, laprosh parkoté lo tamarinié, dann son tibout la min li la pran inn bransh é li la di : ",
          FR: "Quand les ouvriers prirent du repos, l'esprit préoccupé par le déplacement de l'arbre, on raconte que l'enfant de Zanakal, qui marchait à peine, s'approcha, prit une branche dans sa petite main et dit : ",
          EN: "",
        },
      },
      {
        startSecond: 576,
        endSecond: 581,
        text: {
          CR: "« Momon anon »",
          FR: "« Maman allons-nous-en »",
          EN: "",
        },
      },
      {
        startSecond: 581,
        endSecond: 599,
        text: {
          CR: "É ké sou lo zié karkiyé de bann bushron, lo gran tamarinié la lévé, la suiv son zanfan.",
          FR: "Et que sous les yeux écarquillés des bûcherons, le grand tamarinier se leva et suivit l'enfant.",
          EN: "",
        },
      },
      {
        startSecond: 599,
        endSecond: 638,
        text: {
          CR: "Kriké, marmay si nou raont zistoir sé po in zour domoun i souvyin. Kraké, zistoir la sé po la mémoir défin Thérésien.",
          FR: "Kriké, je raconte pour celui qui se souvient. Kraké, cette histoire est à la mémoire de feu Thérésien.",
          EN: "",
        },
      },
    ],
  },
  9: {
    sentences: [
      {
        startSecond: 0,
        endSecond: 19,
        text: {
          CR: "Plaine des grégues... Isi-an-o i port lo non Plaine des grégues.",
          FR: "Plaine des Grègues... Cet endroit s'appelle Plaine des Grègues.",
          EN: "",
        },
      },
      {
        startSecond: 19,
        endSecond: 32,
        text: {
          CR: "Akoz sa ? Pars isi-an-o, do lo la pli, kinm li tonm souvan an boufad, a pinn li karés la tèr, li rant vitman dodan po ardsann dann lé ba.",
          FR: "Pourquoi ? Parce qu'ici, la pluie, même si elle tombe souvent, caresse juste la terre et puis s'en va vers les Bas.",
          EN: "",
        },
      },
      {
        startSecond: 32,
        endSecond: 43,
        text: {
          CR: "Kom din grég-kafé si wi vé byin. In cafeutiéreu en français domoun dann lé ba.",
          FR: "Comme dans une grègue de café si tu veux bien. Une cafetiereuhhh en français des gens-des-Bas.",
          EN: "",
        },
      },
      {
        startSecond: 43,
        endSecond: 55,
        text: {
          CR: "Kriké. Skuzmonpardon. Kriké.",
          FR: "Kriké. Excusez-moi. Kriké.",
          EN: "",
        },
      },
      {
        startSecond: 55,
        endSecond: 80,
        text: {
          CR: "Marmay, lèr ki lé zot i doi ét an trinn dir dann zot koko-d'tét, si ti-fi la, néna la konésans an poundiak komkésa, sé ké, san manké, son papa-moman té ki donn a él souvan po manzé rougay sérvél tang sinonsa la pat la mori grié.",
          FR: "Marmailles, à l'heure qu'il est vous êtes sûrement en train de vous dire, si cette fille déborde de tant de connaissances, c'est que, sans aucun doute, ses parents ont dû la nourrir plus que souvent de rougail de cervelle de tang, ou encore de pattes de morues grillées.",
          EN: "",
        },
      },
      {
        startSecond: 80,
        endSecond: 90,
        text: {
          CR: "Mé ké néni la viéye Nini. Inndé promièrman, mi niam pa la yann",
          FR: "Mais non la vieille Manon. Premièrement, je suis végétarienne.",
          EN: "",
        },
      },
      {
        startSecond: 90,
        endSecond: 96,
        text: {
          CR: "Inndé déziéman, i fé byin lontan la mori lé pi bon marshé.",
          FR: "Deuxièmement, ça fait bien longtemps que la morue est hors de prix.",
          EN: "",
        },
      },
      {
        startSecond: 96,
        endSecond: 118,
        text: {
          CR: "Bréf boitbéf, tousala pour vou dire vou zot, ké tout mon savoir de lantan du lontan, ki fé domoin, isi prézan dann zot zorey, inn fine konéséz de la Plaine des Grégues, sé o vié Scholastique ké mi doi.",
          FR: "Bref, tout ça pour vous dire à vous, que tout mon savoir de l'antan du lontan, qui fait de moi, ici présente dans vos oreilles, une fine connaisseuse de la Plaine des Grègues, c'est au vieux Scholastique que je le dois.",
          EN: "",
        },
      },
      {
        startSecond: 118,
        endSecond: 149,
        text: {
          CR: "Misié Scholastique, lo tré respékté ancien Professeur des Hautes Ecoles de France, vénu prann son rotrét isi-an-o minm, dann la fréshèr sinzozéfoiz, sur les conseils de son docteur. Ce afin de sonyé ses deux membres inférieurs gravement atteinte d'affections rhumatismales. Lartroz son dé boi d'zanm si wi profèr.",
          FR: "Misié Scholastique, le très respecté ancien Professeur des Hautes Ecoles de France, venu prendre sa retraite ici-même, dans la fraicheur saint-joséphoise, sur les conseils de son docteur. Ce, afin de soigner ses deux membres inférieurs gravement atteints d'affections rhumatismales. L'arthrose de ses vieilles jambes, si tu préfères.",
          EN: "",
        },
      },
      {
        startSecond: 149,
        endSecond: 177,
        text: {
          CR: "An vérité, Docteur Hoareau, ké lété pa la moitié d'in Maki de Madagascar, lavé konsey a lu mont Cilaos an cure thermale po lo restan de son trépé d'vi. Mé nout vieillissant Misié Scholastique lété a moitié malatandan. Inn moitié du zorey droit, Inn moitié du zorey gosh.",
          FR: "En vérité, Docteur Hoareau, qui n'était pas la moitié d'un Maki de Madagascar, lui avait conseillé de s'installer à Cilaos en cure thermale pour le restant de ses jours. Mais notre vieillissant Misié Scholastique était à moitié malentendant. Une moitié de l'oreille droite, une moitié de l'oreille gauche.",
          EN: "",
        },
      },
      {
        startSecond: 177,
        endSecond: 192,
        text: {
          CR: "Osi, zis apré la konsiltasion, li lavé kour notaire, ashèt troi golét la tèr la Plaine des Grégues ék in désertin Mosié Ibao ké lavé profèr « fuir la vie des hauts » po fé son kaf-salon sin-dni.",
          FR: "Aussi, après la consultation, il avait acheté devant notaire, trois gaulettes de terre à la Plaine des Grègues à un certain Mosié Ibao, qui avait préféré « fuir la vie des hauts » pour jouer au kaf-de-salon dionysien.",
          EN: "",
        },
      },
      {
        startSecond: 192,
        endSecond: 202,
        text: {
          CR: "Rézilta, ék limidité isi-an-o, Misié Scholastique té ki souf lo martir an zourné kom an nuit.",
          FR: "Résultat, avec l'humidité du coin, Misié Scholastique souffrait le martyr jour et nuit.",
          EN: "",
        },
      },
      {
        startSecond: 202,
        endSecond: 233,
        text: {
          CR: "I di dopi la boutik Henri ziska lao ravine des grégues té ki antann son dé zénou grinsé, san kozé dé sé Kri-dé-doulèr qui déchirait la nuit tel un fouquet la pérd bord'mèr. Bref kom di Pépin, tousala po dir azot moin la byin konu misié Scholastique dann mon zinntan.",
          FR: "On raconte que depuis la boutique Henri jusqu'au bord de la ravine des Grègues on entendait ses deux genoux grincer, sans parler de ses cris de douleur qui déchiraient la nuit tel un fouquet perdu. Bref comme disait Pépin, tout ça pour vous dire que, jeune fille, j'ai bien connu Monsieur Scholastique.",
          EN: "",
        },
      },
      {
        startSecond: 233,
        endSecond: 251,
        text: {
          CR: "Parkoman ? Bé foutor de sanmélèr d'ékoutèr d'zistoir é mons lintimité ? És moin mi domann aou akoz out fonn zorey droit lé krouté komkésa moin ? Akout zistoir vréman lé byin, mé i anpés pa l'apropreté.",
          FR: "Dans quelles conditions ? Hé, de quoi tu te mêles toi qui écoutes mon histoire, et mon intimité ? Est-ce que moi je te demande pourquoi tu as l'oreille droite aussi sale ? Ecouter des histoires c'est bien, mais que cela n'empêche pas ta propreté.",
          EN: "",
        },
      },
      {
        startSecond: 251,
        endSecond: 268,
        text: {
          CR: "Kriké ? Rovnon zan à nos zavans. Misié Scholastique donk, lété, kom i di an Français de loin-la-ba, un puits de science.",
          FR: "Kriké ? Revenons-en à nos zavans. Misié Scholastique donc était, comme on dit en français de France, un puits de sciences.",
          EN: "",
        },
      },
      {
        startSecond: 268,
        endSecond: 278,
        text: {
          CR: "Saminm mi pé kado azot zordi tout lo gro ganblo la konésans li la partaz sanm moin. Anpliskésa, gratuitman, san minm aoir bézoin péy laboneman.",
          FR: "C'est bien pour ça, que je peux aujourd'hui vous offrir la marmite de connaissance qu'il a bien voulu me léguer. Et tout ça, gratuitement, sans même avoir besoin de payer un abonnement.",
          EN: "",
        },
      },
      {
        startSecond: 278,
        endSecond: 295,
        text: {
          CR: "Kosa in soz ?  Kél soz ? Pli for mi antann pa. Kél soz ? Kosa in soz ? Kinm mi donn tout, moin nankor tout ?",
          FR: "Kosa in soz ? Kél soz ? Plus fort je n'entends pas. Kél soz ? Kosa in soz ? J'ai tout donné, mais j'ai tout gardé ?",
          EN: "",
        },
      },
      {
        startSecond: 295,
        endSecond: 309,
        text: {
          CR: "Kosa in soz ! Linstriksion mésié médame la sosiété, linstriksion.",
          FR: "Kosa in soz ! L'instruction messieurs mesdames la société, l'instruction.",
          EN: "",
        },
      },
      {
        startSecond: 309,
        endSecond: 329,
        text: {
          CR: "Por ékzanp sé gras Misié Scholastique ké mi koné kan lan 1900 la Plaine des Grégues té lo plise gran léxportèr la Pay-shoushou, é k'isi-an-o, navé karo shoushou partout. É minm ké lo vilaz té sirnomé Le petit Salazie.",
          FR: "Par exemple, c'est grâce à Misié Scholastique que je sais qu'en l'an 1900, la Plaine des Grègues était le plus grand exportateur de paille de chouchou, et qu'il y avait des champs partout. Si bien que le village était surnommé Le petit Salazie.",
          EN: "",
        },
      },
      {
        startSecond: 329,
        endSecond: 346,
        text: {
          CR: "Atann, mi oi si out figir ou l'an trinn domann aou si lé vré. lés amoin dir aou in nafèr misiédame la sosiété : Dann ri, na galé ? Bin dann zistoir na la mantri. Aou minm trié.",
          FR: "Atttends, je vois bien que tu demandes si tout cela est bien vrai. Laisse moi te dire quelque chose misiédam la sosiété : Dans le riz, y'a des galets ? Ben dans les histoires, il y a du mensonge. A toi de faire le tri.",
          EN: "",
        },
      },
      {
        startSecond: 346,
        endSecond: 356,
        text: {
          CR: "La Plaine té séléb osi po kosa ? Son Zérnium ék son rom maron.",
          FR: "La Plaine était aussi célèbre pour ? Son géranium et son rhum maron.",
          EN: "",
        },
      },
      {
        startSecond: 356,
        endSecond: 378,
        text: {
          CR: "An parlan de rom maron, oir de rom zérnium. In soir d'sa, télman an doulèr malozanm ké minm lo rom i gainye pi soulazé, misié Scholastique la kit son trois golét la tèr po alé rod soulazman an vil La Plaine Des Grégues.",
          FR: "En parlant de rhum, voire de rhum géranium. Un soir, tellement souffrant que même le rhum ne le soulageait plus, misié Scholastique quitta sa maison pour descendre chercher de l'aide en ville de La Plaine des Grègues.",
          EN: "",
        },
      },
      {
        startSecond: 378,
        endSecond: 386,
        text: {
          CR: "En ville, c'est un bien grand mot, mé navé in poto la lumière. Alors té i apél sa La Ville.",
          FR: "En ville, c'est un bien grand mot, mais il y avait un lampadaire. Alors on appelait ça La Ville.",
          EN: "",
        },
      },
      {
        startSecond: 386,
        endSecond: 413,
        text: {
          CR: "So soir-la, Misié Scholastique la bat si tout port. Sat misié François ké la konsey la pomad shinoi. Sat gramoune Viviéne ké la konsey in tizane boid'ranpar. Sat Tonton Angélo ké la konsey boir lo rom ankor in pé « si i fé ankor mal sé ou la pa boir asé ».",
          FR: "Ce soir-là, Misié Scholastique cogna à toutes les portes. Chez misié François qui lui avait conseillé la pommade chinoise. Chez la vieille Viviéne qui lui avait conseillé la tisane de bois de rempart. Chez Tonton Angélo qui lui avait conseillé plus de rhum « si tu souffres encore, c'est que tu n'as pas assez bu ».",
          EN: "",
        },
      },
      {
        startSecond: 413,
        endSecond: 427,
        text: {
          CR: "Késésoi tizane krankréla, dékoksion biyé vinfran cfa, lanpitasion zisko monyon, sakinn lo sét zabitan navé inn solision.",
          FR: "Que ce soit la tisane de cafard, la décoction de billets de vingt francs CFA, l'amputation jusqu'aux genoux, chacun des sept habitants avait une solution.",
          EN: "",
        },
      },
      {
        startSecond: 427,
        endSecond: 451,
        text: {
          CR: "Wi, dann s'tan-la, isi-an-o navé sét zabitan. Sét, pa plis, pa moin. É inn, pa konté. Pa konté wi pars... Bin pars té in touranzé, in moun ayèr pli loin, ké personn té ki frékant pa.",
          FR: "Oui, à cette époque, ils étaient sept. Sept, pas plus, pas moins. Et une autre, pas comptée. Pas comptée parce que... Ben parce que c'était une étrangère, quelqu'un d'ailleurs, que personne ne fréquentait.",
          EN: "",
        },
      },
      {
        startSecond: 451,
        endSecond: 462,
        text: {
          CR: "Tansion, la pa ké domoun si-an-o lé méfian, rasis, tousala, non. Té zis pars lo madame té ki sort zamé d'son kaz.",
          FR: "Attention, pas pour des questions de méfiance, de racisme, tout ça, non. C'était juste parce qu'elle ne sortait jamais de chez elle.",
          EN: "",
        },
      },
      {
        startSecond: 462,
        endSecond: 479,
        text: {
          CR: "Akoz ? Pars él té nioran. Non pa nioran kom zot ki ékout mon zistoir san minm konét ki ké sé moin. Non va. Nioran la lang.",
          FR: "Pourquoi ? Parce que c'était une ignorante. Non, pas ignorante comme vous qui écoutez mon histoire sans même savoir qui je suis. Non va. Ignorante de la langue.",
          EN: "",
        },
      },
      {
        startSecond: 479,
        endSecond: 507,
        text: {
          CR: "Tiya, té komkésa kél té i apél. Lavé ariv dann vilaz an disnéfsan lontan. Telman lontan, ké té in zour navé d'soley. So zour-la, tout lo vilaz té réuni po fét lo gran rotour défin Misié Harris Gontran , ké té i rovyin de kinzan langazman larmé an l'Inde.",
          FR: "Tiya, c'est comme ça qu'elle s'appellait. Elle était arrivée au village en l'an d'il y a longtemps. Tellement longtemps, que c'était un jour de soleil. Ce jour-là, tout le village s'était réuni pour le retour du regretté Misié Harris Gontran, qui revenait de quinze ans d'engagement militaire en Inde.",
          EN: "",
        },
      },
      {
        startSecond: 507,
        endSecond: 518,
        text: {
          CR: "Dékoré pardsi dékoré, ek a son bra, é dann son kèr, in tro zoli flèr d'Asie : Tiya.",
          FR: "Plus décoré que décoré, avec à son bras une magnifique fleur d'Asie : Tiya.",
          EN: "",
        },
      },
      {
        startSecond: 518,
        endSecond: 530,
        text: {
          CR: "Pinn arivé, él lavé toudsuit tonm an lamour la plaine des grégues. Sans doute té ki rapél a él son péï, rapor ké isi-an-o sé toultan la mouson.",
          FR: "A peine arrivée, elle était tombée en amour de la Plaine des Grègues. Sans doute que le climat lui rappelait son pays, puisqu'ici la mousson, c'est tout le temps.",
          EN: "",
        },
      },
      {
        startSecond: 530,
        endSecond: 544,
        text: {
          CR: "Soman, pinn arivé osi, Misié Gontran telman an kontantman atrouv la tèr son vilaz, la trouv a son gou alonz anndan, po in léternité limidité.",
          FR: "Mais à peine arrivé, Misié Gontran, tellement content de retrouver la terre de son village, avait trouvé à son goût de s'allonger dedans, pour une éternité d'humidité.",
          EN: "",
        },
      },
      {
        startSecond: 544,
        endSecond: 555,
        text: {
          CR: "Sé dopi sa mor, ké Tiya té ki oz pi sorti. Rapor aprann koz kréol él lavé pa u l'tan.",
          FR: "C'est depuis sa mort, que Tiya n'osait plus sortir. Puisqu'elle n'avait pas eu le temps d'apprendre la langue créole.",
          EN: "",
        },
      },
      {
        startSecond: 555,
        endSecond: 569,
        text: {
          CR: "Hin, mét aou in pé a sa plas hin, ou sort la boutik ou tonm si l'vié Grondin. Lé pa fasil wi.",
          FR: "Hin, mets-toi un peu à sa place, tu sors de la boutique et tu tombes sur le vieux Grondin. C'est pas facile, faut bien avouer.",
          EN: "",
        },
      },
      {
        startSecond: 569,
        endSecond: 586,
        text: {
          CR: "Bref Morel. Tousala po dit azot ké kinm lo bann zabitan té ki trouv son dey in pé long, talèr vintan. Zot té ki oz pa déranzé. Isi-an-o linportan sé lo réspé.",
          FR: "Bref, Morel. Tout ça pour vous dire que même si les habitants trouvaient son deuil un peu long, presque vingt ans. Ils n'osaient pas la déranger. Ici, le plus important, c'est le respect.",
          EN: "",
        },
      },
      {
        startSecond: 586,
        endSecond: 608,
        text: {
          CR: "Ziska... Ziska so soir livèr ou an doulèr Misié Scholastique la konye si sa port. « Bonsoir, je peux vous aider ? » kél la di.",
          FR: "Jusqu'à... Jusqu'à ce soir d'hiver où, en douleur, Misié Scholastique avait cogné à sa porte. « Bonsoir, je peux vous aider ? » qu'elle avait dit.",
          EN: "",
        },
      },
      {
        startSecond: 608,
        endSecond: 628,
        text: {
          CR: "Sof koman Misié Scholastique la niabout éksplik son trakasman zanm dikapé. « Je reviens » la di Tiya avan disparét dann la kizine é d'ronir ék in kataplasm la poud oranzé.",
          FR: "Je ne sais pas comment mais Misié Scholastique avait su lui expliquer sa douleur. « Je reviens » qu'elle avait dit, avant de disparaitre dans la cuisine et de revenir avec un cataplasme de poudre orangée.",
          EN: "",
        },
      },
      {
        startSecond: 628,
        endSecond: 640,
        text: {
          CR: "Mounoir, pa bésoin mi di azot parkoman lo gramoun la rouzi kan Tiya la domann a li sann son morés po kataplasm son boi d'golét.",
          FR: "Mounoir, je n'ai pas besoin de vous raconter comment il avait rougi quand Tiya lui avait demandé de descendre son pantalon pour lui poser le cataplasme.",
          EN: "",
        },
      },
      {
        startSecond: 640,
        endSecond: 660,
        text: {
          CR: "Falé oir koman él té fé sa byin. É fo t'avoué kan li la sort son kaz li té soulazé. Lé inportan soulazman po in bonom !",
          FR: "Il faut dire aussi qu'elle faisait ça bien. Et il faut aussi avouer que cela l'avait bien soulagé. C'est important le soulagement pour un bonhomme !",
          EN: "",
        },
      },
      {
        startSecond: 660,
        endSecond: 683,
        text: {
          CR: "Pa bésoin dir azot non pli ké dé lo landmoin soir son boi d'zanm la arkomans fé mal, é tout la sominm ankor. É pli souvan k'lo rézonab ou té pé oir Misié Scholastique sann rod son kataplasm safran de l'inde, sél Roméd éfikas po soinye son rédisman.",
          FR: "Pas besoin de vous dire que dès le lendemain ses deux jambes ont recommencé à souffrir, et toute la semaine aussi. Et plus souvent que le raisonnable on pouvait voir Misié Scholastique descendre chercher son cataplasme de safran d'Inde, seul remède efficace pour soigner son raidissement.",
          EN: "",
        },
      },
      {
        startSecond: 683,
        endSecond: 707,
        text: {
          CR: "I di dopi la boutik Henri ziska lao ravine des grégues, té ki antann son/zot soulazman. Wi, pars apraman soulaz a li, i soulazé a él osi.",
          FR: "On raconte que depuis la boutique Henri jusqu'au bord de la ravine des Grègues, on pouvait entendre son/leur soulagement. Oui, parce qu'apparemment le soulager la soulageait aussi.",
          EN: "",
        },
      },
      {
        startSecond: 707,
        endSecond: 715,
        text: {
          CR: "Kosa i éspasé dann la kaz la ? Zot la pa besoin konèt lintimité domoun, gro léspri va.",
          FR: "Qu'est-ce qui se passait dans la case ? Tu n'as besoin de connaitre leur intimité, gro léspri va.",
          EN: "",
        },
      },
      {
        startSecond: 715,
        endSecond: 729,
        text: {
          CR: "Mé i falé oir lo shanman d'kor de Misié Scholastique. Son figir kontanté, son démarsh zinnzan, son zanm vigouréz, i falé oir é tout lo vilaz la vu.",
          FR: "Mais il fallait voir comment Misié Scholastique avait changé. Son visage heureux, sa démarche de jeune homme, ses jambes vigoureuses, il fallait le voir et tout le village l'a vu.",
          EN: "",
        },
      },
      {
        startSecond: 729,
        endSecond: 749,
        text: {
          CR: "É kom Misié Scholastique lété kèr zénéré, li la partaz lo sokré du soulazman épisé, Landmin minm, fanm kom bonom té dovan la kaz Tiya. É lo sirlandmin, tout lo pé, la figir kom la min, té koulèr safran, kataplasmé.",
          FR: "Et comme Misié Scholastique avait le coeur généreux, il avait partagé le secret du soulagement épicé. Dès le lendemain, les femmes comme les hommes étaient devant la case à Tiya. Et le surlendemain, tout le village avait la peau couleur safran.",
          EN: "",
        },
      },
      {
        startSecond: 749,
        endSecond: 772,
        text: {
          CR: "Aprésa sakinn la fé son fason, an kataplasm wi, mé osi an siro, an tizane. Sé Madame André la mét dann kari promié kou, po donn la koulèr la viann son volay péï blinm forstan zamé oir d'soley. É insi de suit siro la kuit.",
          FR: "Après, chacun y est allé de sa recette, en cataplasme oui, mais aussi en sirop, en tisane. C'est Madame André qui en mit dans le cari pour la première fois, pour colorer la peau de sa volaille trop blanche de n'avoir jamais vu le soleil. Et ainsi de suite sirop la cuite.",
          EN: "",
        },
      },
      {
        startSecond: 772,
        endSecond: 791,
        text: {
          CR: "Kriké misié médame la sosiété. Astèr zot i koné po kosa isi-an-o la komans plant safran peï. Po afront la fré é rét a zamé zéne é vigoureux.",
          FR: "Kriké misié médame la sosiété. Maintenant vous savez pourquoi, ici, on s'est mis à planter du safran. Pour faire face au froid et garder la vigueur de sa jeunesse.",
          EN: "",
        },
      },
      {
        startSecond: 791,
        endSecond: 803,
        text: {
          CR: "Si vi kroir pa moin, alé domann Misié Scholastique, dé fois santenèr sét ané, kom lé sét zot promié labitan. Parey po zot 1800 ti zanfan.",
          FR: "Si tu ne me crois pas, tu n'as qu'à aller demander à Misié Scholastique, deux fois centenaire cette année, comme les sept autres habitants. Tout comme leurs 1800 petits enfants.",
          EN: "",
        },
      },
      {
        startSecond: 803,
        endSecond: 822,
        text: {
          CR: "Bréd, mon zistoir i sar finir. Mé avan avan out zorey i artourn ék son propriètèr, é po fini out formasion akséléré linivérsitèr patatèr, in dernié zafèr.",
          FR: "Brèdes, mon histoire se termine ici. Mais avant que tes oreilles ne retrounent à leur propriétaire, et pour finir ta formation accélérée d'universitaire de campagne, une dernière chose.",
          EN: "",
        },
      },
      {
        startSecond: 822,
        endSecond: 852,
        text: {
          CR: "Kosa in soz ? Tanbour lor sou la tèr ? Kosa in soz, Curcuma mon zanfan, Curcuma. Mé anou domoun la Rénion nou kri sa Nout fason, lo minm fason domoun isi-an-o i kri amoin. Tiya ou kroi ?",
          FR: "Kosa in soz ? Un tambour d'or sous la terre. Kosa in soz, Curcuma mon enfant, Curcuma. Mais ici à la Réunion, on le nomme à notre façon, de la même facon que les gens d'ici m'appellent. Tiya tu crois ?",
          EN: "",
        },
      },
      {
        startSecond: 852,
        endSecond: 882,
        text: {
          CR: "Non mon ti-non gaté sé Mamzél... Safran.",
          FR: "Non, mon petit nom gâté c'est Mamzél... Safran.",
          EN: "",
        },
      },
    ],
  },
  7: {
    sentences: [
      {
        startSecond: 0,
        endSecond: 18,
        text: {
          CR: "Mésié médam la sosiété anparmi, zot ki me prété lorey o zour de zordi minm.",
          FR: "Messieurs dames ici présents, vous qui me prêtez l'oreille au jour d'aujourd'hui.",
          EN: "",
        },
      },
      {
        startSecond: 18,
        endSecond: 27,
        text: {
          CR: "J'ai l'illustre, ké dis-je, limans lonèr de vous passer le Konpliman, mé pa ninportakèl kompliman sivouplé.",
          FR: "J'ai l'illustre, que dis-je, l'immense honneur de vous saluer bien bas, mais pas n'importe comment.",
          EN: "",
        },
      },
      {
        startSecond: 27,
        endSecond: 40,
        text: {
          CR: "Un « konpliman » tré réspéktuéz é tré respékté. Zosi zinportan kin sinedékroi, zosi solanél qu'un « je vous salut Marie monsieur le préfet ».",
          FR: "Un Salut très respectueux et très respecté. Aussi important qu'un signe de croix, aussi solennel qu'un « je vous salue Marie monsieur le préfet ».",
          EN: "",
        },
      },
      {
        startSecond: 40,
        endSecond: 43,
        text: {
          CR: "Mésié médam la sosiété Konpliman.",
          FR: "Messieurs mesdames de la Société je vous salue.",
          EN: "",
        },
      },
      {
        startSecond: 43,
        endSecond: 52,
        text: {
          CR: "Certes Huberte, konpliman soit, mais de la part de qui me direz vous ? Bé déja de moin minm isi prézan.",
          FR: "Certes Huberte, le Salut soit, mais de la part de qui me direz-vous ? Beh déjà de moi-même ici présent.",
          EN: "",
        },
      },
      {
        startSecond: 52,
        endSecond: 62,
        text: {
          CR: "Mé zosi de la por de : Misié Danyel Waro dit linkoifé, Manzél Michou ségatéz an shéf, Madame Graziella Leveneur gran lékrivéz",
          FR: "Mais aussi de la part de : Monsieur Danyel Waro dit l'incoiffé, Mademoiselle Michou ségateuse en chef, Madame Graziella Leveneur immense écrivaine",
          EN: "",
        },
      },
      {
        startSecond: 62,
        endSecond: 82,
        text: {
          CR: "Défin Harry Payet shantèr dé sharm sharman, Aleks Sorres rapèr de radiokroshé, Misié lo très répékté linstititèr Zanloran Faubourg, ClaudineMichelFrançoisBernard Patjaune, Max Lauret porteur de moustache gominé, de, de...",
          FR: "Du défunt Harry Payet chanteur de charme charmant, Alex Sorres rappeur de radio crochet, Monsieur le très respecté instituteur Jean-Laurent Faubourg, ClaudineMichelFrançoisBernard Patjaune, Max Lauret porteur de moustache gominée, de, de...",
          EN: "",
        },
      },
      {
        startSecond: 82,
        endSecond: 88,
        text: {
          CR: "Asé foutor ! Zot i pran moin po in botin de lay péyi kosa ? Anfin bréf zot la konpri.",
          FR: "Ça suffit ! Vous me prenez pour un bottin d'ail de Petite-Île ? Enfin bref, vous l'aurez compris.",
          EN: "",
        },
      },
      {
        startSecond: 88,
        endSecond: 99,
        text: {
          CR: "Zordi ni sa koz si Maoul, patjaunes, zabitan, pip-léo, Youl. An rézimé Zozimé, de bann Yab.",
          FR: "Aujourd'hui nous allons parler des Maoul, patjaunes, zabitan, pip-léo, Youl. En résumé Josumé, des Yabs.",
          EN: "",
        },
      },
      {
        startSecond: 99,
        endSecond: 118,
        text: {
          CR: "Mé tout dabor kosa i lé un Yab ? Solon lo définisman du dictionnaire de France, in Yab sé : « Un créole blanc, installé dans les hauteurs de l'île de la Réunion, qui descend des colons français peu fortunés. Ils représentent une communauté à part entière avec une culture propre ».",
          FR: "Mais tout d'abord : qu'est-ce qu'un Yab ? Selon la définition du dictionnaire de France, un Yab c'est : « Un créole blanc, installé dans les hauteurs de l'île de la Réunion, qui descend des colons français peu fortunés. Ils représentent une communauté à part entière avec une culture propre ».",
          EN: "",
        },
      },
      {
        startSecond: 118,
        endSecond: 127,
        text: {
          CR: "Prop, prop, lé vit di. Lo Boug la ékri sa, li la zamé vu la pat Zozimé kan li sort o zérb. Sof, pason.",
          FR: "Propre, c'est vite dit. Celui qui a écrit ça n'a jamais vu les pieds de Josumé revenu des champs. Mais bref, passons.",
          EN: "",
        },
      },
      {
        startSecond: 127,
        endSecond: 141,
        text: {
          CR: "És zot i Koné osi kosa i sinifi l'acronyme Yab - Y.A.B ? Non ? Bé bouz pa kankréla ma di a toi !",
          FR: "Est-ce que vous savez ce que signifie l'acronyme Yab - Y.A.B ? Non ?  Alors ne bouge pas cancrelat je vais t'expliquer !",
          EN: "",
        },
      },
      {
        startSecond: 141,
        endSecond: 151,
        text: {
          CR: "Néna ki di ké sé pars bann marmay lé ô té ki frékant pa légliz, osi monsénièr-monkiré té ki kri azot « Ti diab ».",
          FR: "Certains disent que c'est parce que les enfants des hauts ne fréquentaient pas l'église, aussi monseigneur mon curé les appelaient  les « p'tits diables ».",
          EN: "",
        },
      },
      {
        startSecond: 151,
        endSecond: 162,
        text: {
          CR: "Somanké... Mé véritéfrans, kan ou oi lo zoli figir roz bann zinnfi Matouta, ou la konpri ké po d'bon i vé dir : Young And Beautiful.",
          FR: "Peut-être... Mais Vérité-France, quand tu regardes les belles filles de Matouta, tu sais que pour de bon cela signifie : Young And Beautiful.",
          EN: "",
        },
      },
      {
        startSecond: 162,
        endSecond: 170,
        text: {
          CR: "Lo Yab li viv, prinsipalman, dann trou-d-babouk : Sinzozéf, Sinfilip, la Titile, Tampon, Mafate-les-hauts.",
          FR: "Le Yab vit principalement à la campagne : Saint-Joseph, Saint-Philippe, Le Tampon, Mafate-les-hauts.",
          EN: "",
        },
      },
      {
        startSecond: 170,
        endSecond: 181,
        text: {
          CR: "Odsu néfsan mét, a partir, pars lo Yab li lé kom fanzan. Po asur son bon dévlopman i fo li lé a moitié noyé dann limidité.",
          FR: "Au dessus de neuf cents mètres, à partir, parce que le yab est comme le fanjan. Il ne se développe bien qu'à moitié noyé dans l'humidité.",
          EN: "",
        },
      },
      {
        startSecond: 181,
        endSecond: 189,
        text: {
          CR: "I prétan sé osi pars apré labolision lésklavaz bonpé ti-kolon la trouv la ruine, é la mont dann lé ô po sanfuir la mizér.",
          FR: "On dit aussi qu'après l'abolition de l'esclavage les petits propriétaires ruinés sont montés dans les hauts pour fuir la misère.",
          EN: "",
        },
      },
      {
        startSecond: 189,
        endSecond: 205,
        text: {
          CR: "Rant nout dé toi, tu répét pa, somanké osi sé pars zot té vé pa mélanzé, sali zot pirté-d'ras. Somanké... Zot va vérifié par zot minm, si mon koko la pa ékri Laurent Hoarau.",
          FR: "Entre nous, tu ne répètes rien, c'était aussi parce qu'ils refusaient de se mélanger, de salir leur pureté de race. Peut-être... Vous vérifierez, sur mon front il n'est pas écrit Laurent Hoarau.",
          EN: "",
        },
      },
      {
        startSecond: 205,
        endSecond: 211,
        text: {
          CR: "Koikilansoi, fo pa pran lo Yab po in karapat i koné ryin'k son tétébéf.",
          FR: "Ce qui est sûr, c'est qu'il ne faut pas prendre le Yab pour un plouc bien dans sa boue.",
          EN: "",
        },
      },
      {
        startSecond: 211,
        endSecond: 240,
        text: {
          CR: "Lo Yab, sé t'in léspés léspés ké vi rotrouv portou si la tèr bondié : An Guyane, ousa na bonpé la shapé apré la Sakay, an Lil-de-frans, ou ké Bunidom la saroy an paké, an Bretagne, ou an takon zot i travay dann lagro-alimantèr, zétazuni, ou's lo plis séléb dantrézot la fini prézidan an séf.",
          FR: "Le Yab, c'est une espèce qu'on retrouve partout sur la planète : En Guyane, où ils ont été exilés après la Sakay, en Île-de-France, où le Bumidom les a trainés, en Bretagne, où beaucoup travaillent dans l'agro-alimentaire, aux Etats-Unis, où le plus célèbre d'entre eux a fini Président.",
          EN: "",
        },
      },
      {
        startSecond: 240,
        endSecond: 253,
        text: {
          CR: "Sérié zot i oi pa kisa i lé ? Son koko d'tét oranzé ? Son san-fason ? Donald Trump mounoir ! Zot té kalkil vréman li té in lamérikin ?",
          FR: "Sérieusement, vous ne voyez pas de qui je parle ? Ses cheveux orangés ? Ses vilaines manières ? Donald Trump enfin ! Vous vous imaginiez que c'était un américain ?",
          EN: "",
        },
      },
      {
        startSecond: 253,
        endSecond: 260,
        text: {
          CR: "Asé a toi Zanbenoi. Ti oi pa sé in gardyin-volkan i sort Parkmouton sa ?",
          FR: "Pauvre de toi Jean-Benoit. Tu ne vois pas que c'est un rouquin de Parc-à-Moutons ça ?",
          EN: "",
        },
      },
      {
        startSecond: 260,
        endSecond: 272,
        text: {
          CR: "Mé skizmonpardon. Rovnon zan za nos rouzons. Isi, nou lé Domaine Vidot. Ki port lo nom de son fondatèr : Misié Vidot.",
          FR: "Mais je m'excuse. Revenons-en à nos rougeons. Ici, nous sommes au Domaine Vidot. Qui porte le nom de son fondateur : Misié Vidot.",
          EN: "",
        },
      },
      {
        startSecond: 272,
        endSecond: 283,
        text: {
          CR: "Isi-an-ô, ni tonm pépré 650 mét daltitid, sé sé ki apél : lo zis dogré. Pa tro dann lé ô po konzlé, pa tro dann lé ba po toufé, Zis dogré.",
          FR: "Ici, nous sommes à 650 mètres d'altitude, c'est ce qui s'appelle : le juste degré. Pas trop haut pour congeler, pas trop bas pour suer, le juste degré.",
          EN: "",
        },
      },
      {
        startSecond: 283,
        endSecond: 297,
        text: {
          CR: "Lo domaine Vidot sé in landroi inportant po létnolozi rénionéz. Sé isi-an-ô, ké néna kék zané, la aparét in léspés bien particulière de Yab : le Mi-pant.",
          FR: "Le Domaine Vidot est un endroit important pour l'ethnologie réunionnaise. C'est ici même qu'est né une espèce bien particulière de Yab : le Mi-pant.",
          EN: "",
        },
      },
      {
        startSecond: 297,
        endSecond: 304,
        text: {
          CR: "Akoz Mi-pant ? Akoz ké li té i viv a mi-hauteur, tout sinpléman zanfan d'zavan !",
          FR: "Pourquoi Mi-pant ? Parce qu'il vivait à mi-hauteur, tout simplement zanfan d'zavan !",
          EN: "",
        },
      },
      {
        startSecond: 304,
        endSecond: 316,
        text: {
          CR: "Mé puisk ké nou lé parti po in kour solanél si la Yabitude, mi propoz azot de plonz sanm moin dans la grande histoire ( avec un IS majuscule).",
          FR: "Mais puisque nous voilà partis pour un cours solennel sur la Yabitude, je vous propose de nous plonger dans la grande histoire (avec un IS majuscule).",
          EN: "",
        },
      },
      {
        startSecond: 316,
        endSecond: 326,
        text: {
          CR: "Dann la vré-vérité-vré-po-vréman de l'origine de l'Homme de la Mi-pant, ou comment le Yab des hauts est un jour redescendu de son piton.",
          FR: "Dans la vraie vérité vraie de l'origine de l'Homme de la Mi-pant, où comment le Yab des hauts est un jour redescendu de son piton.",
          EN: "",
        },
      },
      {
        startSecond: 326,
        endSecond: 332,
        text: {
          CR: "Lorkés ? Béz inn ti mizik dokimantèr siouplé.",
          FR: "L'orchestre ? Une musique de documentaire s'il-vous-plait.",
          EN: "",
        },
      },
      {
        startSecond: 332,
        endSecond: 350,
        text: {
          CR: "Nou lé en lan disnéfsanlontan. Dann lé ô de la pa-zankor komune sinpièr, a 1200 mét d'altitid, o pié du piton Papang. Plus présizéman, si l'péron in boukan d'kaz an pay.",
          FR: "Nous sommes il y a très longtemps. Sur les hauteurs de la-pas-encore-commune de Saint-Pierre, à 1200 mètres d'altitude, au pied du piton Papang. Plus précisément sur le perron d'une case en paille.",
          EN: "",
        },
      },
      {
        startSecond: 350,
        endSecond: 364,
        text: {
          CR: "Kaz-la, lété kom livèr, zamé li oi in grin d'soley. Po rézon, li lé pozé zis dann lonbraz lo piton. É anndan-la, i viv les ancetres commun de tous les Yabs Mi-pant :",
          FR: "Case, qui été comme hiver, ne voit jamais le soleil. Pour une bonne raison, elle est posée juste à l'ombre du piton. Et ici, vivent les ancêtres communs de tous les Yabs Mi-pant :",
          EN: "",
        },
      },
      {
        startSecond: 364,
        endSecond: 386,
        text: {
          CR: "Misié Gustin Grondin, kiltivatèr de roshgalé, é madame Reine-Claude Ioné, épouz Grondin	dopi byinto kinzan. Lé dé zamouré lé dann la flèr de laz, é zot i yinm azot for. For for minm. For, kinm zot la marié san konét inn-a-lot, par laranzman dé zot paran.",
          FR: "Monsieur Gustin Grondin, cultivateur de roshgalé, et madame Reine-Claude Ioné, épouse Grondin depuis bientôt quinze ans. Les deux amoureux sont dans la fleur de l'âge et ils s'aiment fort. Fort, fort, fort. Fort, même s'ils se sont mariés sans se connaitre, par l'arrangement des parents.",
          EN: "",
        },
      },
      {
        startSecond: 386,
        endSecond: 398,
        text: {
          CR: "For, kinm zot kaz lé plis shonblié kin figir tang granmatin kan ki fé zéro dogré. For, kinm zot i viv dann lokisité, pars i-san-o i pous ryin k'galé.",
          FR: "Fort, même si leur case est plus froissée que la figure d'un tangue au milieu d'un hiver à zéro degré. Fort, même pauvres, parce qu'ici la seule chose qui pousse ce sont les galets.",
          EN: "",
        },
      },

      {
        startSecond: 398,
        endSecond: 407,
        text: {
          CR: "For, kinm lantétman de Gistin ké la désid ni abit si-an-ô po dovnir kiltivatèr de roshgalé.",
          FR: "Fort, malgré l'entêtement de Gistin qui a décidé de venir vivre ici son rêve de cultivateur de roshgalé.",
          EN: "",
        },
      },
      {
        startSecond: 407,
        endSecond: 427,
        text: {
          CR: "-Ou konpran Reine, lavnir sé lo moélon, san tardé ta oir tout domoun dann lé ba i sa moéloné. Reine, yéroisoir moin la rév k'in désertin Robert, i sar fé inn rout si la mèr, ké li sa ashét roshgalé par milion. Reine, alé aroz galé, tard pa nu sa ni rish !",
          FR: "-Tu comprends Reine, l'avenir est dans le moellon, tout le monde bientôt moellonnera. Reine, hier j'ai rêvé qu'un certain Robert construisait une route sur la mer, qu'il ache-tait des cailloux par millions. Reine, va arroser le champ, on va être riche.",
          EN: "",
        },
      },
      {
        startSecond: 427,
        endSecond: 441,
        text: {
          CR: "Kinm, kinm. Kinm pi inn tay la. Lamour wi, mé dann son fonnkèr Reine-Chantal té fatigé. Fatigé aroz lo rosh.",
          FR: "Fort même. Mais pas tant que ça. L'amour oui, mais dans son coeur Reine est fatiguée. Fatiguée d'arroser la roche.",
          EN: "",
        },
      },
      {
        startSecond: 441,
        endSecond: 456,
        text: {
          CR: "Fatigé viv zot dé tout sél dann lo solitud é lo zéro absolu de livér ki lui ansér lo kèr. Fatigé de se modi piton, de son lonbraz si la kaz. Fatigé zamé oir soley. Fatigé...",
          FR: "Fatiguée de vivre tous les deux seuls dans le froid de l'hiver qui lui glace le coeur. Fatiguée de ce maudit piton. Fatiguée de ne jamais voir le soleil. Fatiguée...",
          EN: "",
        },
      },
      {
        startSecond: 456,
        endSecond: 466,
        text: {
          CR: "Gustin li, anporté par son rév de NRL, lavé minm pa vu lo malèr nésan é sézisan ké Reine té pri anndan.",
          FR: "Gustin, emporté par son rêve de NRL, n'avait même pas vu le malheur de Reine.",
          EN: "",
        },
      },
      {
        startSecond: 468,
        endSecond: 477,
        text: {
          CR: "-Mé Reine, que puis-je donc faire pour faire renaitre en toi le soleil de la plaine ?",
          FR: "-Mais Reine, que puis-je donc faire pour faire renaître en toi le soleil de la plaine ?",
          EN: "",
        },
      },
      {
        startSecond: 477,
        endSecond: 489,
        text: {
          CR: "-Lé sinp, moi lé fatigé viv dann lonbraz, mets des Payet dans ma vie Grondin. Mi vé d'soley po d'bon, fé kom ou vé, mé mi vé ou déplas lo piton.",
          FR: "-C'est simple, je suis fatiguée de l'ombre, mets des Payet dans ma vie Grondin. Je veux du soleil dans ma mémoire, fais comme tu veux, je veux que tu déplaces le piton.",
          EN: "",
        },
      },
      {
        startSecond: 489,
        endSecond: 506,
        text: {
          CR: "Pa besoin mi tir portré Gustin po zot konprann. Son figir la telman dsann ké sa machoire inférieure la fil bordmèr an karzone.",
          FR: "Pas besoin de vous faire un dessin de la tête à Gustin. Tombée tellement bas que sa mâchoire inférieure touchait le bord de mer.",
          EN: "",
        },
      },
      {
        startSecond: 506,
        endSecond: 514,
        text: {
          CR: "Déplas lo piton foutor. Li té kosto wi, mé li té pa non pli in Kapor.",
          FR: "Déplacer le piton. Il était costaud oui, mais ce n'était pas non plus un Hercule.",
          EN: "",
        },
      },
      {
        startSecond: 514,
        endSecond: 530,
        text: {
          CR: "Bréf, so matin-la, an dépitasion, Gistin la trinn son kadav ziska shé Ti-Mardé. Ti-Mardé, fo mi splik azot kisa i lé sinon linfini inkonprénans i sa sézi zot mayo.",
          FR: "Bref, ce matin-là, dépité, Gustin s'est trainé chez Ti-Mardé. Ti-Mardé, je vous explique sinon vous n'allez rien comprendre.",
          EN: "",
        },
      },
      {
        startSecond: 530,
        endSecond: 543,
        text: {
          CR: "Ti-Mardé donk, lété in gran sigidèr dovan léternélité. Li té ki viv an lérmit dopi prés trantan dann in gafourn de proximité. Té son sèl voizin é Gistin sanm li lavé tonb kamarad vitman.",
          FR: "Ti-Mardé donc, était un grand sorcier, qui vivait en ermite depuis trente ans dans une caverne à loyer modéré. C'était son seul voisin alors Gustin et lui avaient sympathisé.",
          EN: "",
        },
      },
      {
        startSecond: 543,
        endSecond: 550,
        text: {
          CR: "Kan li la vi lo figir krazé Gistin, ni inn ni dé, an tan ké dovinèr Ti-Mardé la doviné.",
          FR: "A la tête de Gustin, Ti-Mardé avait tout deviné.",
          EN: "",
        },
      },
      {
        startSecond: 550,
        endSecond: 575,
        text: {
          CR: "-Wo toi, la pa bésoin kaz ti boi, mont si lo piton, koup lo pli gran piédboi, ardsann, bour in koté d'in in trou d'rosh, ék out madame mont dosi lot bout, apui tout zot poi, san manké lo piton va dékalé. Narvisa lonbraz, byinvnu lo bronzaz.",
          FR: "-Hé l'ami, pas besoin de sorcellerie, monte sur le piton, coupe un grand arbre, coince son tronc dans un trou sous le piton et monte dessus avec ta femme, le piton va chavirer, tu peux en être sûr. Au revoir l'ombrage, bienvenu le bronzage.",
          EN: "",
        },
      },
      {
        startSecond: 575,
        endSecond: 598,
        text: {
          CR: "Tél Ti-Mardé  la di, tél Gistin la fé. Monté si lo piton, koupé lo pié d'boi, bour in koté sou la rosh, doubouté lot koté, dobouté soté dobouté soté, brizé lo boud'boi, atèr lo boyo, kasé lo boid'rin, lo piton minm plas minm.",
          FR: "Ti-Mardé l'avait dit, Gustin l'a fait. Monter sur le piton, couper l'arbre, coincer le tronc sous le piton, monter sur une extrémité, dobouté soté, dobouté soté, briser l'arbre, tomber, se fracasser le dos, le piton lui n'a pas bougé.",
          EN: "",
        },
      },
      {
        startSecond: 598,
        endSecond: 606,
        text: {
          CR: "-Ti-Mardé foutor, mazigador de dimans férié, sé mon divors ké vi vé ?",
          FR: "-Ti-Mardé escroc, sorcier de dimanche férié, tu veux mon divorce ?",
          EN: "",
        },
      },
      {
        startSecond: 606,
        endSecond: 639,
        text: {
          CR: "-Fol pa, la di Ti-Mardé, na sanz tiktak, atann asoir fénoir, dann in vane mét troi kèr d'kabri, in domi-volay-bio ék troi lit lo rom aranzé. Mont an lèr dosi piton é fé la prièr de domann d'expropriation. San manké pour éviter de pénibles procédures zéspri lo piton va gainye la zél, lo piton sou son bra é dé domin matin, fini la fénoirsité, wi soléyé",
          FR: "-On va changer de plan, a dit Ti-Mardé, ce soir, dans un plat pose trois coeurs de cabri, un demi-poulet bio et trois litres de rhum arrangé. Monte sur le piton et fais la prière d'expropriation. Sans aucun doute, pour éviter de pénibles procédures l'esprit du piton s'en ira, le piton sous le bras et dès demain matin, fini la nuit, vive le soleil dans ta vie.",
          EN: "",
        },
      },
      {
        startSecond: 639,
        endSecond: 673,
        text: {
          CR: "Fénoir fine zis rantré ala Gistin ék son vane lofrann ki bék an montan. Prés ariv an-o di pa toué li glis si in galé, li travérs lo toi la kaz, tonm dirék dann li parkoté Reine-Chantal, si lo tapi mandian troi kèr d'kabri, in domi-poulé-bio, troi lit lo rom.",
          FR: "La nuit arrivée, voilà Gustin et son offrande qui monte sur le piton. Presque en haut, il glisse sur un galet, traverse le toit de sa case et tombe sur le lit à côté de sa femme, sur le drap trois coeurs de cabri, un demi-poulet bio et trois litres de rhum arrangé.",
          EN: "",
        },
      },
      {
        startSecond: 673,
        endSecond: 686,
        text: {
          CR: "-Ti-Mardé kitonmoman testaman, sar pa domin la vey ta gainye Miss Visnelda mi di atoué. Reine l'an trin fé son balo",
          FR: "-Ti-Mardé kitonmoman testaman, c'est pas demain la veille que tu seras Miss Visnelda, je te le dis. Reine plie ses affaires.",
          EN: "",
        },
      },
      {
        startSecond: 686,
        endSecond: 720,
        text: {
          CR: "-Bon kom mi oi lo bézman lé sérié, oté, oté oté, sél solision i rét aou, rant out kaz, démont la totalité ék lo kapitalité, partaz lo tout an dé moitié, in ta po toué, in ta po Reine, mét dosi zot do, férm lo zié, arkil an arièr pandan in nuit antié an dizan la divine prièr de dis-parition de piton : par la sainte kés morue piton malotru je férm lo zié kan jé rouvèr tu as disparu.",
          FR: "-Bon, ça m'a l'air sérieux, oté, oté, oté, la seule solution qu'il te reste, rentre chez toi dé-monte la case, partage-la en deux moitiés, un tas pour toi, un tas pour ta femme, chacun son tas sur son dos, vous allez reculer toute la nuit, en disant la divine prière de disparition de piton : par la sainte kés morue piton malotru je férm lo zié kan jé rouvèr tu as disparu.",
          EN: "",
        },
      },
      {
        startSecond: 720,
        endSecond: 740,
        text: {
          CR: "Tél Ti-Mardé la di, tél Gistin la fé. Kour an montan, démont la kaz, diviz an dé, si lo do sakinn in moitié, férm lo zié, arkil an arièr an prièr : par la sainte kés morue piton malotru je férm lo zié kan jé rouvèr tu as dis-paru.",
          FR: "Comme Ti-Mardé l'avait dit, Guston l'a fait. Remonter, démonter la case, faire deux tas, chacun sa moitié sur le dos, fermer les yeux, reculer toute la nuit en priant : par la sainte kés morue piton malotru je férm lo zié kan jé rouvèr tu as disparu.",
          EN: "",
        },
      },
      {
        startSecond: 740,
        endSecond: 748,
        text: {
          CR: "Ziska gran matin. Lèr li la santi soley si son figir Gistin la rouv son zié.",
          FR: "Jusqu'au matin. Quand il a senti le soleil, Gustin a ouvert les yeux.",
          EN: "",
        },
      },
      {
        startSecond: 748,
        endSecond: 750,
        text: {
          CR: "-Reine ogard !",
          FR: "-Reine, Regarde !",
          EN: "",
        },
      },
      {
        startSecond: 750,
        endSecond: 754,
        text: {
          CR: "-Ogard kwé mi oi pa ryin.",
          FR: "-Regarder quoi ? Je ne vois rien.",
          EN: "",
        },
      },
      {
        startSecond: 754,
        endSecond: 766,
        text: {
          CR: "-Bé tir Maloy dann zié Reine Ioné. Zistoman Ti-Mardé lavé rézonn, lo piton la déplasé !",
          FR: "-Mais lave tes yeux Reine. Justement Ti-Mardé avait raison, le piton a disparu !",
          EN: "",
        },
      },
      {
        startSecond: 766,
        endSecond: 790,
        text: {
          CR: "Ala marmay astèr zot i koné tout. Parkoman Gustin sanm Reine Chantal Ioné épouz Grondin la kit zot trou d-babouk dann lonbraz piton po sann abit a Mont-Vert les plages, a 650 mét dalitid, donnant ainsi naissance à la première génération de Yab Mi-Pant.",
          FR: "Voilà maintenant vous savez tout. Comment Gustin et Reine Chantal Ioné épouse Grondin ont quitté leur coin paumé perdu dans l'ombrage du piton pour venir vivre à Mont-Vert les plages, à 650m d'altitude, donnant ainsi naissance à la première génération de Yab Mi-Pant.",
          EN: "",
        },
      },
      {
        startSecond: 790,
        endSecond: 798,
        text: {
          CR: "Ou koné tout zistoir i fini minm fason. « Ils vécurent heureux et eurent beaucoup de zanfan ».",
          FR: "Tu sais, les histoires sont les mêmes. « Ils vécurent heureux et eurent beaucoup de zanfan »",
          EN: "",
        },
      },
      {
        startSecond: 798,
        endSecond: 812,
        text: {
          CR: "Gustin larét plant roshgalé po kiltiv la fréz. Reine la fé fortune dan lévénmansiél an organizan la fête du même nom. Zot la gainye pa moin de 9 marmay.",
          FR: "Gustin est devenu cultivateur de fraises. Reine a fait fortune dans l'événementiel en organisant la fête du même nom. Ils n'eurent pas moins de neufs enfants.",
          EN: "",
        },
      },
      {
        startSecond: 812,
        endSecond: 833,
        text: {
          CR: "É moin ki rakont azot zistoir la,  moin sé lo ti dernié-né dé zot bann zarièr zarièr zarièr ti-zanfan. Lé inportan konét ousa ou sort, ét fier kisa ou lé. Kinm ou sé ryin k'in Yab Mi-Pan. Lé inportan kroir out rév. Kinm ousé ryin k'in kiltivatèr d'roshgalé.",
          FR: "Et moi qui vous raconte cette histoire, je suis le dernier né de leurs arrières-arrières-arrières-petits-enfants. C'est important de savoir d'où l'on vient, d'être fier de qui on est . Même si tu n'es rien qu'un Yab Mi-Pant. C'est important de croire en ses rêves. Même si tu n'es rien qu'un cultivateur de roshgalé.",
          EN: "",
        },
      },
      {
        startSecond: 833,
        endSecond: 847,
        text: {
          CR: "Zot i kroi moin la pa tandu kan zot la ri talérla à l'énonsiasion de son métié ? Rir byin. Natandan la zot i oi byin kisa listoir i donn rézon.",
          FR: "Je vous ai bien entendu rire tout à l'heure. Riez. En attendant on voit à qui l'histoire donne raison.",
          EN: "",
        },
      },
      {
        startSecond: 847,
        endSecond: 859,
        text: {
          CR: "Sindni na inn rout i pouss si la mér ? Lé vré pa vré ? É Kisa lo shéf santié dapré zot ? Kisa la fé fortine dan le roshgalé ?",
          FR: "Vrai ou non ? Et c'est qui le chef de chantier ? Qui a fait fortune dans le roshgalé ?",
          EN: "",
        },
      },
      {
        startSecond: 859,
        endSecond: 912,
        text: {
          CR: "Amoin moin minm isi prézan, Zan Didyé Gustin Grondin. Moélonèr de profession. Kriké ?",
          FR: "Moi, en personne, ici présent, Zan Didyé Gustin Grondin. Moellonneur de profession. Kriké ?",
          EN: "",
        },
      },
    ],
  },
  10: {
    sentences: [
      {
        startSecond: 0,
        endSecond: 33,
        text: {
          CR: "Sin-filip. Terla nou lé Sin-filip.",
          FR: "Saint-Philippe. Ici, nous sommes à Saint-Philippe.",
          EN: "",
        },
      },
      {
        startSecond: 33,
        endSecond: 45,
        text: {
          CR: "Amoin ? Kisa mi lé ? Koman mi apél ? Pa bésoin rodé. Mi tonm zanfan d'bordmèr.",
          FR: "Moi ? Qui je suis ? Comment je m'appelle ? Ne cherche pas. Je suis l'enfant du bord de mer.",
          EN: "",
        },
      },
      {
        startSecond: 45,
        endSecond: 58,
        text: {
          CR: "Mon kodinm lékime. Mon sové volkan. Moin lé né rant dé pié vakoa. Din bèr la lav rouz.",
          FR: "Ma peau d'écume. Mes cheveux volcan. Je suis né entre deux vacoas. Dans un berceau de lave rouge.",
          EN: "",
        },
      },
      {
        startSecond: 58,
        endSecond: 82,
        text: {
          CR: "É dopi mon kèr i bat an gro roulo d'vag blé. Akout. Asiz. Moin na zistoir po rakonté. Zistoir in fanm do lo. Kriké.",
          FR: "Et depuis mon coeur bat aussi fort que la mer. Écoute. Assieds-toi. J'ai une histoire à te raconter. L'histoire d'une femme écume. Kriké.",
          EN: "",
        },
      },
      {
        startSecond: 82,
        endSecond: 105,
        text: {
          CR: "Fanm-la. Kosa kél lavé fé po vréman ? Kosa k'lavé niabout fé mont la kolèr son vié papa ? Domoun si-an-ba i ansouvyin pa. Mé sat zot tout nankor souvnans, sé k'in mové-zour d'kontrariété, lo vié lavé trinn a él ziska dann l'bor d'faléz.",
          FR: "Cette femme. Qu'est-ce qu'elle avait fait pour de vrai ? Qu'est-ce qui avait provoqué la colère de son vieux père ? Les gens d'ici ne s'en souviennent pas. Mais ce dont on se souvient encore, c'est qu'un jour de contrariété, il l'avait trainée jusqu'au bord de la falaise.",
          EN: "",
        },
      },
      {
        startSecond: 105,
        endSecond: 125,
        text: {
          CR: "É lavé pous son kor dann gran dézord la mèr. Ké péroké, karang, rokin lavé fé festin d'son kadav. É ké dopi, él té ki dor par l'fon, tout son zosay pozé si in li d'koray.",
          FR: "Et qu'il l'avait poussée dans la mer. Que les poissons avaient fait festin de son corps. Et que depuis, elle dormait par le fond, ses os posés sur un lit de corail.",
          EN: "",
        },
      },
      {
        startSecond: 125,
        endSecond: 145,
        text: {
          CR: "I di ankor ké na d'soir, kan la mèr son kor lé an luil, dann silans son somey gomé, ou pé antann an gropouléré. Lo souplinn lo fanm-éskélèt.",
          FR: "On dit aussi que certains soirs, quand la mer est douce, dans le silence de son sommeil, on peut entendre en gros pleurs. La plainte de la femme squelette.",
          EN: "",
        },
      },
      {
        startSecond: 145,
        endSecond: 179,
        text: {
          CR: "Parey zingad parey rado. Sar mon sérkey an boi mao. Dann dra mortir va koup la voil. Po mon bouzi zékli zétoil (x3). É pi mon lam séré dann por va larg o larz ansanm mon kor (x2) .",
          FR: "Parey zingad parey rado. Sar mon sérkey an boi mao. Dann dra mortir va koup la voil. Po mon bouzi zékli zétoil (x3). É pi mon lam séré dann por va larg o larz ansanm mon kor (x2) .",
          EN: "",
        },
      },
      {
        startSecond: 179,
        endSecond: 189,
        text: {
          CR: "In soir, la ou son kor té dor, dann bor tibrine, lariv la in kanot. In kanot té ki sort telman loin ké li té navig dann liniorans.",
          FR: "Un soir, où elle dormait, au bord de la nuit, est arrivée une barque. Une barque venue de loin qui naviguait sur une mer d'ignorance.",
          EN: "",
        },
      },
      {
        startSecond: 189,
        endSecond: 205,
        text: {
          CR: "Sé pa koman vréman péshèr-la noré pi konét, k'isi la minm, la mèr té bat an roulo la soufrans. Ké parandsou son zindag, té ki souplinn in fanm-éskélèt.",
          FR: "Comment ce pêcheur aurait-il pu savoir, qu'ici-même, la mer trainait tant de souffrance ? Que sous sa barque, pleurait une Femme Squelette ?",
          EN: "",
        },
      },
      {
        startSecond: 205,
        endSecond: 220,
        text: {
          CR: "Koman ankor, li noré pi konprann, ké kan son zin la rét pri, té dann son kot. Kan la line la rédi, son kèr la ranpli kontantman.",
          FR: "Comment aurait-il pu comprendre que quand sa ligne s'est retrouvée prise, c'était dans ses côtes. Quand il l'a sentie se tendre, son coeur s'est rempli de joie.",
          EN: "",
        },
      },
      {
        startSecond: 220,
        endSecond: 236,
        text: {
          CR: "« Gramoun lavé byin di : gro poison i bék si l'tar ». Prés in somén talèr li té rant o por san kinm in kapusin po grié, asoir bondié lavé vey a lu.",
          FR: "« Les vieux disaient vrai : les gros poissons nagent à la nuit ». Presque une semaine qu'il rentrait au port sans même un capucin à griller, mais ce soir Dieu veillait sur lui.",
          EN: "",
        },
      },
      {
        startSecond: 236,
        endSecond: 260,
        text: {
          CR: "Sanmanké, té in karang grotét. Lo plis kosto k'li lavé zamé trapé. Li té mazine déza zépis po l'kari d'asoir, mé osi sépa konbyin d'festin noré ankor, sépa konbyin d'tan minm, san konét la fin, san sobat sanm loséan, tousala d'in sél poison.",
          FR: "Sans aucun doute, c'était une carangue à grosse tête. La plus grosse prise de sa vie. Il se voyait déjà préparer les épices et goûtait déjà aux futurs festins, aux temps sans faim, sans se battre avec l'océan, tout ça dans un seul poisson.",
          EN: "",
        },
      },
      {
        startSecond: 260,
        endSecond: 272,
        text: {
          CR: "Alorse, li la ral la line a-tout-fors é Plis li té ral an lèr, plis lo soidizan poison té ki tir par l'fon.",
          FR: "Alors il tira de toutes ses forces et plus il tirait, plus le présumé poisson tirait par le fond.",
          EN: "",
        },
      },
      {
        startSecond: 272,
        endSecond: 287,
        text: {
          CR: "« Aou grotét, ou na la fors po vréman soman ». Vou lé pa péyé po kroir dézèr d'tan pli tar nout péshèr té ki batay ankor sanm son karang imazinèr.",
          FR: "« Hé grosse tête, tu te défends bien ». Vous n'allez pas me croire mais deux heures plus tard il se battait encore avec sa carangue imaginaire.",
          EN: "",
        },
      },
      {
        startSecond: 287,
        endSecond: 307,
        text: {
          CR: "Dé kou minm, son golét lavé rod po fann an dé. Parandsou d'lo, si son li d'koray, lo zin pri dann son poitraye, lo fanm-skolét té ki débat tout son tanpirkipé.",
          FR: "Par deux fois, son filet avait failli craquer. Sous la mer, sur son lit de corail, le filet pris dans sa poitrine, la Femme Squelette se débattait de toutes ses forces.",
          EN: "",
        },
      },
      {
        startSecond: 307,
        endSecond: 329,
        text: {
          CR: "Mé plis él té i sobat, plis la line té ki anmay tout son zosaye. Bout' tan, fatigé d'lité, él la larg lo kor é son koko-d'mor ranpli d'koki la konye ék lo kanot, son dé réstan d'shiko pri dann la kok.",
          FR: "Mais plus elle se battait, plus elle s'emmêlait les os. A un moment, fatiguée, elle se laissa remonter, et son crâne d'os plein de coquilles cogna le bateau, ses deux vieilles dents pointues se plantèrent dans la coque.",
          EN: "",
        },
      },
      {
        startSecond: 329,
        endSecond: 340,
        text: {
          CR: "Lékime té ki désine si son kadav in zipon lo salé. Anmayé dann la line, baloté par la mèr, él té ki dans.",
          FR: "L'écume lui dessinait un jupon d'eau salé. Pris dans le filet, ballotée par la mer, elle dansait.",
          EN: "",
        },
      },
      {
        startSecond: 340,
        endSecond: 375,
        text: {
          CR: "Parey zingad parey rado. Sar mon sérkey an boi mao. Dann dra mortir va koup la voil. Po mon bouzi zékli zétoil (x3). É pi mon lam séré dann por va larg o larz ansanm mon kor (x2).",
          FR: "Parey zingad parey rado. Sar mon sérkey an boi mao. Dann dra mortir va koup la voil. Po mon bouzi zékli zétoil (x3). É pi mon lam séré dann por va larg o larz ansanm mon kor (x2).",
          EN: "",
        },
      },
      {
        startSecond: 375,
        endSecond: 389,
        text: {
          CR: "Nout péshèr, son koko-d'tét déza, ranpli lodèr kari karang, la tir la line po in bon kou, ziska anvoy lo soidizan poison dann l'fon d'kanot.",
          FR: "Notre pêcheur, le ventre affamé, tira une dernière fois, jusqu'à envoyer son poisson imaginaire au fond de la barque.",
          EN: "",
        },
      },
      {
        startSecond: 389,
        endSecond: 414,
        text: {
          CR: "Pa bésoin mi rakont azot koman la péf l'anmay son trip, kan li la poz son zié si l'ta d'zosay. Son kèr la fann an sézisman, san kalkil ki gainye ki pérd li la trap lo fanm-éskélèt, rojét a la mèr. É san minm lo tan in sine de kroi, li la fout a ramé a tout fors po trouv lo bor.",
          FR: "Je ne vais pas vous raconter sa peur quand il vit son squelette. Son coeur se brisa presque en deux. Sans chercher à comprendre, il prit la Femme Squelette et la jeta à l'eau. Et sans même le temps d'un signe de croix, il se mit à ramer de toutes ses forces pour retrouver la côte.",
          EN: "",
        },
      },
      {
        startSecond: 414,
        endSecond: 441,
        text: {
          CR: "« Kitonmoman lamedané sénièrmari préné pitié ». Mé plis lo kanot té i avans, plis la line té ki anmél. Kanot dévan kadav dérièr. Komdési lo fanm-éskélèt té ki kour si lo, komdési son dé bra té ki vé ral a lu dann lanfèr.",
          FR: "« Maudite âme damnée, Jésus la Vierge prenez pitié ». Mais plus la barque avançait, plus le filet s'emmêlait. La barque devant, la Femme Squelette derrière. C'était comme si elle courait sur l'eau, comme si ses deux bras voulaient l'entraîner en enfer.",
          EN: "",
        },
      },
      {
        startSecond: 441,
        endSecond: 465,
        text: {
          CR: "« sénièrmari préné pitié ». Prièr si prièr, son ki té ki moune losti. Forstan ramé, lo kanot la trouv son bor. Lo péshèr la larg tout an plas, la rame, lo bann zin, lo zapa, é li la kour kashiét dann in boukan d'bord'kap.",
          FR: "« Jésus la Vierge prenez pitié ». À tellement prier il pouvait sentir dans sa bouche le goût de l'hostie. A force de ramer, la barque arriva au bord. Le pêcheur laissa tout en place, et courut se cacher dans une cabane de pêcheur.",
          EN: "",
        },
      },
      {
        startSecond: 465,
        endSecond: 488,
        text: {
          CR: "Son kèr té bat pli for la mèr. « Mérsi bondié ». Kroyan aoir artrouv la tranikilité, li la lume son lanp-pétrol. Mé dovan son zié karkiyé, él lété la.",
          FR: "Son coeur battait plus fort que l'océan. « Merci Bondieu ». Croyant avoir retrouvé la tranquillité, il alluma une vieille lampe, mais devant ses yeux révulsés, elle était là.",
          EN: "",
        },
      },
      {
        startSecond: 488,
        endSecond: 502,
        text: {
          CR: "Anmaré sanm sa zanm, an ta de zosay démonté, lo talon si l'zépol, lo koud dann l'poitray, la zanm sou l'piéd'kou.",
          FR: "Attachée à sa jambe, en tas d'os éparpillés, le talon sur l'épaule, le coude sur la poitrine, la jambe près du cou.",
          EN: "",
        },
      },
      {
        startSecond: 502,
        endSecond: 525,
        text: {
          CR: "Él lété la. Son dé zié d'koray pozé dann la syin. Mé sé pa pokoué, li minm li noré té pa fout kap éspliké, dann son kèr la pitié la ranplas la pèr.",
          FR: "Elle était là. Ses deux yeux de corail posés dans les siens. On ne sait pas pourquoi, lui-même n'aurait pas su l'expliquer, mais dans son coeur la pitié remplaça la peur.",
          EN: "",
        },
      },
      {
        startSecond: 525,
        endSecond: 556,
        text: {
          CR: "Alorse li la komans démay lo filé, dousman. Armét sak zo son plas, dousman. Dann son balo trap in kouvért, dousman. Alim dofé, dousman.",
          FR: "Alors il commença à la démêler, doucement. A remettre ses os en place, doucement. Et il pris une couverture, doucement. Alluma le feu, doucement.",
          EN: "",
        },
      },
      {
        startSecond: 556,
        endSecond: 577,
        text: {
          CR: "Lo fanm skolét té ki di pa ryin. Son lame ranpli ék la pèr. Pér oir son gro doi ardéfèr son kor, ét arzété la mèr po in troizièm kou.",
          FR: "La Femme Squelette ne disait rien. Son âme remplie de peur. Peur de voir ces gros doigts la défaire, et d'être rejetée à la mer une troisième fois.",
          EN: "",
        },
      },
      {
        startSecond: 577,
        endSecond: 594,
        text: {
          CR: "Mé dousman, li té démay. Démayé zisko dernié bout zosay, dousman.",
          FR: "Mais doucement, il la démêlait. Démêlait jusqu'à son dernier os, doucement.",
          EN: "",
        },
      },
      {
        startSecond: 594,
        endSecond: 612,
        text: {
          CR: "Bout'tan, sitantelman fatigé ék tou sa gro sézisman, li la gainye somey. Sé pa koué li maziné, mé dann son rév inn larm la mont.",
          FR: "A un moment, notre pêcheur plein de fatigue, s'est endormi. Je ne sais pas de quoi il rêvait mais une larme s'est mise à couler.",
          EN: "",
        },
      },
      {
        startSecond: 612,
        endSecond: 632,
        text: {
          CR: "Kan lo fanm-éskélèt la vi do lo koulé. Din kou la soif la pran son kor. Alorse él la dépli son zo ziska son gro somey, é él la dépoz sa boush si l'bor son zié.",
          FR: "Quand la Femme Squelette a vu l'eau de son oeil. D'un coup elle fut prise de soif. Alors elle traîna ses os jusqu'à son sommeil, et elle déposa sa bouche au bord de son oeil.",
          EN: "",
        },
      },
      {
        startSecond: 632,
        endSecond: 651,
        text: {
          CR: "Ti grin do lo la, té kom inn sours po son lév sék. Él la boir sitan ziskatan pérd la soif. Aprésa él la plonz la minm dann son poitray.",
          FR: "Cette larme, c'était comme une source pour ses lèvres sèches. Elle bu jusqu'a plus soif. Et puis elle plongea la main dans sa poitrine.",
          EN: "",
        },
      },
      {
        startSecond: 651,
        endSecond: 663,
        text: {
          CR: "Él la pran tanbour son kèr po bat dosi, é si l'roulman tanbouré, él la mét a shanté.",
          FR: "Elle prit son coeur et se mit a jouer du tambour, et sur le roulement, elle se mit à chanter.",
          EN: "",
        },
      },
      {
        startSecond: 663,
        endSecond: 695,
        text: {
          CR: "Ti tras la rout balans ton bèr. An boi Mao dor trankilma. Kom ti dor pi dopi lontan. An romans blé dann bra la mér (x2). É pi mon lam séré dann por va larg o larz ansanm mon kor (x2).",
          FR: "Ti tras la rout balans ton bèr. An boi Mao dor trankilma. Kom ti dor pi dopi lontan. An romans blé dann bra la mér (x2). É pi mon lam séré dann por va larg o larz ansanm mon kor (x2).",
          EN: "",
        },
      },
      {
        startSecond: 695,
        endSecond: 715,
        text: {
          CR: "Plis él té shant, plis la shèr té arpouss si son zo. Él la shant po gainye sévé, dé zié, dé tété, in tapounang, é po l'dernié kouplé, él la alonz son kor név koté le péshèr.",
          FR: "Plus elle chantait, plus la chair repoussait sur ses os. Elle chanta pour des cheveux, des yeux, des seins, un sexe, et au dernier couplet, elle s'est allongée contre le pêcheur.",
          EN: "",
        },
      },
      {
        startSecond: 715,
        endSecond: 734,
        text: {
          CR: "Dann son poitray él la romét son kèr tanbour. Zistoir i di pa plis, mé i di pa moin. Antouléka lèr zot la révéyé, zot dé kor té ankor anmayé sanm léternité.",
          FR: "Dans sa poitrine elle remit le coeur. L'histoire ne dit rien de plus mais rien de moins non plus. Ce que je sais c'est que quand ils se réveillèrent, ils étaient encore emmêlés.",
          EN: "",
        },
      },
      {
        startSecond: 734,
        endSecond: 755,
        text: {
          CR: "Kraké. Dopi s'tan la domoun si-an-ba i rakont k'in granmatin, dann bor la mér kasé, na in kanot la pran la mèr é ké dopi, la pi zamé antann la fanm éskélèt shanté.",
          FR: "Kraké. Depuis les gens d'ici racontent qu'au matin, au bord de la mer cassée, une barque a pris la mer et que depuis, plus jamais l'on n'a entendu la Femme Squelette chanter.",
          EN: "",
        },
      },
      {
        startSecond: 755,
        endSecond: 815,
        text: {
          CR: "La mèr néna son bor, mon zistoir osi. La vi sé t'in kanot baloté. Dodan i palank tout mon rézon. Marmay azot ké i akout mon zistoir, azot ki angard la mèr zét son kor si galé. Ansouyin byin ké dann do lo brizé sinfilip si-an-o, la mèr nadkou li balot la mor, mé toultan li palank lamour. Kriké.",
          FR: "La mer connaît sa frontière, mon histoire aussi. La vie est une barque ballotée. Dedans se balance toute ma raison. Vous qui écoutez mon histoire, vous qui regardez la mer se jeter sur la falaise. Souvenez vous qu'ici à Saint-Philippe, la mer qui se brise porte parfois la mort, mais toujours l'amour. Kriké.",
          EN: "",
        },
      },
    ],
  },
  8: {
    sentences: [
      {
        startSecond: 0,
        endSecond: 20,
        text: {
          CR: "La Titile. Isi-an-ô nou lé la Titile. Akoz de po kosa i apél komkésa ? Atann a toi Zanbenoi, ma splik a toi.",
          FR: "La Petite-Île. Ici on est la Petite-Île. D'où vient ce nom ? Attends, je vais t'expliquer.",
          EN: "",
        },
      },
      {
        startSecond: 20,
        endSecond: 29,
        text: {
          CR: "Mé dabor-inn, dobouté. Dabor-dé, trouv in piédbwa. Dabor-troi, mont zisko pli ô.",
          FR: "Mais d'abord, debout, trouve un arbre, monte jusqu'au plus haut.",
          EN: "",
        },
      },
      {
        startSecond: 29,
        endSecond: 35,
        text: {
          CR: "Astèr, rouvèr gran vout zié, plis gran ké sa sivouplé !",
          FR: "Maintenant ouvre les yeux, plus grand s'il-te-plaît !",
          EN: "",
        },
      },
      {
        startSecond: 35,
        endSecond: 47,
        text: {
          CR: "Angardé. Bordmèr wi pé apercevoir in kanikil morso la tèr antouré do lo, in lile. Inn ti-lile. La titile.",
          FR: "Regarde. En bord de mer tu peux apercevoir un petit morceau de terre entouré d'eau. Une île. Une petite-île. La Petite-Île",
          EN: "",
        },
      },
      {
        startSecond: 47,
        endSecond: 53,
        text: {
          CR: "A la. Astèr kom di mon vié papa, vou lé pa plis zintélizan mé vou lé pa moin kouyon.",
          FR: "Voilà. Maintenant comme disait mon vieux père, tu n'es pas plus intelligent mais tu n'es pas moins bête.",
          EN: "",
        },
      },
      {
        startSecond: 53,
        endSecond: 67,
        text: {
          CR: "Kriké. Misié médame la sosiété la pa po fé mon lintéréséz, mé moin lé sir zot i koné pa ké si so ti-zing la tèr la avan, navé domoun té ki viv.",
          FR: "Kriké. Messieurs mesdames la société, ce n'est pas pour me mettre en avant mais je suis sûr que vous ne savez pas que sur ce petit bout de terre avant, il y avait des gens qui vivaient là.",
          EN: "",
        },
      },
      {
        startSecond: 67,
        endSecond: 75,
        text: {
          CR: "2 moun, plis zékxaktoman ké Bondié lavé dépoz la dann tan davan lo tan davan, promié débu limanité.",
          FR: "Deux personnes, plus exactement que le Créateur avait déposées là il y a bien longtemps, au tout début de l'humanité.",
          EN: "",
        },
      },
      {
        startSecond: 75,
        endSecond: 80,
        text: {
          CR: "Marius ék Zanet, dé zène-zan yab de toute beauté.",
          FR: "Marius et Jeannette, deux jeunes yabs de toute beauté.",
          EN: "",
        },
      },
      {
        startSecond: 80,
        endSecond: 89,
        text: {
          CR: "Él, lo zié an grind'létshi, la po blan-do-lé, in touf sové rouz volcan.",
          FR: "Elle, les yeux noir comme la nuit, la peau de lune, les cheveux volcan.",
          EN: "",
        },
      },
      {
        startSecond: 89,
        endSecond: 101,
        text: {
          CR: "A lu, lo misk an liane-ravine, solid kom in banian, for kom troi kapor. Vou la konpri Marius ék Zanét lété nout l'Adam et Eve tropikalizé.",
          FR: "Lui, taillé comme une liane, solide comme un banian, fort comme trois Kapors. Vous avez compris, Marius et Jeannette étaient nos Adam et Eve tropicalisés.",
          EN: "",
        },
      },
      {
        startSecond: 101,
        endSecond: 112,
        text: {
          CR: "É la, ou yinn aprann in not zafèr. Listoir lumanité la komans isi, d'in Paradi bor d'kap lo sud sovaz ék dé yab maoul.",
          FR: "Et là, tu viens d'apprendre autre chose. L'histoire de l'humanité commence ici, dans un paradis posé sur un rocher avec deux yabs du Sud sauvage.",
          EN: "",
        },
      },
      {
        startSecond: 112,
        endSecond: 120,
        text: {
          CR: "Kom tout patzone zot té ki viv an vèrd'tèr, an sourischaudistes. Po ét pli klèr : an kitouni.",
          FR: "Comme tous les yabs ils vivaient en vers de terre, en naturiste. Plus clairement : tout nus.",
          EN: "",
        },
      },
      {
        startSecond: 120,
        endSecond: 131,
        text: {
          CR: "Bé wi Zanmari, dann Paradi Bondié, navé poin d'magasin de linz, ni de zarab o vann la toil. Lété pa vréman déranzan, minm pa po la moral.",
          FR: "Hé oui, dans le paradis du Créateur, aucun magasin de vêtements, ni de bazardier. Ça ne dérangeait personne, même pas la morale.",
          EN: "",
        },
      },
      {
        startSecond: 131,
        endSecond: 140,
        text: {
          CR: "Po in bon rézon, sé ké zot dé lété tousél si la tèr. Anfin sé sat zot i kroyé.",
          FR: "Pour une bonne raison, ils étaient seuls. Enfin c'est ce qu'ils croyaient.",
          EN: "",
        },
      },
      {
        startSecond: 140,
        endSecond: 152,
        text: {
          CR: "In matin bonèr, Marius la lév an sifokman. Akozkésa ? Pars zordi, zordi minm ekzaktoman",
          FR: "Un matin de bonn heure, Marius se réveille brusquement. Pourquoi ? Parce qu'aujourd'hui, aujourd'hui exactement",
          EN: "",
        },
      },
      {
        startSecond: 152,
        endSecond: 159,
        text: {
          CR: "I fé 7 lané ké zot dé Zanét i dor inn a koté lot, 7 lané Zan-Roné.",
          FR: "Ça fait sept années que Jeannette et lui dorment dans le même lit, sept années.",
          EN: "",
        },
      },
      {
        startSecond: 159,
        endSecond: 174,
        text: {
          CR: "Sé t'in nafèr i mérit, sé pa in gran banké, sé pa in ganblo kado, sé pa, sé pa... Sé pa poukoué li lavé, ankor, oublié. Kom toultan.",
          FR: "C'est une grande occasion, pour un banquet ou une montagne de cadeaux, ou encore, ou encore... Encore une fois, il avait oublié. Comme toujours.",
          EN: "",
        },
      },
      {
        startSecond: 174,
        endSecond: 181,
        text: {
          CR: "Hin, misié médame la sosiété, mi vé pa trouv zéskiz po Marius mé",
          FR: "Hin messieurs mesdames la société, je ne veus pas excuser Marius mais",
          EN: "",
        },
      },
      {
        startSecond: 181,
        endSecond: 188,
        text: {
          CR: "Fo dir osi, san kalandrié si frijidér, ké lé minm pokor invanté, ansouvnir té malizé.",
          FR: "Il faut avouer, que sans calendrier collé au frigo, même pas encore inventé, ce n'était pas simple.",
          EN: "",
        },
      },
      {
        startSecond: 188,
        endSecond: 203,
        text: {
          CR: "Tu grav la dat ék in galé si in piédboi, sominm ansuit lékors fine arpousé. Tu mét an ta in takon fey-sék, inn po sak zourné, lo van i ravaz lo paké. Vréman té malizé.",
          FR: "Tu graves la date au galet sur un arbre, la semaine d'après l'écorce l'a recouverte. Tu mets des feuilles sèches en tas, une par journée, le vent balaie tout. Vraiment ce n'était pas simple.",
          EN: "",
        },
      },
      {
        startSecond: 203,
        endSecond: 213,
        text: {
          CR: "Sirtou ké dé son koté, Zanét, lo zour-nivérsèr, pinn soley lévé : « zoyé nout dé toi mon lamour gaté ».",
          FR: "Surtout que de son côté, Jeannette, le jour-même, à peine le soleil levé : « Joyeux nous, amour de ma vie ».",
          EN: "",
        },
      },
      {
        startSecond: 213,
        endSecond: 225,
        text: {
          CR: "Ansanm sa touzour inn ti-kado de qualité : in kolié de zendét fré, in ti-fomél satmaron aprivoizé, in zorié an divé tann de tang. É lu ?",
          FR: "Et avec ça toujours un cadeau de qualité : un collier de zendettes fraiches, une petite chatte des bois apprivoisée, un oreiller en duvet de jeune tangue. Et lui ?",
          EN: "",
        },
      },
      {
        startSecond: 225,
        endSecond: 233,
        text: {
          CR: "Bin wi koné, kan ou na poin d'tét ou na dé min. Alorse li té démerd an linprovizasion.",
          FR: "Tu sais, quand on n'a pas de tête on a des bras. Alors il improvisait.",
          EN: "",
        },
      },
      {
        startSecond: 233,
        endSecond: 248,
        text: {
          CR: "In roulo de féy brinzélié, in pins a épilé an bék de panyanké, in galé dékoratif « Mon shéridou tu kiét pa, sé lintantion tousala ». Bref boitbéf.",
          FR: "Un rouleau de feuille de bringellier, une pince à épiler en bec de paille-en-queue, un galet « Mon chéri ne t'inquiète pas, l'important c'est l'intention ». Bref.",
          EN: "",
        },
      },
      {
        startSecond: 248,
        endSecond: 259,
        text: {
          CR: "Marius lavé désidé sét ané sré diféran. Zanét i dor ankor, i rét a li in zourné antié. Dizon in léternité.",
          FR: "Marius avait décidé que cette année ce serait différent. Jeannette dort encore, il lui reste une journée entière, une éternité.",
          EN: "",
        },
      },
      {
        startSecond: 259,
        endSecond: 268,
        text: {
          CR: "Pokor di fine fé, a la Marius ki bék an d'sandan vitman ék léspérans trouv lo plis bandé kado po son lémé.",
          FR: "Aussitôt dit aussitôt fait, voilà Marius qui file rapidement avec l'espérance de trouver le plus beau cadeau possible à son aimé.",
          EN: "",
        },
      },
      {
        startSecond: 268,
        endSecond: 279,
        text: {
          CR: "Bordmèr li la fagot in bouké zarét poison (pa vilin vréman). Dann la foré li la aranz in savat pizon an rosh volkan (pa mové non plu).",
          FR: "En bord de mer il fait un bouquet en arêtes de poisson (plutôt beau). Dans la forêt, il lui construit des savates en roche volcanique (pas trop laides faut avouer).",
          EN: "",
        },
      },
      {
        startSecond: 279,
        endSecond: 286,
        text: {
          CR: "In zipon d'lékime (heu), in tyin-tété en grin d'filaos (koman dir)...",
          FR: "Un jupon d'écume (heu), un soutif en grain de filaos (comment dire)...",
          EN: "",
        },
      },
      {
        startSecond: 286,
        endSecond: 297,
        text: {
          CR: "Non vréman, navé poin ryin té lété sifizan po di tout son lamour Zanét. Alors, li la désid désot loséan.",
          FR: "Non vraiment, rien de suffisant pour dire tout son amour. Alors, il décide de sauter l'océan.",
          EN: "",
        },
      },
      {
        startSecond: 297,
        endSecond: 306,
        text: {
          CR: "Anfin travérs la mér po mont si lil an fas. Dé troi kat bras, pinn son pié pozé, na in lodér la ni satouy son troudné.",
          FR: "Enfin de traverser jusqu'à l'île d'en face. Deux ou trois brasses, à peine le pied posé, une odeur lui enivre le nez.",
          EN: "",
        },
      },
      {
        startSecond: 306,
        endSecond: 314,
        text: {
          CR: "In lodér, kom ké zamé li lavé santi, ké li la mét a suiv ziska tonm si in gran touf Zérb Vért",
          FR: "Une odeur, comme il n'en a jamais sentie, qu'il se met à suivre jusqu'à tomber sur une grosse touffe d'herbe",
          EN: "",
        },
      },
      {
        startSecond: 314,
        endSecond: 320,
        text: {
          CR: "Prés osi long ké li é ké té i san bon. Mé san bon.",
          FR: "Aussi haute que lui et qui sentait bon. Mais bon.",
          EN: "",
        },
      },
      {
        startSecond: 320,
        endSecond: 327,
        text: {
          CR: "Sitantelman ké li la alonz dodan é ké li la trouvé : a la son kado po Zanét !",
          FR: "Tellement qu'il s'allonge dedans et qui lui vient l'idée : voilà le plus beau des cadeaux pour Jeannette !",
          EN: "",
        },
      },
      {
        startSecond: 327,
        endSecond: 336,
        text: {
          CR: "In lodèr d'san-bon ! Vitman Marius la romont lo dé mansh shomiz ké li té port pa é li la komans tir a tout fors si lo touf Zerb.",
          FR: "Une odeur qui sent bon ! Vite Marius remonte les manches de la chemise qu'il ne porte pas et commence à tirer de toutes ses forces.",
          EN: "",
        },
      },
      {
        startSecond: 336,
        endSecond: 345,
        text: {
          CR: "Mé lo rasine lété fo kroir byien tro kosto. Alorse li la roroul a li anndan po kap lodèr dosi son po.",
          FR: "Mais les racines sont trop profondément plantées. Alors il se roule dedans pour s'imprégner de l'odeur.",
          EN: "",
        },
      },
      {
        startSecond: 345,
        endSecond: 352,
        text: {
          CR: "Mé pinn li la plonz dann lo d'mèr ké la fané, tourné viré, tourné viré.",
          FR: "Mais à peine entré dans la mer, l'odeur est lavée, tourné viré, tourné viré.",
          EN: "",
        },
      },
      {
        startSecond: 352,
        endSecond: 359,
        text: {
          CR: "Marius ké lété pa la moitié in kouyon la trouv la solision : fout son tét dann lo touf Zerb.",
          FR: "Marius qui n'était pas la moitié d'un imbécile trouve une solution : il enfonce sa tête dans les herbes.",
          EN: "",
        },
      },
      {
        startSecond: 359,
        endSecond: 365,
        text: {
          CR: "Inspiré, inspiré, inspiré, inspiré, inspiré, ziska prés fét pét poumon.",
          FR: "Inspirer, inspirer, inspirer, inspirer, inspirer, jusqu'à s'éclater les poumons.",
          EN: "",
        },
      },
      {
        startSecond: 365,
        endSecond: 371,
        text: {
          CR: "Épisa bous lo troudné, plonzé, artourn vitman koté lémé.",
          FR: "Et puis se boucher le nez, plonger, rejoindre Jeannette de l'autre côté.",
          EN: "",
        },
      },
      {
        startSecond: 371,
        endSecond: 382,
        text: {
          CR: "Zanét té ki dor ankor, li la alonz koté él é li la souf dousman si son zoli figir tout lodèr-san-bon.",
          FR: "Jeannette qui dort encore, il s'allonge et lui souffle doucement l'odeur qui sent bon en plein visage.",
          EN: "",
        },
      },
      {
        startSecond: 382,
        endSecond: 391,
        text: {
          CR: "« Orrrrrrrrr luuuuuuuuu, wo toiiiiiii » la di Zanét, ké lavé zamé santi in nafèr komkésa.",
          FR: "« Orrrrrrrrr luuuuuuuuu, wo toiiiiiii » a fait Jeannette, qui n'avait jamais rien senti d'aussi merveilleux.",
          EN: "",
        },
      },
      {
        startSecond: 391,
        endSecond: 400,
        text: {
          CR: "Wi mésiédame la sosiété, Marius san konprann i véné d'invant le concept du diffuseur de parfum Resharjab.",
          FR: "Oui messieurs mesdames la société, Marius venait d'inventer le concept du diffuseur de parfum Rechargeable.",
          EN: "",
        },
      },
      {
        startSecond: 400,
        endSecond: 410,
        text: {
          CR: "Pars Zanét i anragoulé pa de se sitan bon lodèr de san bon. La pa besoin di azot mé mi di kaminm sétonzamé.",
          FR: "Parce que Jeanette ne se lassait pas de cette odeur qui sentait bon. Pas besoin de vous dire mais je le dis quand même.",
          EN: "",
        },
      },
      {
        startSecond: 410,
        endSecond: 423,
        text: {
          CR: "Apré inn zourné d'lalé-rotour rant lo touf zerb é son lémé, kan lariv lo soir, Marius la kapoté. Fatigé mé lo kèr plin lamour parfimé.",
          FR: "Après une journée d'aller-retours entre la touffe d'herbe et son aimé, quand la nuit est arrivée, Marius est tombé. Fatigué mais le coeur rempli d'amour parfumé.",
          EN: "",
        },
      },
      {
        startSecond: 423,
        endSecond: 432,
        text: {
          CR: "- Marius ou la parti san péyé ? - Bé kosa ? la fé Marius, révéyé an surso.",
          FR: "- Marius tu es parti sans payer ? - Comment ? a fait Marius, réveillé en sursaut.",
          EN: "",
        },
      },
      {
        startSecond: 432,
        endSecond: 442,
        text: {
          CR: "- Po insi dir ou la volé. - Mé vol koué ? - Mon lodèr-san bon Vétivér. - Véti koi ? - Mon touf zerb.",
          FR: "- Pour ainsi dire tu m'as volé. - Mais volé quoi ? - Mon odeur qui sent bon de vétiver. - Véti quoi ? - Ma touffe d'herbe.",
          EN: "",
        },
      },
      {
        startSecond: 442,
        endSecond: 456,
        text: {
          CR: "Zot lé pa péyé po kroir mé dobout dovan Marius ék Zanét, lavé lo Diab en personn. Lo Diab ki prétandé pa moin ét propriétér lo touf Zerb Vétivér.",
          FR: "Vous n'allez pas le croire mais debout devant Marius et Jeannette, le Diable en personne. Le Diable qui prétendait rien de moins qu'être le propriétaire de la touffe de vétiver.",
          EN: "",
        },
      },
      {
        startSecond: 456,
        endSecond: 461,
        text: {
          CR: "É donk dé son lodèr, é ki réklamé in gro lindemnité.",
          FR: "Et donc de son odeur, et qui réclamait de grosses indémnités.",
          EN: "",
        },
      },
      {
        startSecond: 461,
        endSecond: 476,
        text: {
          CR: "- Mé pey aou sanm kwé ? Larzon minm pokor invanté. Mé lo Diab té ki dézandiab pas : luisié, zavoka, madame Aude, Dominzo, tousala.",
          FR: "- Mais te payer avec quoi ? L'argent n'est même pas encore inventé. Mais le Diable ne désendiablait pas : l'huissier, madame Aude, Domenjod, et pire encore.",
          EN: "",
        },
      },
      {
        startSecond: 476,
        endSecond: 490,
        text: {
          CR: "Sé t'alorse ké Zanét l'avans dousman. - Mi konpran byin lo préjudis misié landiablé, si-an-ô nou sé d'moun onét, osi mi propoz aou péy aou sanm sa.",
          FR: "C'est alors que Jeannette s'est avancé doucement. - Je comprends bien le préjudice monsieur l'endiablé, nous sommes des gens honnêtes, aussi je propose de vous payer avec ça.",
          EN: "",
        },
      },
      {
        startSecond: 490,
        endSecond: 507,
        text: {
          CR: "Danm la min Zanét, troi zékay lor. - I sort si lo do la tortue qui porte le monde, Bondié la kado anou sa po nout krémayèr. I vo sirman pa out lodèr vétiver mé sé tout sa nou néna.",
          FR: "Dans la main de Jeannette, trois écailles d'or. - Elle vient du dos de la Tortue qui porte le monde, c'est un cadeau du Créateur pour notre crémaillère. Ça ne vaut sûrement pas ton odeur de vétiver mais c'est tout ce qu'on a.",
          EN: "",
        },
      },
      {
        startSecond: 507,
        endSecond: 516,
        text: {
          CR: "Lo diab la gél té ki bav ék kontantman aoir tonb si lé dé promié kouyon de lumanité.",
          FR: "Le diable bavait tellement il était content d'être tombé sur les deux premiers imbéciles de la création.",
          EN: "",
        },
      },
      {
        startSecond: 516,
        endSecond: 526,
        text: {
          CR: "- Akout byin la di Zanét. É Zanét dé zét an lèr lo troi zékay lor gilinggiing gilinggiing gilinggiing.",
          FR: "- Écoute, lui a dit Jeannette. Et Jeannette jette en l'air les trois écailles d'or gilinggiing gilinggiing gilinggiing",
          EN: "",
        },
      },
      {
        startSecond: 526,
        endSecond: 534,
        text: {
          CR: "- Ou la byin antandu navé konbyin ? - Troi, la fé lo Diab, mé donn astèr.",
          FR: "- Tu as bien entendu, il y en a combien ? - Trois, a fait le diable. Mais donne-les moi.",
          EN: "",
        },
      },
      {
        startSecond: 534,
        endSecond: 545,
        text: {
          CR: "- Ah non, Marius la vol out lodèr Zerb Vétivér, vou lé péyé ék troi zékay lor soit, mé zis sanm zot dézord.",
          FR: "- Ah non, Marius a volé l'odeur de ta touffe de vétiver, tu seras payé avec trois écailles d'or soit, mais avec leur bruit.",
          EN: "",
        },
      },
      {
        startSecond: 545,
        endSecond: 560,
        text: {
          CR: "I di dopi dann siél la antann Bondié rir. Kamu, lo Diab la ral son malèr pli loin. É i di ké dopi so tan-la, la Titile, personn la pa rovu a lu. Kriké ?",
          FR: "On dit que depuis le ciel on entendit le Créateur rire. Muet, le Diable traina son malheur ailleurs. Et que depuis, à la Petite-Île, personne ne l'a jamais revu. Kriké ?",
          EN: "",
        },
      },
      {
        startSecond: 560,
        endSecond: 572,
        text: {
          CR: "Zistoir i tard pa po fini mé avan sa mi rakont azot ké Marius ek Zanét san tardé la kit la Titile po ni abit parkoté lo touf vétiver.",
          FR: "L'histoire se termine mais avant il faut que je vous raconte que très vite Marius et Jeannette ont quitté leur bout de terre pour venir habiter près du vétiver.",
          EN: "",
        },
      },
      {
        startSecond: 572,
        endSecond: 577,
        text: {
          CR: "Tousa pokoué ? Bé po san bon po léternité ma fi !",
          FR: "Pourquoi ? Pour sentir bon pour l'éternité !",
          EN: "",
        },
      },
      {
        startSecond: 577,
        endSecond: 585,
        text: {
          CR: "In not zafèr. Guerlain, Chanel, Lanvin, san sanmanké zot i koné lo non tout bann gran parfumèr.",
          FR: "Autre chose encore. Guerlain, Chanel, Lanvin, vous connaissez tous le nom de ces grands parfumeurs.",
          EN: "",
        },
      },
      {
        startSecond: 585,
        endSecond: 596,
        text: {
          CR: "Zot i koné osi tout lo pé i utiliz lésans Vétiver. Mé es zot i koné po kosa sat la Titile lé réputé pardsi réputé ?",
          FR: "Vous savez aussi qu'ils utilisent tous de l'essence de vétiver. Mais est-ce que vous savez pourquoi celui de la Petite-Île est autant réputé ?",
          EN: "",
        },
      },
      {
        startSecond: 596,
        endSecond: 603,
        text: {
          CR: "Non ? Mi andouté, bé lés amoin di azot. Sé tout simplement pars dopi lo tan Marius ék Zanét",
          FR: "Non ? je m'en doutais. Tout simplement parce que depuis le temps de Marius et Jeannette",
          EN: "",
        },
      },
      {
        startSecond: 603,
        endSecond: 639,
        text: {
          CR: "Kinm dann pli ti flakon lésans Zerb Vétiver na in nafèr ou trouv an takon, sé lodèr lamour.",
          FR: "Même dans le plus petit flacon d'essence de vétiver, ce qu'on trouve en quantité, c'est l'odeur de l'amour.",
          EN: "",
        },
      },
    ],
  },
  5: {
    sentences: [
      {
        startSecond: 0,
        endSecond: 23,
        text: {
          CR: "Lantredé. Tèr-la, ousa out pié lé pozé i port lo non Lantredé.",
          FR: "L'Entre-deux. Cette terre où vous posez les pieds s'appelle L'Entre-deux.",
          EN: "",
        },
      },
      {
        startSecond: 23,
        endSecond: 38,
        text: {
          CR: "Akout. Dann lés, lo krié Zoizo sorsié Bra d'la Plinn. Dann loués, shanté bann maron lo bra Tsy laoza - Cilaos. É dann mitan, in kèr ki bat tanbour.",
          FR: "Écoute. A l'Est, les cris des oiseaux sorciers du Bras de la plaine. A l'Ouest, les chants marrons du bras Tsy laoza - Cilaos. Et au milieu, un cœur battant tambour.",
          EN: "",
        },
      },
      {
        startSecond: 38,
        endSecond: 48,
        text: {
          CR: "Lantredé. Akout. Tèr-la listoir lé gran. Isi minm la viv mon bann gramoun.",
          FR: "L'Entre-deux. Ecoute. Ici est une grande histoire. Ici ont vécu et sont morts mes aïeux.",
          EN: "",
        },
      },
      {
        startSecond: 48,
        endSecond: 59,
        text: {
          CR: "Vou ki travérs, és ou ansouvyin lo Roi Laverdure ék la Rinn Tsaralava, sinonsa Dimitil lo Gétèr, li minm la montanye i port lo non.",
          FR: "Toi qui passes, te souviens-tu du roi Laverdure et la reine Tsaralava, de Dimitile le guetteur, celui dont la montagne honore le nom.",
          EN: "",
        },
      },
      {
        startSecond: 59,
        endSecond: 69,
        text: {
          CR: "És ou ansouvyin lo bann gérié la libèr Jeanneton lo Mozanbikain. És ou ansouvyin ?",
          FR: "Te souviens-tu de ces hommes qui jadis libérèrent Jeanneton le Mozambicain. Te souviens-tu ?",
          EN: "",
        },
      },
      {
        startSecond: 69,
        endSecond: 80,
        text: {
          CR: "Mon gramoun té ki di souvan : la mémoire est ce par quoi l'homme sait qu'il est homme.",
          FR: "Mon grand-père disait souvent : la mémoire est ce par quoi l'homme sait qu'il est homme.",
          EN: "",
        },
      },
      {
        startSecond: 80,
        endSecond: 119,
        text: {
          CR: "É moin mi ansouvyin bann Betsimisarakas, bann Merinas, d'Antongil, Tamatave. L'odèr la poud, lo fwét kuir, lo poi la shéne, lo kri lo batan d'lam. É d'mon péyi ké lé rét laba si lorizon.",
          FR: "Et je me rappelle des Betsimisarakas, des Merinas, d'Antongil, de Tamatave, de l'odeur de la poudre, du cuir des fouets, du poids des chaînes, du cri des vagues. Et de mon pays à jamais resté loin.",
          EN: "",
        },
      },
      {
        startSecond: 119,
        endSecond: 133,
        text: {
          CR: "Mi apél Tangalamena. Mon non po d'vré la rét dann la grann foré d'Atsinanana, la ou moin la trouv la vi. La ou mon nonbril lé planté. Li appartyin selman la tér mon péyi.",
          FR: "Je m'appelle Tangalamena. Mon vrai nom je l'ai laissé aux grandes forêts d'Atsinanana, là où je suis né. Là où mon nombril a été planté. Il n'appartient qu'à la terre de mon pays.",
          EN: "",
        },
      },
      {
        startSecond: 133,
        endSecond: 164,
        text: {
          CR: "Akout. Mi apél Tangalamena é ala troisanzan mi rakont lo minm zistoir. Sat la zamé antandu va gainye lémérvéyman. É sat i koné tak la bék dann lémérvéyman.",
          FR: "Ecoute. Je m'appelle Tangalamena et voilà plus de trois cents ans que je raconte la même histoire. Que ceux qui l'entendent pour la première fois s'émerveillent. Et que ceux qui la connaissent déjà se taisent en s'émerveillant.",
          EN: "",
        },
      },
      {
        startSecond: 164,
        endSecond: 174,
        text: {
          CR: "Navé inn foi in Roi. Navé inn foi in Zoizo. In Zoizo telman zoli é rar ké li té tout la fierté lo roi.",
          FR: "Il était une fois un Roi. Il était une fois un Oiseau. Un oiseau tellement beau et rare qu'il faisait la fierté du roi.",
          EN: "",
        },
      },
      {
        startSecond: 174,
        endSecond: 189,
        text: {
          CR: "In Zoizo Vintsy. In Vintsy také d'inn kaz en lor. Po li, sak zour, mil zatansion. Tousa li domann lo Roi i donn a lu.",
          FR: "Un Oiseau Vintsy. Un Vintsy enfermé dans une cage dorée. Pour lui, chaque jour, mille attentions. Tout ce que l'oiseau veut aussitôt offert par le Roi.",
          EN: "",
        },
      },
      {
        startSecond: 189,
        endSecond: 204,
        text: {
          CR: "Mé in matin, kan la kestion lo Roi larivé : kosa i fré frémi out kèr zordi ? Zoizo la réponn : la liberté. Lo Roi la di non. Lo Zoizo larét santé.",
          FR: "Mais un matin, à la question : qu'est-ce qui ferait ton bonheur aujourd'hui ? L'Oiseau répond : la liberté. Le Roi refuse. L'oiseau cesse de chanter.",
          EN: "",
        },
      },
      {
        startSecond: 204,
        endSecond: 216,
        text: {
          CR: "É sak zour, po lo minm késtion : kosa i fré frémi out kèr zordi ? Lo Zoizo lavé lo minm répons : la Liberté.",
          FR: "Et à chaque jour, la même question : qu'est-ce qui ferait ton bonheur aujourd'hui ? L'oiseau désormais toujours répond : la liberté.",
          EN: "",
        },
      },
      {
        startSecond: 216,
        endSecond: 226,
        text: {
          CR: "Tou lé zan douz moi, lo minm lanfermnan. É lo minm soley ki lév si in kestion é ki dor si in minm répons.",
          FR: "Les jours se suivent comme se suivent les jours quand on est enfermé. Et toujours le soleil se lève sur une question et se couche sur un refus.",
          EN: "",
        },
      },
      {
        startSecond: 226,
        endSecond: 244,
        text: {
          CR: "In zour pa parey i ariv. So matin la, lo Zoizo i domann lo Roi alé rann vizit son frèr dann péyi Nosy Ve ousa k'na lontan d'sa lo Roi la kol a lu.",
          FR: "Un jour différent des autres arrive pourtant. Ce matin-là, l'Oiseau demande au Roi d'aller rendre visite à son frère sur l'île lointaine de Nosy Ve, où jadis il le captura.",
          EN: "",
        },
      },
      {
        startSecond: 244,
        endSecond: 254,
        text: {
          CR: "« Pass a lu mon konpliman. É kan ou va roni, ou va donn amoin la syinn ». Lo Roi i pé pa rofuz son domann...",
          FR: "« Donne-lui de mes nouvelles. Et quand tu reviendras, tu m'en donneras des siennes ». Le Roi ne peut pas refuser...",
          EN: "",
        },
      },
      {
        startSecond: 254,
        endSecond: 266,
        text: {
          CR: "Lo Roi i trouv somin, présa la mér. In voyaz long kom la Grande Terre, é prés in lané apré, li poz son pié dann péyi Nosy Ve.",
          FR: "Le Roi prend le chemin, puis la mer. Un voyage aussi long que la Grande Terre, et presque une année après, il pose les pieds sur l'île lointaine de Nosy Ve.",
          EN: "",
        },
      },
      {
        startSecond: 266,
        endSecond: 280,
        text: {
          CR: "Li arkoné lo santié ou na lontan li la pasé. Li travérs la klérièr é li arét désou Piedbwa Sakré. An lèr lo pli ot bransh in Zoizo i angard a lu an silans.",
          FR: "Il reconnait le sentier où il il y bien longtemps, il était passé. Traverse la clairière et s'arrête au pied de l'Arbre sacré. Sur la plus haute branche, le regarde un Oiseau, silencieux.",
          EN: "",
        },
      },
      {
        startSecond: 280,
        endSecond: 291,
        text: {
          CR: "« Mi vyin pass aou lo konpliman out frèr I viv dann mon palé » di lo Roi « Lo fra ou la vol amoin ? Mi akout aou » di lo Zoizo.",
          FR: "« Je viens te donner des nouvelles de ton frère qui vit dans mon palais », dit-il. « Le frère que tu m'as pris ? Je t'écoute » dit l'oiseau.",
          EN: "",
        },
      },
      {
        startSecond: 291,
        endSecond: 298,
        text: {
          CR: "É lo Roi i mét a rakonté : Lo klèr, manzé an poundiak, lo konfor, la kaz an lor",
          FR: "Et le Roi raconte : l'eau claire, la nourriture abondante, le confort, la cage.",
          EN: "",
        },
      },
      {
        startSecond: 298,
        endSecond: 319,
        text: {
          CR: "Pinn li antan lo mo kaz, lo Zoizo i tonm réd atèr, mor nét. Lo Roi i sobat po ranime a lu, inn tiork. É la moral déshiré li ropran somin ék la mér pandan in lané po son palé",
          FR: "Lorsqu'il prononce ce dernier mot, l'Oiseau tombe à ses pieds, raide mort. Le Roi tente en vain de le réanimer et l'âme torturée, reprend le chemin et la mer pendant une année entière vers son palais.",
          EN: "",
        },
      },
      {
        startSecond: 319,
        endSecond: 324,
        text: {
          CR: "Pokor rivé, lo Zoizo i domann : « Mon frèr i pass amoin konpliman ? »",
          FR: "A son arrivée, l'Oiseau l'interpelle : « Quels sont les nouvelles de mon frère ? »",
          EN: "",
        },
      },
      {
        startSecond: 324,
        endSecond: 336,
        text: {
          CR: "Ék la ont lo Roi i rét kamu. É sak matin, lo Zoizo i kri pli for ankor. Li vé konét, lo voyaz, lo péyi, lo piédbwa.",
          FR: "Le roi honteux n'ose rien raconter. Mais chaque jour plus fort, l'oiseau se met à crier. Il veut entendre parler du voyage, de son île, de son arbre.",
          EN: "",
        },
      },
      {
        startSecond: 336,
        endSecond: 347,
        text: {
          CR: "És li la trouv son fami ? És li la donn son frèr d'nouvél ? In matin, fatigé, lo Roi i réponn tout, kestion si kestion.",
          FR: "A-t-il vu sa famille ? A-t-il raconté comment sa vie se déroulait ? Un matin, n'en pouvant plus, le Roi répond, question après question.",
          EN: "",
        },
      },
      {
        startSecond: 347,
        endSecond: 359,
        text: {
          CR: "É saviré li fini si : « sé t'alor out fra la kaz kuyèr ». Pinn li antan sa, lo Zoizo i tonm réd dann la kaz.",
          FR: "Et enfin tristement dit : « c'est à ce moment-là que ton frère est mort ». A ce dernier mot, l'Oiseau tombe du perchoir au sol de la cage dorée.",
          EN: "",
        },
      },
      {
        startSecond: 359,
        endSecond: 380,
        text: {
          CR: "Lo Roi i rouv vitman baro, li koz, li bérs, li réshof lo Zoizo, inn tiork. La moral déshiré aoir kine dé frèr, li poz lo Zoizo si lo robor d'fénét. Li férm son zié, kan li rouv : oki d'Zoizo.",
          FR: "Le roi ouvre la cage, parle, berce, essaie de réchauffer l'Oiseau, rien n'y fait. Le cœur déchiré d'avoir causé la mort des deux frères, il pose l'Oiseau sur le rebord de sa fenêtre, ferme les yeux. Quand il les ouvre : plus d'Oiseau.",
          EN: "",
        },
      },
      {
        startSecond: 380,
        endSecond: 402,
        text: {
          CR: "Sispandiyé si la brans piédbwa an fas, li angard lo Roi é li sant kom in gazé.",
          FR: "Perché sur la branche de l'arbre d'en face, celui-ci regarde le Roi et chante à tue-tête.",
          EN: "",
        },
      },
      {
        startSecond: 402,
        endSecond: 438,
        text: {
          CR: "« Arvyin » i kri lo Roi. Lo Zoizo zorey koson dann marmit d'poi i di lo Roi « Mon fra la pa mor, pa plis ké moin. Li la zis mont amoin parkoman prann la liberté ».",
          FR: "« Reviens » dit le roi. L'Oiseau refuse et en s'envolant dit au Roi de se consoler... « Mon frère n'est pas mort, pas plus que moi, il m'a juste montré comment prendre ma liberté ».",
          EN: "",
        },
      },
      {
        startSecond: 438,
        endSecond: 446,
        text: {
          CR: "Mi apel Tangalamena. Mon non i trouv pa dann liv, ni si bann stèl.",
          FR: "Je m'appelle Tangalamena. Tu ne trouveras mon nom nulle part, dans aucun livre, sur aucune stèle.",
          EN: "",
        },
      },
      {
        startSecond: 446,
        endSecond: 453,
        text: {
          CR: "Mé ansouvyin aou, aou ké néna lo pié pozé terla . Ké kan vi mars sé dan nout tras.",
          FR: "Mais souviens-toi, toi qui as le pied posé ici, que tu marches dans chacun de nos pas.",
          EN: "",
        },
      },
      {
        startSecond: 453,
        endSecond: 506,
        text: {
          CR: "É ké tout piton i trouv si-an-ô i port nout non. É sirtou fra d'zordi ansouvyin byin, ké si la Liberté i donn pa nou pé kaminm arashé.",
          FR: "Et que chacun des pitons qui se trouvent sur ton chemin porte nos noms. Et surtout frère de maintenant, rappelle-toi que si la liberté ne se donne pas, on peut pour autant l'arracher.",
          EN: "",
        },
      },
    ],
  },
  3: {
    sentences: [
      {
        startSecond: 0,
        endSecond: 23,
        text: {
          CR: "Les makes. Si-an-ô nou lé Sin-Lwi dann bor la nuit, dann la klerté d'zétoil",
          FR: "Les Makes. Ici nous sommes à Saint-Louis juste au bord de la nuit, dans la clarté des étoiles.",
          EN: "",
        },
      },
      {
        startSecond: 23,
        endSecond: 38,
        text: {
          CR: "Soman alé pa kroir marmay ké la touzour été komkésa. Néna in tan, in tan davan lo tan davan ou la nwit té san zétoil, san line, san klerté.",
          FR: "Seulement n'allez pas croire que tout a toujours été comme ça. Il est un temps, un temps d'avant le temps d'avant, où la nuit était sans étoiles, sans lune, sans lumière",
          EN: "",
        },
      },
      {
        startSecond: 38,
        endSecond: 49,
        text: {
          CR: "Rod pa raplé zot té kinm minm pokor énét. Rézman po anouvnans tout néna zistoir.",
          FR: "Ne cherchez pas à vous en rappeler vous n'étiez pas encore nés. Heureusement que pour se souvenir de tout il y a les histoires.",
          EN: "",
        },
      },
      {
        startSecond: 49,
        endSecond: 64,
        text: {
          CR: "É so zistoir, sé l'zistoir in bonom, in madame. Tou lé dé zansanm, dopi zot zéne-tan ziska zordi, dann bor la gramounité.",
          FR: "Et cette histoire, c'est l'histoire d'un homme, d'une femme. Tous les deux ensemble de la naissance à aujourd'hui, au bord de la vieillesse.",
          EN: "",
        },
      },
      {
        startSecond: 64,
        endSecond: 77,
        text: {
          CR: "Inn ék lot toultan, mé po toultan tousél, san zanfan. Sé pa pokoué, sé pa akoz, personn i koné pa.",
          FR: "Toujours ensembles, mais pourtant seuls, sans enfant. Je ne sais ni pourquoi, ni à cause, personne ne le sait.",
          EN: "",
        },
      },
      {
        startSecond: 77,
        endSecond: 88,
        text: {
          CR: "Mé sat mi koné sé ké lo madame navé lo kèr an shonbli. Él noré tan émé tan tonm an void'fami.",
          FR: "Mais ce que je sais c'est que la femme avait le cœur tout froissé. Elle aurait tant aimé être mère.",
          EN: "",
        },
      },
      {
        startSecond: 88,
        endSecond: 113,
        text: {
          CR: "Alorse in zour él la di komsa sanm son mari : « Somanké dann la foré néna bonpé zanimo bandoné ké noré byin bésoin in papa ék in moman. Osi asoir dann lèr la shas tang, promié baba zanimal ki kroiz vout rout, raminm a lu la kaz é li sora nout zanfan ».",
          FR: "Alors un jour, elle dit à son mari : « Peut-être que dans la forêt, il y a des petits d'animaux abandonnés, qui auraient bien besoin d'un papa et d'une maman. Aussi ce soir, quand tu iras à la chasse, le premier bébé animal que tu trouveras, ramène-le à la maison et ce sera notre enfant ».",
          EN: "",
        },
      },
      {
        startSecond: 113,
        endSecond: 135,
        text: {
          CR: "Ti-brine pinn rantré, nout shasèr la mét la foré sou son pié. É li la mét a marshé, marshé, marshé ziska tonm si in mizing baba zanimal. In vér d'tèr.",
          FR: "La nuit à peine tombée, notre chasseur a mis la forêt sous ses pieds. Et il s'est mis à marcher, marcher, marcher jusqu'à tomber sur un minuscule animal. Un verre de terre.",
          EN: "",
        },
      },
      {
        startSecond: 135,
        endSecond: 149,
        text: {
          CR: "Son madame lavé byin di : lo promié baba zanimal. Alorse anon, lo shasér la prann lo baba vérd'tèr dann son min é li la ropran somin son kaz.",
          FR: "Sa femme lui avait bien dit : le premier animal que tu trouveras. Alors pourquoi pas, le chasseur prit le petit ver de terre dans sa main et reprit le chemin de sa maison.",
          EN: "",
        },
      },
      {
        startSecond: 149,
        endSecond: 163,
        text: {
          CR: "« Or mé koman li lé zoli, angard koman li angard anou, koman li rosanm a vou, na apél a li Horas ».",
          FR: "« Oh mais comme il est joli, regarde comment il nous regarde, comment il te ressemble. Nous allons l'appeler Horace ».",
          EN: "",
        },
      },
      {
        startSecond: 163,
        endSecond: 179,
        text: {
          CR: "San tardé, lo moman la dépoz son garson-ladoption dann inn bol. San zoublié mét, po son manzé, dé fey lastron byin tann. É él la pran son li, lo kèr ranpli la zoi la maternité.",
          FR: "Et sans plus tarder la mère dépose son garçon d'adoption dans un bol. Sans oublier, pour son repas, deux petites feuilles de salade bien tendre. Et elle pris son lit, le cœur rempli d'être enfin maman.",
          EN: "",
        },
      },
      {
        startSecond: 179,
        endSecond: 199,
        text: {
          CR: "Lo landmin matin, bardzour pinn lévé, él i sa oir son baba lé lété pi dann bol telman déza li lavé grandi. Mé somansa in gine, kinm in ti-boushé, lo féy lastron li lavé pa toushé.",
          FR: "Le lendemain matin, à peine le jour levé, elle est allée voir son fils qui n'était plus dans le bol tellement déjà il avait grandi. Cependant, il n'avait pas touché à la salade.",
          EN: "",
        },
      },
      {
        startSecond: 199,
        endSecond: 215,
        text: {
          CR: "Zot i koné koman bann moman i lé. Madame la, la prés vid lo farfar. Pars somanké li profèr in grin mayi tann. Mé lo baba i vé pa gouté minm, kinm minm in ti trépé.",
          FR: "Vous savez comment sont les mères. Celle-ci a presque vidé tous les placards. Peut-être qu'il préférerait un train de maïs tendre. Mais le petit ver de terre ne voulait rien goûter, même un tout petit peu.",
          EN: "",
        },
      },
      {
        startSecond: 215,
        endSecond: 239,
        text: {
          CR: "Somanké pétét son bous lé fin, li noré préférans in frui d'foré. Oki lo frui i pé touzour gaté, kaminm in zié dosi li pa pozé. Zananm, fig, zat, konflor, maniok, zariko. Zéro kalbas la fimé granboi, lo baba vèrd'tér in tiork li manz pa.",
          FR: "Peut-être qu'il avait le palais fin et qu'il aurait préféré un fruit de la forêt. Mais le fruit pouvait bien pourrir, même d'un oeil il ne l'avait pas touché. Zananm, fig, zat, konflor, maniok, zariko. Rien de rien, le petit verre de terre ne mangeait rien.",
          EN: "",
        },
      },
      {
        startSecond: 239,
        endSecond: 255,
        text: {
          CR: "Bé, vi koné koman lo kèr in moman i lé. Tout la zourné la syinn la tourn an traka. Wi koné byin, goni vid li tyin pa dobout. Parlon pu si lo goni sé t'in baba tann.",
          FR: "Vous savez comment est le cœur d'une mère. Toute la journée le sien s'était rempli de peine. Vous savez bien ce qu'on dit : le sac vide ne tient pas debout. D'autant plus si ce sac est un tout petit-enfant.",
          EN: "",
        },
      },
      {
        startSecond: 255,
        endSecond: 290,
        text: {
          CR: "Dodo mon baba, dodo. Dodo mon baba, dodo. Dodo mon baba, tél wa lévé wa giny tété. Dodo mon baba, tél wa lévé wa giny tété.",
          FR: "Dodo mon baba, dodo. Dodo mon baba, dodo. Dodo mon baba, tél wa lévé wa giny tété. Dodo mon baba, tél wa lévé wa giny tété.",
          EN: "",
        },
      },
      {
        startSecond: 290,
        endSecond: 308,
        text: {
          CR: "So soir-la, kan son shasér d'mari la rant o kan, li minm té an gro traka. Zistorézon, sétansi jibié navé poin tro é dann son bésas, in ti mizing zoizo.",
          FR: "Ce soir-là, quand son chasseur de mari était rentré, lui aussi était en peine. Pour une bien bonne raison, il n'y avait presque pas de gibier et dans sa besace, un tout minuscule oiseau.",
          EN: "",
        },
      },
      {
        startSecond: 308,
        endSecond: 330,
        text: {
          CR: "Még mé még, si ou loré vu ou noré, san manké, pran pitié. Mé ou koné gramoun i di : i vo mié pa bonpé ké ryin dann zabo. Alors lo madame la komans plimé. É kom san fé t'éxpré él la pran lo kèr él la donn Horas.",
          FR: "Tellement maigre, que si vous l'aviez vu, vous l'auriez pris en pitié. Mais vous savez ce qu'on dit : il vaut mieux très peu que rien dans le ventre. Alors la femme a commencé à le préparer. Et sans y faire attention, elle prit le cœur de l'oiseau et le donna à Horace.",
          EN: "",
        },
      },
      {
        startSecond: 330,
        endSecond: 344,
        text: {
          CR: "Kom an goulapia d'karnivorité zamé rasasié, Horas la gob, in kou in sél, lo kèr tann lo ti-zoizo tann, é zis apré li la mét a krié.",
          FR: "Qui comme un ogre affamé, d'un coup d'un seul, goba le cœur du petit oiseau tendre, et juste après s'est mis à crier.",
          EN: "",
        },
      },
      {
        startSecond: 344,
        endSecond: 367,
        text: {
          CR: "« Ma fin moman ma fin papa Ma fin moman ma fin papa ». Toute la nuit ziska granmatin, ou ké, plis bonèr ké bonèr, son papa la sann la foré. Ziska ronir lo soir ék in név kèr tann ké li lavé batay po trouvé.",
          FR: "« J'ai faim maman j'ai faim papa ». Toute la nuit jusqu'au matin, où très très tôt, son chasseur de père s'en est allé en forêt. Jusqu'à revenir le soir avec un nouveau petit cœur tendre.",
          EN: "",
        },
      },
      {
        startSecond: 367,
        endSecond: 396,
        text: {
          CR: "« Ma fin moman ma fin papa Ma fin moman ma fin papa ». É landmin, si landmin, si landmin, plis loin ké loin, lo shasèr té ki anfons dann la foré. Sa po niabout fane lapéti son sanfon d'zanfan. kèr tang, kèr d'kay, kèr d'papang, kèr d'koson sovaz. « Ma fin moman ma fin papa Ma fin moman ma fin papa ».",
          FR: "« J'ai faim maman j'ai faim papa ». Et lendemain sur lendemain sur lendemain, plus loin que loin le chasseur s'enfonçait dans la forêt pour trouver de quoi contenter le féroce appétit de son enfant. Cœur de hérisson, de cailles, de buses, de cochon sauvage. « J'ai faim maman j'ai faim papa j'ai faim maman j'ai faim papa ».",
          EN: "",
        },
      },
      {
        startSecond: 396,
        endSecond: 430,
        text: {
          CR: "Dodo mon baba, dodo. Dodo mon baba, dodo. Soso mon baba twé lé tro tan po gromanzé. Soso mon baba twé lé tro tan po gromanzé.",
          FR: "Dodo mon baba, dodo. Dodo mon baba, dodo. Soso mon baba twé lé tro tan po gromanzé. Soso mon baba twé lé tro tan po gromanzé.",
          EN: "",
        },
      },
      {
        startSecond: 430,
        endSecond: 450,
        text: {
          CR: "É tout matin kan zot té ki lév Horas lavé grandi. Li té niabout pi rét an plas si la tab, kinm lo kan té fine ariv séré. Lété pi in kaniki vér-d'tèr mé in bél bél papa serpan.",
          FR: "Et tous les matins quand ils se levaient, Horace avait grandi. Il ne tenait plus sur la table, même la maison était devenue trop petite. Ce n'était plus un tout petit ver de terre mais un immense serpent.",
          EN: "",
        },
      },
      {
        startSecond: 450,
        endSecond: 468,
        text: {
          CR: "Ké lavé fin. Toultan. Lariv in zour, trakasé son sankontanté, son papa la trouv lo bor la foré, pa loin in gran vilaz. É dovan son zié karkiyé : in fanm.",
          FR: "Qui avait faim. Tout le temps. Un jour à force de s'y enfoncer, le chasseur buta sur le bord de la forêt, pas loin d'un grand village. Et devant ses yeux surpris : une femme.",
          EN: "",
        },
      },
      {
        startSecond: 468,
        endSecond: 494,
        text: {
          CR: "Li di komsa an li minm : na poin ryin d'pli gran d'pli nourisan ké lo kèr in fanm. Li ézit, mé dann son tét li antann krié tout la vorasité son serpan d'zanfan. Alorse li tir son fizi, li arm, pré po tiré. Mé li lavé pa vu ké derièr lu, navé lo bononm lo madame.",
          FR: "Il se dit en lui-même : il n'y a rien de plus nourrissant que le cœur d'une femme. Il hésite mais dans sa tête il entend crier toute la voracité de son serpent d'enfant. Alors il sort son fusil, il arme, s'apprête à tirer. Mais il n'avait pas vu que derrière lui, il y avait le mari de la femme",
          EN: "",
        },
      },
      {
        startSecond: 494,
        endSecond: 513,
        text: {
          CR: "Ki san mayé la pran son kouto. La plant dann lo do lo shasèr. Lo san, Lodèr lo san. Horas, lot bout la foré, la santi lodèr la mor son papa.",
          FR: "Qui prend un grand couteau et le plante dans le dos du chasseur. Le sang, l'odeur du sang. Horace, à l'autre bout de la forêt sent l'odeur de la mort de son père.",
          EN: "",
        },
      },
      {
        startSecond: 513,
        endSecond: 533,
        text: {
          CR: "I di piédboi té ki arash kom zoumine sou sa kolèr. É ké kan san tardé li la ariv dann milié lo vilaz, li la tonm si son papa dann in basin d'san. Alorse Horas la rant dann in kolèr plis noir k'a nuit ké té antour azot.",
          FR: "On dit que les arbres s'arrachaient comme des brindilles sous sa colère. Et que très vite il est arrivé au milieu du village, qu'il a vu son père dans un bain de sang. Alors Horace est entré dans une colère plus noire que la nuit qui les entouraient.",
          EN: "",
        },
      },
      {
        startSecond: 533,
        endSecond: 561,
        text: {
          CR: "É ké li la kronm lo vilaz komkési li té kronm in kèr d'volay. Ké bann zabitan po ansové la tir foursh, sab, fizi. Ziska, ziska k'Horas manzé par la doulèr la sot an lèr. Plis o ké lo vilaz, plis o ké la foré, Plis ké bann niaz. Ziska bat ék lo siél.",
          FR: "Qu'il s'est mis à dévorer le village comme si c'était un cœur d'oiseau. Que les habitants ont pris leurs fourches, leurs sabres, leurs fusils. Jusqu'à, jusqu'à ce qu'Horace, dévoré par la douleur saute vers le ciel. Plus haut que le village, plus haut que la forêt, plus haut que les nuages. Jusqu'à se cogner contre le ciel.",
          EN: "",
        },
      },
      {
        startSecond: 561,
        endSecond: 585,
        text: {
          CR: "I rakont ké kan son bann zékay la frot ansanm, la grafiné, é ké din kou rido la nuit la rouv an gran. La line o plin, zétoil an gro zékli, konstelasion an paké.",
          FR: "On raconte que quand ses écailles ont frotté le ciel, elles ont déchiré le rideau de la nuit qui s'est ouvert en grand. Sur la lune pleine, les étoiles brillantes, les constellations à perte de vue.",
          EN: "",
        },
      },
      {
        startSecond: 585,
        endSecond: 602,
        text: {
          CR: "Sé so zour la ké la énét : la voûte céleste. Marmay, si zistoir la lé mantèr Rod pa kisa lotèr. Zot i konétra pa.",
          FR: "C'est ce jour-là qu'est née : la voûte céleste. Si cette histoire est un mensonge ne cherchez pas qui en est l'auteur. Vous ne le saurez pas.",
          EN: "",
        },
      },
      {
        startSecond: 602,
        endSecond: 625,
        text: {
          CR: "Mé astèr sat zot i koné, sé dopi kansa si la tèr la nwit lé klér É ké dann siél néna in gran serpan i kri la fin. Ké li manz trou noir, zétoil filant kom lo kèr. Néna ki di minm kin zour li va anval tout lunivér.",
          FR: "Mais désormais ce que vous savez, c'est depuis quand les nuits sont éclairées. Et que dans le ciel il y a un grand serpent qui crie sa faim. Qu'il dévore les trous noirs, les étoiles filantes, comme des cœurs d'oiseaux. Il y en a même qui disent qu'un jour il avalera tout l'univers.",
          EN: "",
        },
      },
      {
        startSecond: 625,
        endSecond: 674,
        text: {
          CR: "Lolo mon baba, lolo. Lolo mon baba, lolo. Lolo mon baba panga namanm dann ti gozyé. Lolo mon baba panga namanm dann ti gozyé. Lolo mon baba panga namanm dann ti gozyé.",
          FR: "Lolo mon baba, lolo. Lolo mon baba, lolo. Lolo mon baba panga namanm dann ti gozyé. Lolo mon baba panga namanm dann ti gozyé. Lolo mon baba panga namanm dann ti gozyé.",
          EN: "",
        },
      },
    ],
  },
  4: {
    sentences: [
      {
        startSecond: 0,
        endSecond: 14,
        text: {
          CR: "Cilaos",
          FR: "Cilaos",
          EN: "Cilaos",
        },
      },
      {
        startSecond: 14,
        endSecond: 18,
        text: {
          CR: "Si-an-o nou lé Cilaos",
          FR: "Ici nous sommes à Cilaos",
          EN: "",
        },
      },
      {
        startSecond: 18,
        endSecond: 19,
        text: {
          CR: "Tu san lèr fré ?",
          FR: "Tu sens l’air frais ?",
          EN: "",
        },
      },
      {
        startSecond: 19,
        endSecond: 21,
        text: {
          CR: "Tu antann lo silans ?",
          FR: "Tu entends le silence ?",
          EN: "",
        },
      },
      {
        startSecond: 21,
        endSecond: 24,
        text: {
          CR: "Tu gainye frison dovan tant de beauté ?",
          FR: "Tu frissonnes devant tant de beauté ?",
          EN: "",
        },
      },
      {
        startSecond: 24,
        endSecond: 26,
        text: {
          CR: "Alorse tu konpran pokoi je shant !",
          FR: "Alors tu sais pourquoi je chante !",
          EN: "",
        },
      },
      {
        startSecond: 26,
        endSecond: 28,
        text: {
          CR: "Pars ké kan tu na la shans abit si-an-ô",
          FR: "Parce que quand tu as la chance de vivre ici",
          EN: "",
        },
      },
      {
        startSecond: 28,
        endSecond: 31,
        text: {
          CR: "Rant la ravine flérzoné é lo piton dé neiges",
          FR: "Entre la Ravine Fleur jaune et le Piton des Neiges",
          EN: "",
        },
      },
      {
        startSecond: 31,
        endSecond: 37,
        text: {
          CR: "Ton kèr est une goutte de rosée fraîche caressée par les vôlutes de l’alizé.",
          FR: "Ton cœur est une goutte de rosée fraîche caressée par des volutes de l’alizé.",
          EN: "",
        },
      },
      {
        startSecond: 37,
        endSecond: 41,
        text: {
          CR: "Té, j’aime tro la fonnkézri !",
          FR: "Té, j’aime trop la poésie !",
          EN: "",
        },
      },
      {
        startSecond: 41,
        endSecond: 50,
        text: {
          CR: "Mémé dizé toujours : Daniel de ta bouche sort de la dantél lanbrokin plus délika zankor que le plus délicat des jours de Cilaos.",
          FR: "Ma grand-mère disait toujours : Daniel de ta bouche sort de la dentelle de lambrequin plus délicat encore que le plus délicat des Jours de Cilaos.",
          EN: "",
        },
      },
      {
        startSecond: 50,
        endSecond: 52,
        text: {
          CR: "Mémé",
          FR: "Mémé",
          EN: "",
        },
      },
      {
        startSecond: 52,
        endSecond: 54,
        text: {
          CR: "In fi si-an-o osi él.",
          FR: "Une fille d’ici aussi.",
          EN: "",
        },
      },
      {
        startSecond: 54,
        endSecond: 58,
        text: {
          CR: "Sé él ke la done a moi le gou de la poézri.",
          FR: "C’est elle qui m’a donné le goût de la poésie.",
          EN: "",
        },
      },
      {
        startSecond: 58,
        endSecond: 59,
        text: {
          CR: "Il pleut.",
          FR: "Il pleut.",
          EN: "",
        },
      },
      {
        startSecond: 59,
        endSecond: 60,
        text: {
          CR: "Les pins sont noirs",
          FR: "Les pins sont noirs",
          EN: "",
        },
      },
      {
        startSecond: 60,
        endSecond: 62,
        text: {
          CR: "Et la fôrét se noie Dans la brume du soir",
          FR: "Et la fôrét se noie Dans la brume du soir",
          EN: "",
        },
      },
      {
        startSecond: 62,
        endSecond: 67,
        text: {
          CR: "Regarde sur la vigne cette écharpe de soie qui recourbe sa ligne",
          FR: "Regarde sur la vigne cette écharpe de soie qui recourbe sa ligne",
          EN: "",
        },
      },
      {
        startSecond: 67,
        endSecond: 69,
        text: {
          CR: "Un soir a Cilaos.",
          FR: "Un soir a Cilaos.",
          EN: "",
        },
      },
      {
        startSecond: 69,
        endSecond: 70,
        text: {
          CR: "Alcide Baret",
          FR: "Alcide Baret",
          EN: "",
        },
      },
      {
        startSecond: 70,
        endSecond: 73,
        text: {
          CR: "Té in linstitèr d’sinlwi",
          FR: "C’était un instituteur de Saint-Louis",
          EN: "",
        },
      },
      {
        startSecond: 73,
        endSecond: 74,
        text: {
          CR: "Amoureux d’isi-an-ô",
          FR: "Amoureux d’ici lui aussi",
          EN: "",
        },
      },
      {
        startSecond: 74,
        endSecond: 76,
        text: {
          CR: "Sé lu ke la ékri sa dan les années 30.",
          FR: "C’est lui qui a écrit cette poésie dans les années 30.",
          EN: "",
        },
      },
      {
        startSecond: 76,
        endSecond: 83,
        text: {
          CR: "Mé és aou ki mékout, és tu koné omoin kosa i vé dire Cilaos Non ?",
          FR: "Mais toi qui m’écoutes, est-ce que tu sais au moins ce que veut dire Cilaos Non ?",
          EN: "",
        },
      },
      {
        startSecond: 83,
        endSecond: 87,
        text: {
          CR: "Bé bouz pa j’an apél a ma grande culturité.",
          FR: "Ne bouge surtout pas je vais étaler là toute ma culture.",
          EN: "",
        },
      },
      {
        startSecond: 87,
        endSecond: 90,
        text: {
          CR: "Alors solon désertin zistoryin",
          FR: "Alors selon certains historiens",
          EN: "",
        },
      },
      {
        startSecond: 90,
        endSecond: 97,
        text: {
          CR: "I vyindré du non d’in lésklav malgas ké la mont maroné par si-an-o in désertin Tsilaos",
          FR: "Cela viendrait du nom d’un esclave malgache parti maron ici-même un certain Tsilaos",
          EN: "",
        },
      },
      {
        startSecond: 97,
        endSecond: 103,
        text: {
          CR: "Mé lo pli souvan domoun i akord po dir ké sé lo mo malgas Tsilaosa ké la donn lo non le sirk",
          FR: "Mais le plus souvent on s’accorde à dire que c’est le mot Malgache Tsilaosa qui a donné le nom du cirque",
          EN: "",
        },
      },
      {
        startSecond: 103,
        endSecond: 110,
        text: {
          CR: "Tsilaosa Le pays qu’on ne quitte pas ",
          FR: "Tsilaosa Le pays qu’on ne quitte pas",
          EN: "",
        },
      },
      {
        startSecond: 110,
        endSecond: 118,
        text: {
          CR: "Lé bon, ma konpri bé foutor, sé pa moin. Vi mazine byin ké si moin la bék in dénivlé 1200m dann mon vié yalbo turbo po mont la fét lantil",
          FR: "C’est bon, j’ai compris bah je sais pas moi. Tu penses bien que si j’ai grimpé 1200 m de dénivelé dans ma vieille Talbot turbo pour monter à la fête de la lentille",
          EN: "",
        },
      },
      {
        startSecond: 118,
        endSecond: 123,
        text: {
          CR: "La pa po ardsann toudsuit dotan plis apré dé troi vèr d’vin d’péyi.",
          FR: "Ce n’est pas pour redescendre aussitôt d’autant plus après quelques verres de vin de pays.",
          EN: "",
        },
      },
      {
        startSecond: 123,
        endSecond: 125,
        text: {
          CR: "Hin, an parlan de vin",
          FR: "En parlant de vin",
          EN: "",
        },
      },
      {
        startSecond: 125,
        endSecond: 132,
        text: {
          CR: "És ou la romarké ké lo vin de Cilaos lé prés toultan anboutéyé dann boutey Zony Walker ? Akozsa ?",
          FR: "Est-ce que tu as remarqué que le vin de Cilaos était presque toujours embouteillé dans des bouteilles de Johnny Walker ? Pourquoi ?",
          EN: "",
        },
      },
      {
        startSecond: 132,
        endSecond: 141,
        text: {
          CR: "Mi vé pa fé lo konésèr mé mi kroi byin na in rapor ek lo fét ké la Réunion nou boir 1% de la produksion mondial de Johnny walker. Ryin k’sa.",
          FR: "Je ne veux pas faire mon intellectuel mais je crois bien que c’est en rapport avec le fait qu’à La Réunion on boit 1 % de la production mondiale de Johnny Walker. Rien que ça.",
          EN: "",
        },
      },
      {
        startSecond: 141,
        endSecond: 145,
        text: {
          CR: "Si ou kroir pa ou pé vérifié. Tout fason ma dir a toi in nafèr",
          FR: "Si tu ne me crois pas tu peux vérifier. De toute façon je vais te dire quelque chose",
          EN: "",
        },
      },
      {
        startSecond: 145,
        endSecond: 149,
        text: {
          CR: "Si-an-o sé pa lo vin ki tourn la tét, sé la rout",
          FR: "Ici ce n’est pas le vin qui tourne la tête, c’est la route",
          EN: "",
        },
      },
      {
        startSecond: 149,
        endSecond: 153,
        text: {
          CR: "Ou koné konbyin de tournan néna po mont Cilaos toi ?",
          FR: "Au fait tu sais combien de tournants il y a pour monter à Cilaos ?",
          EN: "",
        },
      },
      {
        startSecond: 153,
        endSecond: 156,
        text: {
          CR: "Mi lés azot konté",
          FR: "Je te laisse compter",
          EN: "",
        },
      },
      {
        startSecond: 156,
        endSecond: 161,
        text: {
          CR: "Sinksan ou di ou ?",
          FR: "500 ?",
          EN: "",
        },
      },
      {
        startSecond: 161,
        endSecond: 166,
        text: {
          CR: "Dé mounoir inn a droit, inn a gosh.",
          FR: "2 mounoir 1 à droite, 1 à gauche.",
          EN: "",
        },
      },
      {
        startSecond: 166,
        endSecond: 168,
        text: {
          CR: "Mé bon rovnon zan za a la poézri",
          FR: "Mais bon revenons à la poésie",
          EN: "",
        },
      },
      {
        startSecond: 168,
        endSecond: 176,
        text: {
          CR: "Pars fo pa kroir, la poézri sé kom inn zéne fi fugase Lèr tu kroi trapé, él la fine ariv an lèr d’salaz. Fo ét vif",
          FR: "Il ne faut pas croire, la poésie c’est une jeune fille fugace Au moment où tu crois l’avoir attrapée, elle est déjà en haut des montagnes. Il faut être vif",
          EN: "",
        },
      },
      {
        startSecond: 176,
        endSecond: 181,
        text: {
          CR: "Moin jé ganié le don Ék mémé",
          FR: "Moi j’ai eu le don avec mémé",
          EN: "",
        },
      },
      {
        startSecond: 181,
        endSecond: 183,
        text: {
          CR: "Anéil, té komsa kél té apél",
          FR: "Anéil, c’était comme ça qu’elle s’appelait",
          EN: "",
        },
      },
      {
        startSecond: 183,
        endSecond: 190,
        text: {
          CR: "É parlfét si moin lé la asoir dovan vou dann zot zorey, sé po rann a él omaz",
          FR: "Et justement si je suis là ce soir devant vous dans vos oreilles, c’est pour lui rendre hommage",
          EN: "",
        },
      },
      {
        startSecond: 190,
        endSecond: 194,
        text: {
          CR: "É fé pran azot konsians dé linportans de la poézri",
          FR: "Et vous faire prendre conscience de l’importance de la poésie",
          EN: "",
        },
      },
      {
        startSecond: 194,
        endSecond: 202,
        text: {
          CR: "Pars la poézri la souvandéfoi ou kroi sé so santiman étranz ki tatrap lo kèr dann in sanson Alex Sorres.",
          FR: "Parce que la poésie parfois tu crois que c’est ce sentiment étrange qui t’attrape le cœur dans une chanson de Alex Soress.",
          EN: "",
        },
      },
      {
        startSecond: 202,
        endSecond: 208,
        text: {
          CR: "Mami mami mami Mémé moin lé maléré",
          FR: "Mami mami mami Mémé moin lé maléré",
          EN: "",
        },
      },
      {
        startSecond: 208,
        endSecond: 212,
        text: {
          CR: "Ou konpran ou la ? Bé sé pa sa mé pa du tou.",
          FR: "Tu comprends ? C’est pas ça Mais pas du tout.",
          EN: "",
        },
      },
      {
        startSecond: 212,
        endSecond: 219,
        text: {
          CR: "Sé byin plus ankor ké so frison d’kèr zamouré mé akout ou va konprann.",
          FR: "C’est bien plus encore que ce frison de cœur amoureux mais écoute tu vas comprendre.",
          EN: "",
        },
      },
      {
        startSecond: 219,
        endSecond: 222,
        text: {
          CR: "Anéil",
          FR: "Anéil",
          EN: "",
        },
      },
      {
        startSecond: 222,
        endSecond: 238,
        text: {
          CR: "Anéil té ki viv dann dernié bor lilét a kord Dan in tan ou ké navé poin ankor d’rout zis in santié d’faléz Ou ké galé té ki déboul si ou sitantelman vitman Ké ou lavé minm poin l’tan rokomann out lame o bondié",
          FR: "Anéil vivait au dernier bout de l’ilet à cordes dans un temps on avait pas encore de route juste un sentier sur la falaise Là où les rochers tombaient si rapidement Que tu n’avais même pas le temps de recommander ton âme à Dieu",
          EN: "",
        },
      },
      {
        startSecond: 238,
        endSecond: 247,
        text: {
          CR: "Sé komsa kél la perd son Mari : défin Marius ké lavé désid désann an vil fé komision Un soir d’syklone po rod la bouzi.",
          FR: "C’est comme ça qu’elle a perdu son mari : défunt Marius qui s’était mis en tête de descendre en ville faire des commissions Un soir de cyclone pour acheter des bougies.",
          EN: "",
        },
      },
      {
        startSecond: 247,
        endSecond: 251,
        text: {
          CR: "« Alé pa » lavé kri Anéil",
          FR: "« N’y va pas » avait supplié Aneil",
          EN: "",
        },
      },
      {
        startSecond: 251,
        endSecond: 254,
        text: {
          CR: "« Lé bon la » lavé réponn Marius",
          FR: "« Ça ira » avait répondu Marius",
          EN: "",
        },
      },
      {
        startSecond: 254,
        endSecond: 266,
        text: {
          CR: "Parlfèt son koko tét dur la rankont in kap plus dur ankor É an késtion de bouzi li la trouv la lumièr éternel. Pézasoname...",
          FR: "Par le fait sa tête trop dure avait rencontré un rocher plus dur encore et en question de bougies il avait trouvé la lumière éternelle. Paix à son âme...",
          EN: "",
        },
      },
      {
        startSecond: 266,
        endSecond: 274,
        text: {
          CR: "Partir de la mésié médame la sosiété otan dir zot toudsuit mon zistoir i sa pu fé rir du tout du tout du tout",
          FR: "A partir de là Monsieur Mesdames la société autant vous le dire tout de suite mon histoire ne va plus rien avoir de drôle",
          EN: "",
        },
      },
      {
        startSecond: 274,
        endSecond: 286,
        text: {
          CR: "Ral kleenex, sui zot zié ék la mansh palto, moush dann zerb tortu, sé pa mé trouv in moyin fane zot santimantalité san déranzé la dramaturgie du récit. Merci.",
          FR: "Prenez des Kleenex, essuyez-vous les yeux contre votre manche, mouchez-vous dans les hautes herbes, je ne sais pas mais trouvez un moyen d’éteindre vos sentiments sans déranger la dramaturgie du récit. Merci.",
          EN: "",
        },
      },
      {
        startSecond: 286,
        endSecond: 290,
        text: {
          CR: "Marius po Anéil lété...",
          FR: "Marius pour Aneil c’était...",
          EN: "",
        },
      },
      {
        startSecond: 290,
        endSecond: 293,
        text: {
          CR: "Lo promié grin d’lanti de la sézon",
          FR: "Le premier grain de lentille de la saison",
          EN: "",
        },
      },
      {
        startSecond: 293,
        endSecond: 295,
        text: {
          CR: "Lo kuyèr d’sik dann vin d’péyi",
          FR: "La cuillère de sucre dans son verre de vin",
          EN: "",
        },
      },
      {
        startSecond: 295,
        endSecond: 298,
        text: {
          CR: "La lanp pétrol de son fénoir",
          FR: "La lampe à pétrole de son obscurité",
          EN: "",
        },
      },
      {
        startSecond: 298,
        endSecond: 307,
        text: {
          CR: "É la din kou du sor, tout lanti lavé sék, lo vin lavé tourn amèr É partou lobskurité totale.",
          FR: "Et puis là d’un coup du sort, toutes les lentilles avaient séché, le vin était devenu amer, et partout l’obscurité totale.",
          EN: "",
        },
      },
      {
        startSecond: 307,
        endSecond: 311,
        text: {
          CR: "Fo dir osi té pa fasil la vi d’fanm-sél dann se fin fon d’bout Cilaos",
          FR: "Il faut dire aussi que ce n’était pas facile la vie d’une femme seule dans ce fin fond de Cilaos",
          EN: "",
        },
      },
      {
        startSecond: 311,
        endSecond: 319,
        text: {
          CR: "Mazine aou, nou lé dann lé zané trant, poin freedom, poin d’rézo soso, poin rozdézil",
          FR: "Imaginez-vous, on est dans les années 30, pas de radio, pas de réseaux sociaux, pas de boîte de nuit",
          EN: "",
        },
      },
      {
        startSecond: 319,
        endSecond: 323,
        text: {
          CR: "Lobskurité totale et définitive.",
          FR: "L’obscurité totale et définitive.",
          EN: "",
        },
      },
      {
        startSecond: 323,
        endSecond: 328,
        text: {
          CR: "Po Anéil toudinkou lo paradi lavé flamn an lanfèr",
          FR: "Pour Aneil tout d’un coup son paradis brûlait comme l’enfer",
          EN: "",
        },
      },
      {
        startSecond: 328,
        endSecond: 344,
        text: {
          CR: "E lo zoli flèr d’ranpar kél lété té ki fane, pétal an pétal, son padport, son boukan d’kaz, son kor, son lame, tout largé zo désespoir. Totalement, kriélman, suisidérman...",
          FR: "Et cette belle fleur de rempart qu’elle était s’était mise à faner, pétale par pétale, son jardin, sa vieille case, son corps, son âme tout était largué au désespoir. Totalement, cruellement, suicidairement...",
          EN: "",
        },
      },
      {
        startSecond: 344,
        endSecond: 348,
        text: {
          CR: "Oui médame zé ménonm, él lavé pans mét la kord.",
          FR: "Oui Mesdames et Messieurs, elle avait songé à se pendre.",
          EN: "",
        },
      },
      {
        startSecond: 348,
        endSecond: 356,
        text: {
          CR: "Du ciel tombe des cordes faut-il y grimper ou s’y pendre ?",
          FR: "Du ciel tombe des cordes faut-il y grimper ou s’y pendre ?",
          EN: "",
        },
      },
      {
        startSecond: 356,
        endSecond: 366,
        text: {
          CR: "Et c’est en attrapant la pelote de coton mouliné dans son nécessaire à broderie pour se suspendre au zarboutan de la case, qu’Anéil sauva sa vie.",
          FR: "Et c’est en attrapant la pelote de coton mouliné dans son nécessaire à broderie pour se suspendre au zarboutan de la case, qu’Anéil sauva sa vie.",
          EN: "",
        },
      },
      {
        startSecond: 366,
        endSecond: 378,
        text: {
          CR: "Bon mi koné tré byin zot la po dir azot : mais quelle drôle d’idée, le coton mouliné n’est pas du tout adapté, elle aurait mieux fait de choisir de la corde de chanvre beaucoup plus solide quoique plus irritante pour la peau.",
          FR: "Bon je sais bien ce que vous êtes en train de vous dire : mais quelle drôle d’idée, le coton mouliné n’est pas du tout adapté, elle aurait mieux fait de choisir de la corde de chanvre beaucoup plus solide quoique plus irritante pour la peau.",
          EN: "",
        },
      },
      {
        startSecond: 378,
        endSecond: 380,
        text: {
          CR: "Lés amoin réponn azot.",
          FR: "Laissez-moi vous répondre.",
          EN: "",
        },
      },
      {
        startSecond: 380,
        endSecond: 383,
        text: {
          CR: "Daborinn : i juj pa.",
          FR: "Tout d’abord on ne juge pas.",
          EN: "",
        },
      },
      {
        startSecond: 383,
        endSecond: 396,
        text: {
          CR: "Dabordé : kontrérman a sat nu peu kroir l’ilet a cordes i port trés mal son nom é parlfét. Té tout sak kél lavé sou la min mé bréf boitbéf Zot la po éloinye amoin de mon rési.",
          FR: "Et puis contrairement à ce qu’on peut croire l’ilet à cordes porte très mal son nom. Et c’est tout ce qu’elle avait sous la main mais bref vous allez m’éloigner de mon récit.",
          EN: "",
        },
      },
      {
        startSecond: 396,
        endSecond: 406,
        text: {
          CR: "Mi dizé don : c’est en attrapant la pelote de coton mouliné dans son nécessaire à broderie pour se suspendre au zarboutan de la case, qu’Anéil sauva sa vie",
          FR: "C’est en attrapant la pelote de coton mouliné dans son nécessaire à broderie pour se suspendre au zarboutan de la case, qu’Anéil sauva sa vie",
          EN: "",
        },
      },
      {
        startSecond: 406,
        endSecond: 410,
        text: {
          CR: "Fo konét osi Anéil lété Brodeuse mé pa ninportakél brodeuse",
          FR: "Il faut savoir aussi qu’Anéil était brodeuse mais pas n’importe quelle brodeuse",
          EN: "",
        },
      },
      {
        startSecond: 410,
        endSecond: 417,
        text: {
          CR: "Domoun si-an-ô té ki di ké tousat kél té ki brod té komsi ki proné vi.",
          FR: "Les gens d’ici disaient que tout ce qu’elle brodait prenait vie.",
          EN: "",
        },
      },
      {
        startSecond: 417,
        endSecond: 427,
        text: {
          CR: "Alors an él minm él la di : Somanké si mi brod Marius li riskap artrouv somin la vi ?",
          FR: "Alors en elle-même elle s’était dit : Peut-être que si je brodais un portrait de Marius il retournerait à la vie",
          EN: "",
        },
      },
      {
        startSecond: 427,
        endSecond: 434,
        text: {
          CR: "Bon, la nou minm nou koné kinm nou lé dann in zistoir, ni fo pa tro fane la mantri, lé inposib.",
          FR: "bon tous ici on sait même si on est dans une histoire, je ne vais pas trop vous mentir, c’est impossible.",
          EN: "",
        },
      },
      {
        startSecond: 434,
        endSecond: 439,
        text: {
          CR: "In moun kan li lé mor, li lé mor, anfin minm si défoi vréman i pé ét domi-mor",
          FR: "Quelqu’un qui est mort est mort, enfin même si parfois il peut être à demi-mort",
          EN: "",
        },
      },
      {
        startSecond: 439,
        endSecond: 448,
        text: {
          CR: "Domi-mor dann koma, domi-mor pré granraid, domi-mor sérébralman dovan Hanouna. Mé la té mort.",
          FR: "Demi-mort dans le coma, demi-mort après une randonnée, demi-mort cérébralement devant une émission d’Hanouna. Mais là il était mort.",
          EN: "",
        },
      },
      {
        startSecond: 448,
        endSecond: 456,
        text: {
          CR: "Et je vous le rappelle dans des circonstances terribles et irréversibles d’un rosh dur tombé droit din koko dur",
          FR: "Et je vous rappelle dans des circonstances terribles et irréversibles d’un rocher qui lui a fendu le crâne en deux",
          EN: "",
        },
      },
      {
        startSecond: 456,
        endSecond: 466,
        text: {
          CR: "Donc Marius lété kiné, sosiétévantanlérisé, kaskuiyéré. An in mo : Mo.",
          FR: "Donc Marius était mort, mort, mort. En un mot : mort.",
          EN: "",
        },
      },
      {
        startSecond: 466,
        endSecond: 479,
        text: {
          CR: "Mé Aniél navé rézon déspéré Sa i rodoné a él gou a la vi É la pa plus interdi de kroir an la résikréksion ko pouvoir dé la poézri. Sakinn son bondiésri.",
          FR: "Mais Anéil avait bien raison d’espérer. Ça lui redonnait le goût de vivre et il n’est pas plus interdit de croire en la résurrection qu’au pouvoir de la poésie. Chacun sa bondieuserie.",
          EN: "",
        },
      },
      {
        startSecond: 479,
        endSecond: 484,
        text: {
          CR: "Anéil la komans brodé lo soir minm é él la brod troi zan d’suit. Zour kom nwit.",
          FR: "Aneil avait commencé à broder le soir même, trois ans de suite. Jour comme nuit.",
          EN: "",
        },
      },
      {
        startSecond: 484,
        endSecond: 487,
        text: {
          CR: "In lané po lo siél blé an palto lamour",
          FR: "Une année pour le ciel bleu comme l’amour",
          EN: "",
        },
      },
      {
        startSecond: 487,
        endSecond: 490,
        text: {
          CR: "In lané po la montanye santiman",
          FR: "Une année pour la montagne de sentiments",
          EN: "",
        },
      },
      {
        startSecond: 490,
        endSecond: 496,
        text: {
          CR: "In lané ankor po l’portré Marius dobout dosou in zakaranda",
          FR: "Une année encore pour le portrait de Marius debout sous le zakaranda",
          EN: "",
        },
      },
      {
        startSecond: 496,
        endSecond: 507,
        text: {
          CR: "Kan kél la poz lo dernié poin son zié té ki plèr lo san. Son doi té prés toute torte mé dovan él navé in papa brodri.",
          FR: "Quand elle avait posé le dernier point ses yeux pleuraient du sang. Ses doigts étaient tout tordus mais devant elle il y avait une magnifique broderie.",
          EN: "",
        },
      },
      {
        startSecond: 507,
        endSecond: 512,
        text: {
          CR: "Sitantelamn gran kél lavé du tir dann la kaz, poz si d’zérb déor po angard an lantié",
          FR: "Tellement immense qu’elle avait dû la sortir de la maison, la poser sur la pelouse pour pouvoir la regarder en entier",
          EN: "",
        },
      },
      {
        startSecond: 512,
        endSecond: 519,
        text: {
          CR: "É dosi, kom in foto de zénéz, son pli gayar souvnans",
          FR: "Et dessus, comme une photo de jeunesse, son plus beau souvenir",
          EN: "",
        },
      },
      {
        startSecond: 519,
        endSecond: 526,
        text: {
          CR: "Marius, lo dan déor, dann son plis zoli kanzémaniok sou in pié zakaranda an flèr.",
          FR: "Marius, tout sourire, dans son plus joli costume sous un pied de zakaranda en fleurs.",
          EN: "",
        },
      },
      {
        startSecond: 526,
        endSecond: 533,
        text: {
          CR: "Anéil lavé brod lo zour zot rankont, anbalaba Cilaos kan kél lavé vu a lu po la promièr foi Zot promié randévou an tégor.",
          FR: "Anéil avait brodé le jour de leur rencontre, là-bas à Cilaos quand elle l’avait vu pour la première fois Leur premier rendez-vous en amoureux.",
          EN: "",
        },
      },
      {
        startSecond: 533,
        endSecond: 540,
        text: {
          CR: "Él lavé tonm amouréz toudsuit po toudsuit É lété parti po trantan lamour for.",
          FR: "Elle était tombé amoureuse tout de suite et c’était parti pour 30 ans d’amour passionné.",
          EN: "",
        },
      },
      {
        startSecond: 540,
        endSecond: 550,
        text: {
          CR: "Dovan la brodri, Anéil i roviv tout son zénetan. Son kèr, in gine linstan, lé moin vid Son lame pli zan pé",
          FR: "Devant la broderie Anéil revivait toute sa jeunesse. Son cœur, juste un instant, était moins vide Son âme presque en paix",
          EN: "",
        },
      },
      {
        startSecond: 550,
        endSecond: 556,
        text: {
          CR: "Andiré prés Marius la pa loin sort dann la brodri po anbrat a él.",
          FR: "On aurait dit que Marius allait quitter la broderie pour la serrer dans ses bras.",
          EN: "",
        },
      },
      {
        startSecond: 556,
        endSecond: 559,
        text: {
          CR: "« Mi yinm a toi »",
          FR: "« Mi yinm a toi »",
          EN: "",
        },
      },
      {
        startSecond: 559,
        endSecond: 561,
        text: {
          CR: "« Moin si »",
          FR: "« Moin si »",
          EN: "",
        },
      },
      {
        startSecond: 561,
        endSecond: 565,
        text: {
          CR: "Mé a la pa, in koudvan-malèr i ral lo brodri sanm lu",
          FR: "Mais voilà qu’un coup de vent malheureux emporta la broderie",
          EN: "",
        },
      },
      {
        startSecond: 565,
        endSecond: 568,
        text: {
          CR: "Anéil i lév lo bra mé lé tro ô",
          FR: "Anéil leva les bras mais c’était bien trop haut",
          EN: "",
        },
      },
      {
        startSecond: 568,
        endSecond: 579,
        text: {
          CR: "Anéil i mét a kourir, kour dérièr lo van, dann santié, dann rosh, dann ravine. Él la kour dé zour antié ziska ariv terla",
          FR: "Anéil s’était mis à courir courir, derrière le vent, dans les sentiers, dans les rochers, dans les ravines. Elle couru deux jours entier jusqu’à arriver ici",
          EN: "",
        },
      },
      {
        startSecond: 579,
        endSecond: 582,
        text: {
          CR: "Ousa ké zot i akout mon zistoir.",
          FR: "Où je vous raconte mon histoire.",
          EN: "",
        },
      },
      {
        startSecond: 582,
        endSecond: 588,
        text: {
          CR: "Terla ou si zot i rogard byin, zot i pé oir Cilaos kom Anéil la vu",
          FR: "Ici où si vous regardez bien, vous pouvez apercevoir Cilaos comme Anéil l’a vu",
          EN: "",
        },
      },
      {
        startSecond: 588,
        endSecond: 594,
        text: {
          CR: "Sous sé zié karkiyé son broderi ralé par lo van la tonm si l’vilaz",
          FR: "Sous ses yeux étonnés la broderie emportée par le vent était tombée sur le village",
          EN: "",
        },
      },
      {
        startSecond: 594,
        endSecond: 602,
        text: {
          CR: "Pli présiséman si in vié zakaranda sék ké an in linstan la mét a roflorir.",
          FR: "Plus précisément sur un vieux zakaranda sec qui s’était mis à refleurir à l’instant.",
          EN: "",
        },
      },
      {
        startSecond: 602,
        endSecond: 605,
        text: {
          CR: "Oui mésié médame la sosiété",
          FR: "Oui Messieurs et Mesdames la société",
          EN: "",
        },
      },
      {
        startSecond: 605,
        endSecond: 615,
        text: {
          CR: "Domin kan zot va ardsann dann lé ba, zot i poura rakonté ké si-an-ô Cilaos, navé in zakaranda ké la viv la résureksion",
          FR: "Demain quand vous redescendrez vous pouvez raconter qu’ici à Cilaos, il y a un zakaranda qui a vécu la résurrection",
          EN: "",
        },
      },
      {
        startSecond: 615,
        endSecond: 620,
        text: {
          CR: "É ké pandan lontan, été kom livèr, li té an flèr tout sézon",
          FR: "Qu’il fleurissait depuis en toutes saisons",
          EN: "",
        },
      },
      {
        startSecond: 620,
        endSecond: 628,
        text: {
          CR: "Zot i poura osi razouté ké lo lo zour Anéil la mont o siél, li la anfléri dé koulèr",
          FR: "Vous pourrez aussi raconter que le jour où Anéil est partie à son tour, il a fleuri en deux couleurs",
          EN: "",
        },
      },
      {
        startSecond: 628,
        endSecond: 633,
        text: {
          CR: "E ké dosou navé in zorkri ké té i plèr tout larm son kor.",
          FR: "Et que dessous il y avait un petit garçon qui pleurait toutes les larmes de son corps.",
          EN: "",
        },
      },
      {
        startSecond: 633,
        endSecond: 647,
        text: {
          CR: "Amoin minm, Anéil té mon mémé. In fanm si-an-o, in fanm ryin, inn brodéz anparmi mé ké la tir dann mon vi in gran fil-lamour la poézri.",
          FR: "C’était moi, Anéil c’était ma grand-mère. Une femme d’ici, une femme de rien, une brodeuse parmi les autres mais qui m’a appris à tisser le fil de la poésie.",
          EN: "",
        },
      },
      {
        startSecond: 647,
        endSecond: 649,
        text: {
          CR: "É zis po finir",
          FR: "Et pour finir",
          EN: "",
        },
      },
      {
        startSecond: 649,
        endSecond: 655,
        text: {
          CR: "An plis ét poézeur é rakontèr, moin lé osi in pa mové santèr",
          FR: "En plus d’être poète raconteur, je suis aussi pas mauvais chanteur",
          EN: "",
        },
      },
      {
        startSecond: 655,
        endSecond: 663,
        text: {
          CR: "Alors an méoir Anéil É tou sinleman po lo plézir de l’instant présent. Ma kado azot la parol in zarboutan.",
          FR: "Alors à la mémoire de ma mémé et tout simplement pour le plaisir de l’instant présent. Je vais vous offrir la parole d’un grand ancêtre.",
          EN: "",
        },
      },
      {
        startSecond: 663,
        endSecond: 668,
        text: {
          CR: "Misié Jean-Paul Cadet",
          FR: "Monsieur Jean-Paul Cadet",
          EN: "",
        },
      },
      {
        startSecond: 668,
        endSecond: 677,
        text: {
          CR: "Zakaranda an flèr li balans blans dan le van, dodan son balansman li vyin kost koté flanboyan",
          FR: "Zakaranda an flèr li balans blans dan le van, dodan son balansman li vyin kost koté flanboyan",
          EN: "",
        },
      },
      {
        startSecond: 677,
        endSecond: 686,
        text: {
          CR: "Flanboyan an karnèr la fé an giz linvitasion, po mélanz ton koulèr vyin trap lo rouz dodan mon san",
          FR: "Flanboyan an karnèr la fé an giz linvitasion, po mélanz ton koulèr vyin trap lo rouz dodan mon san",
          EN: "",
        },
      },
      {
        startSecond: 686,
        endSecond: 711,
        text: {
          CR: "A nou valé nou valé nou valé, ah nou valé nalé boir la sours maloya",
          FR: "A nou valé nou valé nou valé, ah nou valé nalé boir la sours maloya",
          EN: "",
        },
      },
    ],
  },
  2: {
    sentences: [
      {
        startSecond: 0,
        endSecond: 54,
        text: {
          CR: "♫ ♫ ♫",
          FR: "♫ ♫ ♫",
          EN: "",
        },
      },
      {
        startSecond: 54,
        endSecond: 59,
        text: {
          CR: "Tan-salé. Terla nou lé tan-salé.",
          FR: "L'étang-salé. Ici nous sommes à l'Étang-Salé.",
          EN: "",
        },
      },
      {
        startSecond: 59,
        endSecond: 65,
        text: {
          CR: "Aou ki akout amoin, daborinn, mi pass aou konpliman pars la polités avan tou.",
          FR: "Toi qui m'écoute, en premier, prends mon bonjour. Parce qu'être poli c'est important.",
          EN: "",
        },
      },
      {
        startSecond: 65,
        endSecond: 72,
        text: {
          CR: "É kom di mon vié papa : « Minm si out figir lé plus krazé k'in kap bordmèr ou pé kaminm ét byien élvé » ...",
          FR: "Et comme disait mon vieux père : « Même si tu as la figure comme un cap de bord de mer, tu peux quand même être bien élevé » ...",
          EN: "",
        },
      },
      {
        startSecond: 72,
        endSecond: 74,
        text: {
          CR: "Li dizé pa sa po moin hin.",
          FR: "Il ne disait pas ça pour moi hein.",
          EN: "",
        },
      },
      {
        startSecond: 74,
        endSecond: 80,
        text: {
          CR: "A non, Tonin, mon défin papa pézasonname, noré zamé di sa po son garson",
          FR: "Ah non, Tonin, mon défunt père, paix à son âme, n'aurait jamais dit ça de son garçon",
          EN: "",
        },
      },
      {
        startSecond: 80,
        endSecond: 83,
        text: {
          CR: "Fo dir osi li noré pa pu.",
          FR: "Il faut dire aussi, qu'il n'aurait pas pu.",
          EN: "",
        },
      },
      {
        startSecond: 83,
        endSecond: 89,
        text: {
          CR: "Akozkésa ? Bé, pars kan bondié la donn la boté moin la gainye byin plis la moitié.",
          FR: "Pourquoi ? Ben, parce que quand le Bondieu a donné toute la beauté du monde, j'en ai eu plus de la moitié.",
          EN: "",
        },
      },
      {
        startSecond: 89,
        endSecond: 95,
        text: {
          CR: "Lé pa po fé lo vantar, moin lé ziska bo. Zot minm zot i pé oir.",
          FR: "Ce n'est pas pour me vanter, mais je suis plus que beau. Comme vous pouvez le voir.",
          EN: "",
        },
      },
      {
        startSecond: 95,
        endSecond: 103,
        text: {
          CR: "Anfin non zot i pé pa... Mé zot i pé maziné ! Tousala po dir azot kosa déza ?",
          FR: "Enfin non, vous ne pouvez pas... Mais vous pouvez imaginer ! Tout ça pour vous dire quoi déjà ?",
          EN: "",
        },
      },
      {
        startSecond: 103,
        endSecond: 115,
        text: {
          CR: "Wi Papa ék moin nou té kom dé frèr, kinm pourtan li té mon gramoun. Wi, Tonin, mon gramoun, ké lété mon papa, té kom mon frèr...",
          FR: "Oui, papa et moi on était comme deux frères, malgré le fait que c'était mon Gramoun. Oui Tonin, mon Gramoun, qui était mon père, était comme mon frère...",
          EN: "",
        },
      },
      {
        startSecond: 115,
        endSecond: 122,
        text: {
          CR: "Nou navé d'lamour an poundiak si wi vé byin. Fo dir osi, moin té son sél garson.",
          FR: "On avait de l'amour à revendre si tu veux bien. Il faut dire aussi, que j'étais son seul garçon.",
          EN: "",
        },
      },
      {
        startSecond: 122,
        endSecond: 128,
        text: {
          CR: "Té i tonm byin, li té mon sél papa. Ou rir ou mé la pa vidan soman.",
          FR: "Ça tombait bien, il était mon seul père. Tu ris mais ce n'est pas évident.",
          EN: "",
        },
      },
      {
        startSecond: 128,
        endSecond: 138,
        text: {
          CR: "Moin na d'kamarad, moman zot na inn sél, mé papa zot na détroi. Mé pa moin. Amoin mon papa sé Tonin.",
          FR: "J'ai des copains, ils n'ont qu'une seule mère, mais plusieurs père. Mais pas moi. Moi mon père s'est Tonin.",
          EN: "",
        },
      },
      {
        startSecond: 138,
        endSecond: 151,
        text: {
          CR: "Ti-Tonin kom son bann kamarad té ki kri a lu. Amoin mi profèr Tonin, tout kourt. Tout kourt sé pa l'bon mo. Dobout an soulié li té mesur 1,23m.",
          FR: "Ti-Tonin comme ses amis l'appelaient. Mais moi je préfère Tonin, tout court. Tout court, ce n'est pas le bon mot. Debout dans ses souliers, il mesurait 1,23m.",
          EN: "",
        },
      },
      {
        startSecond: 151,
        endSecond: 157,
        text: {
          CR: "É asiz dann son kanot, bin lé sinp, té oi pa lu. Mé li té grankaminm.",
          FR: "Et assis dans sa barque, bah c'est simple, on ne le voyait pas. Mais c'était quand même un grand homme.",
          EN: "",
        },
      },
      {
        startSecond: 157,
        endSecond: 164,
        text: {
          CR: "Gran ék la jantiyès, lamour, lo soin li té donn Grand kom, kom",
          FR: "Grand avec la gentillesse, l'amour, le soin qu'il donnait aux gens. Grand comme, comme",
          EN: "",
        },
      },
      {
        startSecond: 164,
        endSecond: 173,
        text: {
          CR: "Wi oi lao la ? Piton la kroi ? Bé sa, sé la bonn oteur. É pi sé lao ké...",
          FR: "Tu vois là-haut ? Piton La Croix ? Bah ça, c'est la bonne hauteur. Et puis c'est là-haut que...",
          EN: "",
        },
      },
      {
        startSecond: 173,
        endSecond: 179,
        text: {
          CR: "Non, mi sa tro vit. Mi ris pérd azot. Sé sa mon défo.",
          FR: "Non, je vais trop vite. Je risque de vous perdre. C'est ça min défaut.",
          EN: "",
        },
      },
      {
        startSecond: 179,
        endSecond: 187,
        text: {
          CR: "Défoi, mi kroi, tout domoun lé osi intélizan k'moin. Midi pa toi la déziluzion. Mé rovnon zan a no rouzon.",
          FR: "Parfois, je crois que tout le monde est aussi intelligent que moi. Je ne te dis pas la désillusion. Mais revenons à nos rouzons",
          EN: "",
        },
      },
      {
        startSecond: 187,
        endSecond: 195,
        text: {
          CR: "No rouzon, no péroké, no karang, no poison kaf. Tonin, mon papa lété péshèr d'kanot.",
          FR: "Nos rouzons, nos péroké, nos karang, nos poison kaf. Tonin, mon papa était pêcheur de bord de mer.",
          EN: "",
        },
      },
      {
        startSecond: 195,
        endSecond: 200,
        text: {
          CR: "Toultan an mèr. A sa li té yinm sa, la mèr.",
          FR: "Toujours sur l'océan. C'est fou comment il aimait ça, la mer.",
          EN: "",
        },
      },
      {
        startSecond: 200,
        endSecond: 207,
        text: {
          CR: "Mi kroi pétét sé lo sél zafèr ké li té yinm prés plus ké son garson Philibert. Philibert, sé moin.",
          FR: "Je crois peut-être que c'est la seule chose qu'il aimait plus que son garçon Philibert. Philibert, c'est moi.",
          EN: "",
        },
      },
      {
        startSecond: 207,
        endSecond: 220,
        text: {
          CR: "Garson de Ti-Tonin, ké li té profèr apél gran, ké lété son gramoun, pourtant son papa é son frèr an minm tan... Bref boitbéf.",
          FR: "Garçon de Ti-Tonin, qui préférait qu'on l'appelle grand, qui était son Gramoun, pourtant son père et son frère en même temps... Bref boitbéf.",
          EN: "",
        },
      },
      {
        startSecond: 220,
        endSecond: 232,
        text: {
          CR: "Zour kom nwit li té vavang dann roulo lo salé po gainye son bousé manzé, certes Huberte mé osi pars o larz li té rotrouve la pé. Li dizé amoin toultan :",
          FR: "De jour comme de nuit, il était toujours dans les rouleaux d’eau salée Pour pêcher de quoi manger, certes Huberte. Mais aussi parce qu’au large il retrouvait la paix. Il me disait toujours :",
          EN: "",
        },
      },
      {
        startSecond: 232,
        endSecond: 242,
        text: {
          CR: "« Tibér, té komkésa ké li té kri amoin. Isi na poin de ti, na poin d'gran »",
          FR: "« Thibert, c'est comme ça qu'il m'appelait. Ici il n'y a pas de petits, pas de grands »",
          EN: "",
        },
      },
      {
        startSecond: 242,
        endSecond: 251,
        text: {
          CR: "É li razouté touzour zis dérièr an rogardon amoin : « Poin non plu de plus kouyon, poin de plus lintélizan »",
          FR: "Et il rajoutait toujours en me regardant : « Il n'y a pas non plus de plus bête, ou de plus intelligent »",
          EN: "",
        },
      },
      {
        startSecond: 251,
        endSecond: 256,
        text: {
          CR: "Ma zamé trop konpri pokwé ké li razouté sa mé sof.",
          FR: "Je n’ai jamais trop compris pourquoi il rajoutait ça mais bref",
          EN: "",
        },
      },
      {
        startSecond: 256,
        endSecond: 264,
        text: {
          CR: "Na in nafèr mi koné, sé ké li lavé bonpé soufèr son tininnité.",
          FR: "Il y a quelque chose que je sais, c'est qu'il avait beaucoup souffert de sa petitesse.",
          EN: "",
        },
      },
      {
        startSecond: 264,
        endSecond: 270,
        text: {
          CR: "Dopi ti-lékol ziska son trantsinkan bana té ki moukat a li toultan.",
          FR: "Depuis l’école primaire jusqu’à ses 35 ans, tout le monde se moquait de lui tout le temps.",
          EN: "",
        },
      },
      {
        startSecond: 270,
        endSecond: 279,
        text: {
          CR: "« Fé tansion, zérb lé ô, va shatouy sou out bra ». « Akoz ta ashét in kanot, in boit sardine noré té sifizan »",
          FR: "« Fais attention, les herbes sont hautes, ça va te chatouiller sous les bras ». « Pourquoi tu as acheté une barque, une boîte de sardines aurait été bien suffisante. »",
          EN: "",
        },
      },
      {
        startSecond: 279,
        endSecond: 288,
        text: {
          CR: "« Té Tonin, kosa ou la péshé zordi, inn domi-liv ti lapia ? » Vréman domoun té pa zantil.",
          FR: "« Hé Tonin, qu’est-ce que tu as pêché aujourd’hui, une demi livre de tilapia ? » Vraiment les gens n'était pas gentils.",
          EN: "",
        },
      },
      {
        startSecond: 288,
        endSecond: 296,
        text: {
          CR: "Ziska son trantsinkan. Akoz trantsink ? Atan je tan ékseplik.",
          FR: "Jusqu'à ses 35 ans. Pourquoi 35 ? Attends je t'explique.",
          EN: "",
        },
      },
      {
        startSecond: 296,
        endSecond: 304,
        text: {
          CR: "Pars dann lo malèr dé son titrépé de grandèr, in zour Tonin la gainye in kado la vi. In gorson.",
          FR: "Un jour Tonin a eu un cadeau de la vie, un garçon.",
          EN: "",
        },
      },
      {
        startSecond: 304,
        endSecond: 318,
        text: {
          CR: "Mé pa ninport akél, in golgot. Ké pinn né té dépass déza son papa. É ki a kinzan té in kolos 1,90m po 90 kg.",
          FR: "Mais pas n'import lequel, un géant. Qui à peine né dépassait déjà son père. Et qui à 15 ans était un colosse, 1,90 m pour 90 kg.",
          EN: "",
        },
      },
      {
        startSecond: 318,
        endSecond: 330,
        text: {
          CR: "90 kg ké la fout a tonbé dann le dantié de ninportkisa té ki oz moukat la pétités son papa. In zoli é gran é for gorson.",
          FR: "90 kg qui tombait dans la face de celui qui osait se moquer de la petitesse de son père. Un beau et grand et fort garçon.",
          EN: "",
        },
      },
      {
        startSecond: 330,
        endSecond: 341,
        text: {
          CR: "Ék in non ki soné plis dou ké dézord la vag bordmèr. Philibert, gorson d'son papa.",
          FR: "Avec un nom qui sonnait plus doux encore que le bruit des vagues du bord de mer. Philibert, garçon de son papa.",
          EN: "",
        },
      },
      {
        startSecond: 341,
        endSecond: 348,
        text: {
          CR: "Atann kosa moin té trin dir azot déza ?",
          FR: "Attends qu'est-ce que j'étais en train de vous raconter déjà ?",
          EN: "",
        },
      },
      {
        startSecond: 348,
        endSecond: 361,
        text: {
          CR: "A wi piton la kroi. Sété sa la zis grandèr Ti-Tonin. Kan li té fatigé soley bordmèr, li té mont lao rod lombraz son kamarad. In gran pié d'tamarin",
          FR: "Ah oui, piton la Croix. C'était ça la juste grandeur de Ti-Tonin. Quand il était fatigué du soleil du bord de mer, il montait chercher de l'ombre auprès de son vieil ami. Un grand pied de tamarin.",
          EN: "",
        },
      },
      {
        startSecond: 361,
        endSecond: 369,
        text: {
          CR: "Plis o k'lo siél, plis larz la montanye. Tamarin la, té ékzaktoman ou koméla néna la kroi.",
          FR: "Plus haut que le ciel, plus large que la montagne. Ce pied de tamarin était exactement où est aujourd'hui la Croix.",
          EN: "",
        },
      },
      {
        startSecond: 369,
        endSecond: 378,
        text: {
          CR: "É in zour li la vi débarké in zéne péshèr bordmèr, in mizing ti-gine domoun.",
          FR: "Et un jour, il a vu débarquer un jeune pêcheur du bord de mer, un tout petit homme.",
          EN: "",
        },
      },
      {
        startSecond: 378,
        endSecond: 393,
        text: {
          CR: "An gro pouléré, lo kèr saviré pars domoun té san arrét oa d'moukat son tro-ti. Piédbwa la di alu komsa, « Plèr pa garson, na poin pli o ké moin dann péyi-la, grinp vitman »",
          FR: "Qui pleurait, le cœur chaviré, parce que les gens n’arrêtait pas de se moquer de sa petitesse. L'arbre lui dit comme ça, « Ne pleure pas mon garçon, il n’y a pas plus haut que moi dans tout le pays, monte »",
          EN: "",
        },
      },
      {
        startSecond: 393,
        endSecond: 406,
        text: {
          CR: "Po promié kou d'sa vi, Tonin la santi a li gran, pli gran ké tout piton otour, pli grand ké tout lo bann moukataz. É kan li la rodsann, son kér té ki débord la grandèr.",
          FR: "Pour la première fois de sa vie, Ti-Tonin s'est senti grand, plus grand, que tous les pitons autour, plus grand, que toutes les moqueries. Et quand il est redescendu de l’arbre, son cœur débordait de grandeur.",
          EN: "",
        },
      },
      {
        startSecond: 406,
        endSecond: 418,
        text: {
          CR: "So zour-la in gran lamitié lavé énét parkoté piton la kroi. É tout la vi Tonin i alé ét ritmé par zot bann rankont",
          FR: "Ce jour-là, une grande amitié était née sur le piton là-bas en haut. Et toute la vie de Ti-Tonin allait être rythmée par leur rencontre",
          EN: "",
        },
      },
      {
        startSecond: 418,
        endSecond: 436,
        text: {
          CR: "In zour li la débark an kouran, « Dalon, ma gainye in baba, vi kroira pa, mé pinn né li lé déza pli gran k’moi ». Alorse, la di piédbwa, i fo ou fé in gran kaz po lu, pran mé bransh.",
          FR: "Un jour il débarque au courant, « mon ami, j’ai eu un garçon, tu ne me croiras pas, mais à peine né il est déjà plus grand que moi ». Alors, a dit l’arbre, il te faut un grand berceau, prends mes branches",
          EN: "",
        },
      },
      {
        startSecond: 436,
        endSecond: 442,
        text: {
          CR: "É so zour-la, Tonin la ardsan ék in ni lamour dann kèr",
          FR: "Ce jour là, Ti-Tonin est descendu avec un nid d’amour dans le cœur",
          EN: "",
        },
      },
      {
        startSecond: 442,
        endSecond: 452,
        text: {
          CR: "In ot zour sat la ral a li an lèr été lo traka. Kosa lariv a toi, la fé piédbwa ?",
          FR: "Un autre jour, ce qu’il a amené en haut du piton, c’était les soucis. Qu’est-ce qui t’arrive ? avait dit l’arbre",
          EN: "",
        },
      },
      {
        startSecond: 452,
        endSecond: 463,
        text: {
          CR: "« Lo poison mi tir bord-mèr lé loin d’ét sifizan, fo mi mont o larz » la di Tonin. Alorse, i fo ou na in bon kanot, pran mon tron.",
          FR: "« Les poissons que je pêche en bord de la falaise sont loin d’être suffisants, il faut que j’aille en mer » dit Tonin. Alors, il te faut une bonne barque, prends mon tronc.",
          EN: "",
        },
      },
      {
        startSecond: 463,
        endSecond: 477,
        text: {
          CR: "Sé lot zour-la, Tonin la rodsann lo kèr batan an gran dézord la mèr. Li la batiz son kanot Marise, lo non son madame.",
          FR: "Cet autre jour, Tonin est redescendu, le cœur battant, comme le bruit des vagues du bord de mer. Il baptisa sa barque Marise, le nom de ma mère.",
          EN: "",
        },
      },
      {
        startSecond: 477,
        endSecond: 486,
        text: {
          CR: "É sé komsa ké la vi la mét a palanké dann bordmèr tansalé. Rant son baba, son madame, son kanot.",
          FR: "Et c'est comme ça que sa vie s'est mie à balancer dans le bord de mer d'étang-salé. Entre son fils, sa femme, sa barque.",
          EN: "",
        },
      },
      {
        startSecond: 486,
        endSecond: 493,
        text: {
          CR: "In vi an luil dann lonbraz son dalon piédbwa.",
          FR: "La vie douce dans l'ombre de son ami l'arbre.",
          EN: "",
        },
      },
      {
        startSecond: 493,
        endSecond: 505,
        text: {
          CR: "É pi lariv dann son vié tan. In zour ou ké li lariv san kozé, li la zis alonz rant dé rasine.",
          FR: "Et puis est arrivé l’heure de la vieillesse. Un jour il est arrivé à côté de son ami sans parler et s’est juste allongé entre deux racines",
          EN: "",
        },
      },
      {
        startSecond: 505,
        endSecond: 515,
        text: {
          CR: "É li la gainye somey la minm. É piédbwa la donn li son dernié kado : le repo.",
          FR: "Et s’est endormi. Et l’arbre lui a donné son dernier cadeau : le repos.",
          EN: "",
        },
      },
      {
        startSecond: 515,
        endSecond: 530,
        text: {
          CR: "Sé la-o, ousa ké koméla néna la kroi, ké moin la rotrouv papa. Ék moman nou navé labitid oir li rant tar. Mé so kou si, son kanot té pozé bormdèr.",
          FR: "C’est là-haut, où maintenant il y a une croix, que j’ai retrouvé mon papa. Avec ma mère on avait l’habitude de voir de le voir rentrer tard. Mais cette fois, sa barque était posée dans le port.",
          EN: "",
        },
      },
      {
        startSecond: 530,
        endSecond: 545,
        text: {
          CR: "Toudsuit ma pran somin la foré, mavé pa tronpé. Sous la rasine té ki dor Ti-Tonin. Tonin, mon gramoun, mon papa, mon frèr.",
          FR: "Tout de suite je suis monté vers la forêt, je ne m'étais pas trompé. Dans l'ombre dormait Ti-Tonin. Tonin, mon Gramoun, mon papa, mon frère.",
          EN: "",
        },
      },
      {
        startSecond: 545,
        endSecond: 552,
        text: {
          CR: "Mésié médame la sosiété, non mi plér pa, sé l'sél bordmèr.",
          FR: "Messieurs Mesdames la société, non je ne pleure pas, c’est le sel du bord de mer",
          EN: "",
        },
      },
      {
        startSecond: 552,
        endSecond: 564,
        text: {
          CR: "Dopli terla i fo vin minute po ariv la kroi. Zot i mazine pa koman lé zoli lao. Tout lé telman gran. Pran kouraz.",
          FR: "Depuis là où vous êtes il faut vingt minutes pour arriver à la Croix. Vous ne vous imaginez pas comment c’est beau. Tout est tellement grand. Soyez courageux.",
          EN: "",
        },
      },
      {
        startSecond: 564,
        endSecond: 573,
        text: {
          CR: "Mé sirtou kan zot i ariv an lèr oubli pa anbras Tonin po moin. É si zot zié lankor bon, gét dann gran larz.",
          FR: "Mais surtout quand vous arrivez là-haut n’oubliez pas d’embrasser Tonin pour moi. Et si vos yeux sont encore bon, regardez vers le large",
          EN: "",
        },
      },
      {
        startSecond: 573,
        endSecond: 580,
        text: {
          CR: "Somanké zot va oir in kanot batizé Marise. Ék dodan in Golgot 120 kg.",
          FR: "Peut-être que vous y verrez une barque en bois de tamarin avec écrit dessus Marise. Et dedans un géant de 120 kg.",
          EN: "",
        },
      },
      {
        startSecond: 580,
        endSecond: 586,
        text: {
          CR: "Sa amoin sa, Philibert, gorson d'Tonin.",
          FR: "C'est moi, Philibert, garçon d'Tonin.",
          EN: "",
        },
      },
      {
        startSecond: 586,
        endSecond: 649,
        text: {
          CR: "♫ ♫ ♫",
          FR: "♫ ♫ ♫",
          EN: "",
        },
      },
    ],
  },
  6: {
    sentences: [
      {
        startSecond: 0,
        endSecond: 22,
        text: {
          CR: "♫ ♫ ♫",
          FR: "♫ ♫ ♫",
          EN: "",
        },
      },
      {
        startSecond: 22,
        endSecond: 24,
        text: {
          CR: "Mésié médame la sosiété",
          FR: "Messieurs Mesdames, la société",
          EN: "",
        },
      },
      {
        startSecond: 24,
        endSecond: 27,
        text: {
          CR: "Si-a,-o nou lé Tampon",
          FR: "Ici, nous sommes au Tampon",
          EN: "",
        },
      },
      {
        startSecond: 27,
        endSecond: 30,
        text: {
          CR: "Plis préziséman dann mon zistoir la Plinn dé kaf",
          FR: "Plus précisément dans mon histoire à la Plaine des cafres",
          EN: "",
        },
      },
      {
        startSecond: 30,
        endSecond: 34,
        text: {
          CR: "Plis présizéman dovan lantré in boit de nui",
          FR: "Plus précisément devant l’entrée d’une boîte de nuit",
          EN: "",
        },
      },
      {
        startSecond: 34,
        endSecond: 41,
        text: {
          CR: "Zot i koné isi-an-o po kal la fré qui s’avance nou la besoin sokouy lo kor an kandans",
          FR: "Comme vous le savez, ici quand le froid s’avance, on danse pour se réchauffer",
          EN: "",
        },
      },
      {
        startSecond: 41,
        endSecond: 47,
        text: {
          CR: "Vou qui makouté, tiré votre bonnet po mié Zantand ankor pars asoir…",
          FR: "Et vous qui m’écoutez, enlevez votre bonnet pour mieux m’entendre encore parce que ce soir…",
          EN: "",
        },
      },
      {
        startSecond: 47,
        endSecond: 49,
        text: {
          CR: "lé vintèr d'soir",
          FR: "Il est 20h",
          EN: "",
        },
      },
      {
        startSecond: 49,
        endSecond: 53,
        text: {
          CR: "la nuit lé an luil, san dézord, ou prés",
          FR: "la nuit est belle, sans un bruit, ou presque",
          EN: "",
        },
      },
      {
        startSecond: 53,
        endSecond: 56,
        text: {
          CR: "dann bor ranpar wi pé antan marsé",
          FR: "dans le bord du rempart, on peut entendre marcher",
          EN: "",
        },
      },
      {
        startSecond: 56,
        endSecond: 60,
        text: {
          CR: "Michel i bat talon dann la pousiér, li lé paré, ou prés",
          FR: "Michel tape des talons dans la poussière, il est prêt, presque",
          EN: "",
        },
      },
      {
        startSecond: 60,
        endSecond: 64,
        text: {
          CR: "lo tan ankor pass in dernié kou d’verni si son soulié",
          FR: "le temps encore d’un dernier coup de cirage sur ses chaussures",
          EN: "",
        },
      },
      {
        startSecond: 64,
        endSecond: 68,
        text: {
          CR: "lo tan ankor pass in dernié kou d'listraz si son karos",
          FR: "le temps encore d’un dernier coup de lustrage sur son carrosse",
          EN: "",
        },
      },
      {
        startSecond: 68,
        endSecond: 71,
        text: {
          CR: "Dann bor lakour in 404 lé garé",
          FR: "Dans le bord de la cour, une 404 est garée",
          EN: "",
        },
      },
      {
        startSecond: 71,
        endSecond: 78,
        text: {
          CR: "pa in mérsédés mé prés, pa név mé prés, pokor péyé mé prés",
          FR: "pas une Mercedes mais presque, pas neuve mais presque, pas encore payée mais presque",
          EN: "",
        },
      },
      {
        startSecond: 78,
        endSecond: 82,
        text: {
          CR: "la zant i bri sitantelman k’anndan i gainye tir portré",
          FR: "la jante brille tellement qu’on peut y voir",
          EN: "",
        },
      },
      {
        startSecond: 82,
        endSecond: 85,
        text: {
          CR: "é portré d'zoli zinnzan",
          FR: "le reflet d’un beau jeune homme",
          EN: "",
        },
      },
      {
        startSecond: 85,
        endSecond: 91,
        text: {
          CR: "Michel sé t'in zinnzan karnèr, touzour byin mi, in galan ké na prés vintan",
          FR: "Michel est un jeune séducteur, toujours bien habillé, un galant de presque vingt ans",
          EN: "",
        },
      },
      {
        startSecond: 91,
        endSecond: 101,
        text: {
          CR: "In vintan d'koupér d’kann, in vintan d’kouraz, ki souplinn pa si bitation, sou gropouaké d'soley kan i fo rant dann karo la pay",
          FR: "Un vingt ans de coupeur de canne à sucre, un vingt ans de courage, qui ne se plaint jamais, que ce soit sous les coups du soleil ou sous les morsures de la paille de canne",
          EN: "",
        },
      },
      {
        startSecond: 101,
        endSecond: 105,
        text: {
          CR: "Asoir Michel la kas larmoir",
          FR: "Ce soir, Michel a brisé l’armoire",
          EN: "",
        },
      },
      {
        startSecond: 105,
        endSecond: 114,
        text: {
          CR: "li la mét son tergal posaki, son zilé-a-pins lamidon, son veston troikar, é-pi-sa in ti-gine lésans-ponpéya",
          FR: "il a mis son plus beau pantalon, son gilet à pinces, son veston troikar et puis un tout petit peu d’essence de Pompeya",
          EN: "",
        },
      },
      {
        startSecond: 114,
        endSecond: 118,
        text: {
          CR: "Michel sé t'in zinnzan karnèr, touzour byin mi",
          FR: "Michel c’est un jeune plein d’élégance, toujours bien mis",
          EN: "",
        },
      },
      {
        startSecond: 118,
        endSecond: 120,
        text: {
          CR: "in galan ké na prés vintan",
          FR: "un galant qui a presque 20 ans",
          EN: "",
        },
      },
      {
        startSecond: 120,
        endSecond: 124,
        text: {
          CR: "Fyièr kom Artaban",
          FR: "fier comme Artaban",
          EN: "",
        },
      },
      {
        startSecond: 124,
        endSecond: 129,
        text: {
          CR: "Soman sominn-si lété mouék, sharjman d'kann lété még, la monaye lété féb",
          FR: "Mais cette semaine a été difficile, le rendement des canne était maigre, l’argent difficile à faire",
          EN: "",
        },
      },
      {
        startSecond: 129,
        endSecond: 137,
        text: {
          CR: "mé asoir sé samdi soir, asoir Michel i sann dann lé ba po prann in sanzmandlèr",
          FR: "mais ce soir c’est samedi soir, ce soir Michel descend dans les bas pour prendre un changement d’air",
          EN: "",
        },
      },
      {
        startSecond: 137,
        endSecond: 142,
        text: {
          CR: "Si li la tir son trant-é-in, sé k'asoir la shas lé rouvér",
          FR: "S’il s’est mis sur son 31, c’est que ce soir la chasse est ouverte",
          EN: "",
        },
      },
      {
        startSecond: 142,
        endSecond: 145,
        text: {
          CR: "madame Mardaye vey byin vout volaye",
          FR: "Madame surveillez bien votre volaille",
          EN: "",
        },
      },
      {
        startSecond: 145,
        endSecond: 147,
        text: {
          CR: "Misié Morel rant tout vout tourtrél",
          FR: "monsieur, rentrez votre tourterelles",
          EN: "",
        },
      },
      {
        startSecond: 147,
        endSecond: 151,
        text: {
          CR: "asoir lo kok lé an parad",
          FR: "ce soir le coq est en parade",
          EN: "",
        },
      },
      {
        startSecond: 151,
        endSecond: 156,
        text: {
          CR: "Fo di komkilé, tout fi terla i koné lodèr son plimaz",
          FR: "Il faut bien dire la vérité, toutes les filles ici connaissent l’odeur de son plumage",
          EN: "",
        },
      },
      {
        startSecond: 156,
        endSecond: 161,
        text: {
          CR: "é po dirvré na pwinn inn sèl si-an-o la pokor gainye son zérgo",
          FR: "et il n’est pas une seule femme du village qui n’ai vu sa crête de coq.",
          EN: "",
        },
      },
      {
        startSecond: 161,
        endSecond: 165,
        text: {
          CR: "Fanm, fanm, sa son sikdorz",
          FR: "Les femmes, les femmes, c’est sa friandise.",
          EN: "",
        },
      },
      {
        startSecond: 165,
        endSecond: 169,
        text: {
          CR: "son kann-bonbon, son bout-fondan",
          FR: "",
          EN: "",
        },
      },
      {
        startSecond: 169,
        endSecond: 176,
        text: {
          CR: "Sé k'in zinnzan dann la fors-dé-laz i doi pa dor tousél trosouvan i fo li tir-son-ras",
          FR: "C’est qu’un jeune homme dans la force de l’âge ne doit pas dormir tout seul trop souvent, il faut bien qu’il se reproduise",
          EN: "",
        },
      },
      {
        startSecond: 176,
        endSecond: 182,
        text: {
          CR: "Soman angant pa li lamour, zigid-lo-kèr, in santiman li donn pa lodèr",
          FR: "Mais ne lui parlez jamais d’amour, de sentiments, il ne veut même en sentir l’odeur",
          EN: "",
        },
      },
      {
        startSecond: 182,
        endSecond: 185,
        text: {
          CR: "Kinm nadkou, dovan domoun, li fé lanmerdé",
          FR: "Même si parfois devant les gens il fait celui que ça ennuie",
          EN: "",
        },
      },
      {
        startSecond: 185,
        endSecond: 190,
        text: {
          CR: "Michel lé fier son ti-nom gaté: Kabosabouk",
          FR: "Michel est particulièrement fier de son petit nom gâté: Kaboshabouk",
          EN: "",
        },
      },
      {
        startSecond: 190,
        endSecond: 194,
        text: {
          CR: "Li lé koma Michel: gran travayér, gran sédiktér",
          FR: "Il est comme ça, Michel, grand travailleur, grand séducteur",
          EN: "",
        },
      },
      {
        startSecond: 194,
        endSecond: 204,
        text: {
          CR: "E sar pa asoir ki sa tinn son répitation, asoir li sann la-vil, asoir Michel i sa va bal",
          FR: "Et ce ne sera pas ce soir que ça réputation va prendre l’eau, ce soir Michel va au bal.",
          EN: "",
        },
      },
      {
        startSecond: 204,
        endSecond: 261,
        text: {
          CR: "♫ ♫ ♫ ♫",
          FR: "♫ ♫ ♫ ♫",
          EN: "",
        },
      },
      {
        startSecond: 261,
        endSecond: 267,
        text: {
          CR: "In dernié kou pinye dann rétrovisér, in kou démarér, 10 kilomét dann karokann",
          FR: "Un dernier coup de peigne dans le rétroviseur, un coup de démarreur, dix kilomètres entre les cannes",
          EN: "",
        },
      },
      {
        startSecond: 267,
        endSecond: 272,
        text: {
          CR: "onzérmoinlkar, Michel i gar son 404",
          FR: "à 10h45 Michel, gare sa 404",
          EN: "",
        },
      },
      {
        startSecond: 272,
        endSecond: 278,
        text: {
          CR: "La line o plin i éklér in gran parking gravié, dé feytol rouyé",
          FR: "Dans le ciel la lune est pleine, elle éclaire un grand parking de gravier, deux feuilles de tôle rouillées",
          EN: "",
        },
      },
      {
        startSecond: 278,
        endSecond: 281,
        text: {
          CR: "in lékrito mal fagoté: Chez Maryse",
          FR: "un écriteau mal arrangé: Chez Maryse",
          EN: "",
        },
      },
      {
        startSecond: 281,
        endSecond: 285,
        text: {
          CR: '"Chez Maryse" lété ansiénman in salon de koifir',
          FR: '"Chez Maryse", c’était anciennement un salon de coiffure',
          EN: "",
        },
      },
      {
        startSecond: 285,
        endSecond: 289,
        text: {
          CR: "kom Maryze po sak sové koupé té i boir in koudsék la tourn an bivét",
          FR: "comme Maryse pour chaque cheveux coupé vidait un verre de rhum, on a construit une buvette",
          EN: "",
        },
      },
      {
        startSecond: 289,
        endSecond: 293,
        text: {
          CR: "kom Maryze la tonm an lamour d'in kokér-d’rak zot la marié",
          FR: "comme Maryse est tombée en amour d’un pilier de comptoir du coin, ils se sont mariés",
          EN: "",
        },
      },
      {
        startSecond: 293,
        endSecond: 295,
        text: {
          CR: "la fé in salvert, lanbosh in lorkés",
          FR: "on a construit une salle verte, embauché un orchestre",
          EN: "",
        },
      },
      {
        startSecond: 295,
        endSecond: 302,
        text: {
          CR: "kom Maryse ék son gaté la pi zamé désoulé, lorkés lma pi zamé arét zoué, la fé in diskoték",
          FR: "comme Maryse et son amoureux n’ont plus jamais dessaoulé, l’orchestre ne s’est jamais arrêté de jouer, on a construit une discothèque",
          EN: "",
        },
      },
      {
        startSecond: 302,
        endSecond: 309,
        text: {
          CR: "Chez Maryse lété pli souvan rampli d'kamayang ké d'miss goyavié mé sof kaminm",
          FR: "Chez Maryse on croisait plus de jeunes voyous que de Miss Goyavier mais quand même,",
          EN: "",
        },
      },
      {
        startSecond: 309,
        endSecond: 315,
        text: {
          CR: "Michel sé tin zoli garson é siplétadié sar pa asoir po li dor si kal",
          FR: "beau garçon comme il est, si Dieu veut, ce ne sera pas encore ce soir que Michel rentrera bredouille",
          EN: "",
        },
      },
      {
        startSecond: 315,
        endSecond: 321,
        text: {
          CR: "Soman si parking Michel i mazine ki so doi d'ét ankor in pé bonér pars déor na poin dot loto",
          FR: "Mais sur le parking Miche se dit qu’il doit être encore tôt, parce que là où d’habitude il y a beaucoup de voitures",
          EN: "",
        },
      },
      {
        startSecond: 321,
        endSecond: 327,
        text: {
          CR: "é dovan la port, la ou dabitid lé an lanfilad-la-mori, na poin pérsone",
          FR: "et une file d’attente interminable, il n’y a personne",
          EN: "",
        },
      },
      {
        startSecond: 327,
        endSecond: 332,
        text: {
          CR: "Li avans, anndan la misiz i donn paké, li antann kozé, li rant",
          FR: "Il avance, la musique arrive jusqu’à l’extérieur, il entend parler, il rentre",
          EN: "",
        },
      },
      {
        startSecond: 332,
        endSecond: 337,
        text: {
          CR: "Apinn la port pasé, Michel i anrovyin pa sé zié, na pi inn plas asiz",
          FR: "A peine la porte passée, Michel n’en crois pas ses yeux, il n’y a plus une seule place assise",
          EN: "",
        },
      },
      {
        startSecond: 337,
        endSecond: 343,
        text: {
          CR: "dizon dizon, lantré paradi, lo salon lé ranpli d'madanm",
          FR: "on dirait le paradis, la salle est pleine de femmes",
          EN: "",
        },
      },
      {
        startSecond: 343,
        endSecond: 351,
        text: {
          CR: "Sétaki zipon-ansoi, sétaki rob-a-volan, sétaki dékolté-plonzan",
          FR: "il y en a en jupon de soie, d’autres en robe à volant",
          EN: "",
        },
      },
      {
        startSecond: 351,
        endSecond: 356,
        text: {
          CR: "Michel i travérs la pist ziska la bivét: gorson in doubsék",
          FR: "Michel traverse la piste jusqu’à la buvette : Garçon, un double rhum",
          EN: "",
        },
      },
      {
        startSecond: 356,
        endSecond: 359,
        text: {
          CR: "Son tét i tourn forstan pi konét ousa poz lo zié",
          FR: "Sa tête tourne à force de ne plus savoir où poser les yeux",
          EN: "",
        },
      },
      {
        startSecond: 359,
        endSecond: 361,
        text: {
          CR: "zinnzan wi dans?",
          FR: "Jeune homme vous dansez ?",
          EN: "",
        },
      },
      {
        startSecond: 361,
        endSecond: 363,
        text: {
          CR: "é koman manzél",
          FR: "Et comment que je danse",
          EN: "",
        },
      },
      {
        startSecond: 363,
        endSecond: 370,
        text: {
          CR: "Séga i donn paké, lo rin i tourn, lo kor i kol, i tourn, i tourn, i tourn, tro vit, minm pa lo tan présanté",
          FR: "L’orchestre joue de toutes ses forces et tout tourne, tourne, tourne, tourne, bien trop vite pour avoir le temps de se présenter",
          EN: "",
        },
      },
      {
        startSecond: 370,
        endSecond: 373,
        text: {
          CR: "zinnzan wi dans?",
          FR: "Jeune homme vous dansez ?",
          EN: "",
        },
      },
      {
        startSecond: 373,
        endSecond: 381,
        text: {
          CR: "kadri i donn po alé, lo rin i tourn, lo kor i kol, i tourn, i tourn, i tourn, tro vit, minm pa lo tan larg in kozé",
          FR: "Le quadrille secoue la piste et tout tourne, tourne, tourne, sans même avoir le temps de se parler",
          EN: "",
        },
      },
      {
        startSecond: 381,
        endSecond: 384,
        text: {
          CR: "I tourn, i tourn.",
          FR: "Ça tourne, ça tourne",
          EN: "",
        },
      },
      {
        startSecond: 384,
        endSecond: 396,
        text: {
          CR: "Linvitation si linvitation, séga, kadri, valse, lorkés i grinn dofé, i dans, yin pa bout kozé, i koz, yin pa bout kosté, i dans, yin pa bout respiré",
          FR: "Invitation sur invitation, quadrille, valse, séga, l’orchestre ne s’arrête jamais, ça danse, tellement qu’on a pas le temps de se présenter, pas le temps de se parler, même pas le temps de respirer",
          EN: "",
        },
      },
      {
        startSecond: 396,
        endSecond: 399,
        text: {
          CR: "La bivét, in doubsék",
          FR: "La buvette, un double rhum",
          EN: "",
        },
      },
      {
        startSecond: 399,
        endSecond: 402,
        text: {
          CR: "Lo paradi sinonsa lanfér ?",
          FR: "Le paradis ou l’enfer",
          EN: "",
        },
      },
      {
        startSecond: 402,
        endSecond: 406,
        text: {
          CR: "Michel i san a li kom in kok léspés din park volay an batri",
          FR: "Michel se sent comme un coq de race dans un élevage de volaille en batterie",
          EN: "",
        },
      },
      {
        startSecond: 406,
        endSecond: 408,
        text: {
          CR: "Garson in doubsék",
          FR: "Garçon un double rhum",
          EN: "",
        },
      },
      {
        startSecond: 408,
        endSecond: 413,
        text: {
          CR: "Lorkés i ralanti lo témpo, in léslow Harry Payet",
          FR: "L’orchestre ralentit le tempo, un slow d’Harry Payet",
          EN: "",
        },
      },
      {
        startSecond: 413,
        endSecond: 418,
        text: {
          CR: "Dopi fénoir fondsal in manzél i avans",
          FR: "Depuis le fond de la salle, une jeune demoiselle s’avance",
          EN: "",
        },
      },
      {
        startSecond: 418,
        endSecond: 422,
        text: {
          CR: "mouramour i trap son min, mouramour i gant son rin",
          FR: "doucement, elle attrape sa main, doucement, elle lui prend les reins",
          EN: "",
        },
      },
      {
        startSecond: 422,
        endSecond: 428,
        text: {
          CR: "i poz la tét si son zépol, san ryin dmandé, san minm kozé",
          FR: "elle pose la tête sur son épaule, sans rien demander, sans même parler",
          EN: "",
        },
      },
      {
        startSecond: 428,
        endSecond: 431,
        text: {
          CR: "Michel lé an grinn-d'frison, san konprann akoz",
          FR: "Michel frissonne, sans comprendre ce qui lui arrive",
          EN: "",
        },
      },
      {
        startSecond: 431,
        endSecond: 437,
        text: {
          CR: "él i san bon, la po lé dou, lo kor lé fin",
          FR: "elle sent bon, sa peau est douce, son corps est fin",
          EN: "",
        },
      },
      {
        startSecond: 437,
        endSecond: 440,
        text: {
          CR: "Li tourn son tét po angard in pé an fas lo galante",
          FR: "Michel tourne la tête pour regarder un peu le visage de sa galante",
          EN: "",
        },
      },
      {
        startSecond: 440,
        endSecond: 449,
        text: {
          CR: "lo zié lé klér, lo né lé fin, zis, son lév-an-lèr, moitié rouvér, domi-andé",
          FR: "elle a les yeux clairs, le nez délicat, juste sa lèvre du haut, à moitié ouverte",
          EN: "",
        },
      },
      {
        startSecond: 449,
        endSecond: 451,
        text: {
          CR: "Dann salon bal na pi dézord",
          FR: "Dans le salon de bal, il n’y a plus aucun bruit",
          EN: "",
        },
      },
      {
        startSecond: 451,
        endSecond: 454,
        text: {
          CR: "lo dé tégor i dans pi, i flot",
          FR: "les deux amoureux ne dansent plus, il flottent",
          EN: "",
        },
      },
      {
        startSecond: 454,
        endSecond: 459,
        text: {
          CR: "la mizik i pé byin tourné, la pist i pé byin flanbé, i antann pi dézord",
          FR: "la musique peut bien ne jamais s’arrêter, la piste peut s’enflammer, ils n’entendent plus rien",
          EN: "",
        },
      },
      {
        startSecond: 459,
        endSecond: 463,
        text: {
          CR: "na aryink zot, ryink azot dé",
          FR: "il n’y a plus qu’eux deux, rien qu’eux deux",
          EN: "",
        },
      },
      {
        startSecond: 463,
        endSecond: 465,
        text: {
          CR: "Tousél",
          FR: "Seuls",
          EN: "",
        },
      },
      {
        startSecond: 465,
        endSecond: 466,
        text: {
          CR: "Tousél",
          FR: "Seuls",
          EN: "",
        },
      },
      {
        startSecond: 466,
        endSecond: 469,
        text: {
          CR: "Sétakroir Michel la larg son vayan",
          FR: "C’est à croire que Michel a perdu toute sa vaillance",
          EN: "",
        },
      },
      {
        startSecond: 469,
        endSecond: 470,
        text: {
          CR: "la perd son bonomité",
          FR: "tout son courage",
          EN: "",
        },
      },
      {
        startSecond: 470,
        endSecond: 472,
        text: {
          CR: "sétapinn si li gainye ankor kozé",
          FR: "c’est à peine s’il arrive encore à parler",
          EN: "",
        },
      },
      {
        startSecond: 472,
        endSecond: 479,
        text: {
          CR: "Skiz-mon-pardon manzél, moin sé Michel é vou parkoman vi apél?",
          FR: "Excusez-moi mademoiselle, moi c’est Michel et vous comment vous vous appelez ?",
          EN: "",
        },
      },
      {
        startSecond: 479,
        endSecond: 483,
        text: {
          CR: 'Amoin Michel bann si-an-o i kri amoin "la lièv"',
          FR: "Moi Michel les gens d’ici m’appellent la lièvre",
          EN: "",
        },
      },
      {
        startSecond: 483,
        endSecond: 488,
        text: {
          CR: "Kinm lo pli galan zinnzan i pé nadkou tonm an maladrés",
          FR: "Même le plus galant des jeunes gens peu de temps en temps être maladroit",
          EN: "",
        },
      },
      {
        startSecond: 488,
        endSecond: 493,
        text: {
          CR: 'Michel noré pi margonye inn ti konpliman, kinm in ti "lé zoli"',
          FR: "Michel aurait pu murmurer un compliment, même un tout petit « vous êtes belle »",
          EN: "",
        },
      },
      {
        startSecond: 493,
        endSecond: 495,
        text: {
          CR: "tout o-do-mié so kouyoneri",
          FR: "tout au lieu de ce qu’il a dit",
          EN: "",
        },
      },
      {
        startSecond: 495,
        endSecond: 500,
        text: {
          CR: "La liév? Po vréman? Mi yinm byin, moin la manz inn na poin lontan",
          FR: "La lièvre ? Vraiment ? J’aime beaucoup, j’en ai mangé, il n’y a pas longtemps",
          EN: "",
        },
      },
      {
        startSecond: 500,
        endSecond: 502,
        text: {
          CR: "Lo manzél la larg son rin",
          FR: "La demoiselle lui lâche les reins",
          EN: "",
        },
      },
      {
        startSecond: 502,
        endSecond: 504,
        text: {
          CR: "la zis di « oh Michel, sa lé pa jenti »",
          FR: "en disant juste « oh, Michel, ce n’est pas gentil »",
          EN: "",
        },
      },
      {
        startSecond: 504,
        endSecond: 507,
        text: {
          CR: "Lo tan po Mishel konpran son maldrés",
          FR: "Le temps pour Michel de comprendre sa maladresse",
          EN: "",
        },
      },
      {
        startSecond: 507,
        endSecond: 513,
        text: {
          CR: "in van fré la pas la port la pass dosi lorkés ké la fane kom in ta la pousièr",
          FR: "un grand vent froid passe la porte, emporte l’orchestre comme si c’était un tas de poussière",
          EN: "",
        },
      },
      {
        startSecond: 513,
        endSecond: 515,
        text: {
          CR: "dann son somiz",
          FR: "dans sa chemise",
          EN: "",
        },
      },
      {
        startSecond: 515,
        endSecond: 518,
        text: {
          CR: "la pran lo manzélé ké la mét a tourné dann salon",
          FR: "et prend la demoiselle qui se met à tourner sur la piste",
          EN: "",
        },
      },
      {
        startSecond: 518,
        endSecond: 524,
        text: {
          CR: "byinto son kor té ki dans an ron telman vit ké son pié té i tous pi atèr",
          FR: "bientôt son corps danse tellement vite que ses pieds ne touchent plus le sol",
          EN: "",
        },
      },
      {
        startSecond: 524,
        endSecond: 528,
        text: {
          CR: "an défilé tout lo bann galante la rant dann la ronde",
          FR: "et toutes les danseuses du bal entrent dans la ronde",
          EN: "",
        },
      },
      {
        startSecond: 528,
        endSecond: 535,
        text: {
          CR: "kom in gran riban blan anporté par lo van zot la pass la port ziska fénoir ou ké zot la disparét",
          FR: "comme un grand ruban blanc emporté par le vent elles rejoignent la nuit et disparaissent",
          EN: "",
        },
      },
      {
        startSecond: 535,
        endSecond: 540,
        text: {
          CR: "Michel an kapkap, san krié, san kozé la pran somin parking",
          FR: "Michel en tremblement, sans même crier, sans même parler, prend le chemin du parking",
          EN: "",
        },
      },
      {
        startSecond: 540,
        endSecond: 543,
        text: {
          CR: "In dernié koud’zié dann rétrovisér",
          FR: "Un dernier coup d’œil dans le rétroviseur",
          EN: "",
        },
      },
      {
        startSecond: 543,
        endSecond: 545,
        text: {
          CR: "in kou démarér",
          FR: "un coup de démarreur",
          EN: "",
        },
      },
      {
        startSecond: 545,
        endSecond: 548,
        text: {
          CR: "Michel i armont son kaz",
          FR: "Michel remonte le chemin cannier",
          EN: "",
        },
      },
      {
        startSecond: 548,
        endSecond: 552,
        text: {
          CR: "troi kilomét pli dvan dann viraz in liév i koup fénoir",
          FR: "trois kilomètres plus loin, dans un virage, un lièvre coupe",
          EN: "",
        },
      },
      {
        startSecond: 552,
        endSecond: 553,
        text: {
          CR: "i koup dovan loto",
          FR: "devant les phares",
          EN: "",
        },
      },
      {
        startSecond: 553,
        endSecond: 556,
        text: {
          CR: "Michel i frinn, i dérap, i rant dann kann",
          FR: "Michel freine, la voiture dérape",
          EN: "",
        },
      },
      {
        startSecond: 556,
        endSecond: 560,
        text: {
          CR: "i anfons ziska bor ranpar, ziska lo bor",
          FR: "s’arrête juste au bord du rempart",
          EN: "",
        },
      },
      {
        startSecond: 560,
        endSecond: 566,
        text: {
          CR: "Dann siél la line o plin, dann fon lo gran ranpar",
          FR: "Dans le ciel, la lune, dans le fond, le grand rempart",
          EN: "",
        },
      },
      {
        startSecond: 566,
        endSecond: 571,
        text: {
          CR: "E Michel, lo kor an kap-kap ki konpran k'asoir li la pass parkoté lamour",
          FR: "Et Michel, le corps tremblant comprend que ce soir, il est passé à côté de l’amour",
          EN: "",
        },
      },
      {
        startSecond: 571,
        endSecond: 575,
        text: {
          CR: "parkoté la mor prés an minm tan",
          FR: "et à côté de la mort, presque en même temps",
          EN: "",
        },
      },
      {
        startSecond: 575,
        endSecond: 578,
        text: {
          CR: "Mésié médame la sosiété",
          FR: "Messieurs, Mesdames la société",
          EN: "",
        },
      },
      {
        startSecond: 578,
        endSecond: 580,
        text: {
          CR: "zistoir i di pa kosa Michel la dovnu",
          FR: "l’histoire ne dit pas ce que Michel est devenu",
          EN: "",
        },
      },
      {
        startSecond: 580,
        endSecond: 584,
        text: {
          CR: "moin minm ki vou kozé mi tonm dann liniorans la plus totalitaire",
          FR: "moi-même qui vous parle, je suis complètement ignorant de la suite",
          EN: "",
        },
      },
      {
        startSecond: 584,
        endSecond: 587,
        text: {
          CR: "mé sat moin lé sur sé ké na d’soir lao",
          FR: "mais ce dont je suis sur, c’est que certains soirs",
          EN: "",
        },
      },
      {
        startSecond: 587,
        endSecond: 592,
        text: {
          CR: "la Plinn dé kaf dann la fré wi pé oir in lékrito briyé : Chez Maryse",
          FR: "à la plaine des cafres on peut voir briller un écriteau : Chez Maryse",
          EN: "",
        },
      },
      {
        startSecond: 592,
        endSecond: 595,
        text: {
          CR: "Somanké mésié médam la sosiété",
          FR: "Peut-être messieurs mesdames la société",
          EN: "",
        },
      },
      {
        startSecond: 595,
        endSecond: 597,
        text: {
          CR: "sat la ral zot terla sé lo mank lamour",
          FR: "que ce qui vous a ramené ici, c’est le manque d’amour",
          EN: "",
        },
      },
      {
        startSecond: 597,
        endSecond: 601,
        text: {
          CR: "le besoin de proximité, de réciprosité, tousala",
          FR: "le besoin de proximité, de réciprocité",
          EN: "",
        },
      },
      {
        startSecond: 601,
        endSecond: 605,
        text: {
          CR: "mi ziz pa mé si moin na in konsey po zot kèr maléré",
          FR: "je ne juge pas mais si j’ai un conseil pour votre cœur malheureux",
          EN: "",
        },
      },
      {
        startSecond: 605,
        endSecond: 607,
        text: {
          CR: "o lié pass la port salon d’bal la",
          FR: "au lieu de passer la porte du salon de bal",
          EN: "",
        },
      },
      {
        startSecond: 607,
        endSecond: 612,
        text: {
          CR: "odomié ou apél Shalèr Tropicale au 0692…",
          FR: "vous feriez mieux d’appeler Chaleur Tropicale au 06 92…",
          EN: "",
        },
      },
      {
        startSecond: 612,
        endSecond: 646,
        text: {
          CR: "♫ ♫ ♫ ♫ ♫",
          FR: "♫ ♫ ♫ ♫ ♫",
          EN: "",
        },
      },
    ],
  },
});
