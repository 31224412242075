import { useState } from "react";
import { useAtom } from "jotai";
import {
  currentZarboutanAtom,
  isDescriptionButtonVisibleAtom,
  isDescriptionOpenAtom,
  listenedZarboutansAtom,
  visitedZarboutansAtom,
  zarboutansAtom,
} from "../atoms/zarboutanAtoms";

const useZarboutans = () => {
  const [zarboutans] = useAtom(zarboutansAtom);
  const [currentZarboutan, setCurrentZarboutan] = useAtom(currentZarboutanAtom);
  const [listenedZarboutans, setListenedZarboutans] = useAtom(listenedZarboutansAtom);
  const [visitedZarboutans, setVisitedZarboutans] = useAtom(visitedZarboutansAtom);
  const [isDescriptionOpen, setIsDescriptionOpen] = useAtom(isDescriptionOpenAtom);
  const [isDescriptionButtonVisible, setIsDescriptionButtonVisible] = useAtom(
    isDescriptionButtonVisibleAtom
  );

  const loadZarboutan = (id) => {
    setCurrentZarboutan(zarboutans.find((z) => z.id == id));
  };

  const setZarboutanIdVisited = (id) => {
    setVisitedZarboutans([...visitedZarboutans, id]);
  };
  const setZarboutanIdListened = (id) => {
    setListenedZarboutans([...listenedZarboutans, id]);
  };

  const onZarboutanPlay = () => {
    setIsDescriptionButtonVisible(true);
    setZarboutanIdListened(currentZarboutan.id);
  };

  const onOpenDescription = () => {
    setIsDescriptionOpen(true);
  };
  const onCloseDescription = () => {
    setIsDescriptionOpen(false);
  };

  return {
    zarboutans: zarboutans.map((z) => {
      return {
        ...z,
        isVisited: visitedZarboutans.includes(z.id),
        isListened: listenedZarboutans.includes(z.id),
      };
    }),
    currentZarboutan: currentZarboutan
      ? {
          ...currentZarboutan,
          isVisited: visitedZarboutans.includes(currentZarboutan.id),
          isListened: listenedZarboutans.includes(currentZarboutan.id),
        }
      : currentZarboutan,
    listenedZarboutans,
    visitedZarboutans,
    isDescriptionOpen,
    isDescriptionButtonVisible,
    setIsDescriptionButtonVisible,
    loadZarboutan,
    setZarboutanIdVisited,
    setZarboutanIdListened,
    onZarboutanPlay,
    onOpenDescription,
    onCloseDescription,
  };
};

export default useZarboutans;
