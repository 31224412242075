import { useAtom } from "jotai";
import { isModalOpenAtom, modalContentAtom, modalTitleAtom } from "../atoms/modalAtoms";

const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useAtom(isModalOpenAtom);
  const [modalTitle, setModalTitle] = useAtom(modalTitleAtom);
  const [modalContent, setModalContent] = useAtom(modalContentAtom);

  const openModal = ({ title = null, content = null }) => {
    if (title && typeof title == "string") {
      setModalTitle(title + "");
    }
    setModalContent(content);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onModalDismiss = () => {
    closeModal();
  };

  return {
    modalTitle,
    setModalTitle,
    modalContent,
    setModalContent,
    isModalOpen,
    setIsModalOpen,
    openModal,
    closeModal,
    onModalDismiss,
  };
};

export default useModal;
