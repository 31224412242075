import { IonSpinner } from "@ionic/react";

const Loader = ({ loaderProps = {}, ...props }) => {
  return (
    <div {...props}>
      <IonSpinner name="crescent" {...loaderProps} />
    </div>
  );
};

export default Loader;
