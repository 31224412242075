import styled from "styled-components";
import { keyframes } from "styled-components";

const Bird = () => {
  return (
    <BirdContainer>
      <div className="xAxis">
        <div className="rotateAxis">
          <img className="birdAxis" src="/assets/images/birdq.png" />
        </div>
      </div>
    </BirdContainer>
  );
};

const xAxis = keyframes`
  0% {
    transform:translateX(-1%);
  }
  100% {
    transform:translateX(100%);
  }
`;

const rotateAxis = keyframes`

 to {
   transform:translateY(30px) 
 }
`;
const birdAxis = keyframes`
to {
  transform:rotate(-4deg) scale(0.7)
}

 
`;

const BirdContainer = styled.div`
  left: 0;
  position: absolute;
  width: 100%;
  top: 0;

  & .xAxis {
    width: 100%;
    height: 100px;
    transform: translateX(-1%);
    animation: ${xAxis} 75s infinite linear;
    animation-delay: 1.5s;
  }
  & .rotateAxis {
    width: 100px;
    height: 100px;
    animation: ${rotateAxis} 3s alternate infinite ease-in-out;
    animation-delay: 1.5s;

    & .birdAxis {
      opacity: 0.2;
      transform: rotate(4deg) scale(1.3);
      animation: ${birdAxis} 3s alternate infinite ease-in-out;
      animation-delay: 1.5s;
    }
  }

  & img {
    opacity: 0.4;
    width: 26px;
  }
`;

export default Bird;
