import { IonPage, IonContent, IonHeader, IonToolbar, IonButtons } from "@ionic/react";
import Button from "../../Common/Buttons/Button";
import ParallaxSlider from "./ParallaxSlider/ParallaxSlider";
import { GiCog } from "react-icons/gi";

const Home = (props) => {
  const zarboutanId = props.match?.params?.id;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <Button noArrow text={<GiCog />} style={{ border: 0 }} routerLink="/settings" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <ParallaxSlider zarboutanId={zarboutanId} />
      </IonContent>
    </IonPage>
  );
};

export default Home;
