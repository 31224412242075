import { IonButton } from "@ionic/react";
import { BsArrowRight } from "react-icons/bs";
import styled from "styled-components";

const Button = ({ text = "", noArrow = false, ...props }) => {
  return (
    <StyledButton fill="clear" {...props}>
      <span>
        {text}
        {!noArrow && <BsArrowRight style={{ verticalAlign: "middle", marginLeft: 10 }} />}
      </span>
    </StyledButton>
  );
};

const StyledButton = styled(IonButton)`
  background: ${({ transparent }) => (transparent ? "rgba(255,255,255,0.001)" : "white")};
  border: ${({ noBorder }) => (noBorder ? "" : "1px solid black")};
  padding: ${({ small }) => (small ? "0.3rem" : "0.3rem 1.3rem")};
  min-height: ${({ small }) => (small ? "30px" : "48px")};
  font-size: ${({ small }) => (small ? "17px" : "20px")};
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  transition: 400ms;
  position: relative;
  color: black;
  border-radius: 15px;
  overflow: hidden;

  & span {
    position: relative;
    z-index: 3;
  }

  &::before {
    content: "";
    height: 100%;
    background: black;
    width: 100%;
    transform: scaleX(0);
    transition: 400ms;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: left center;
  }

  &.selected {
    color: white;
    &::before {
      transform: scaleX(1);
    }
  }
`;

export default Button;
