import styled from "styled-components";
import { keyframes } from "styled-components";
import useLang from "../../../../hooks/useLang";
import useAudioDescription from "./useAudioDescription";
import { BsChevronCompactDown } from "react-icons/bs";
import Button from "../../../Common/Buttons/Button";
import AudioLangSelector from "./AudioLangSelector";

const AudioDescription = ({ closeDescription }) => {
  const { lang } = useLang();
  const { config, isPaused, getStatus, goToSentence } = useAudioDescription();
  const realLang = lang == "EN" ? "FR" : lang;

  return (
    <DescriptionContainer>
      <Button
        className="close-description-btn"
        onClick={closeDescription}
        noArrow
        small
        text={<BsChevronCompactDown />}
      />
      <AudioLangSelector />
      <SentencesContainer>
        {config.sentences.map((sentence, sindex) => {
          const status = getStatus(sindex);
          const duration = status.isActive ? sentence.endSecond - sentence.startSecond - 0.5 : 0;
          const sentenceCN = `a-sentence ${status.isPast ? "past" : ""} ${
            status.isActive ? "active" : ""
          } ${isPaused ? "paused" : ""}`;
          return (
            <Sentence
              onClick={() => goToSentence(sentence)}
              className={sentenceCN}
              key={`sentence${sentence.text[realLang]}`}
              id={`sentence${sindex}`}
              duration={duration}
            >
              {sentence.text[realLang]}
            </Sentence>
          );
        })}
      </SentencesContainer>
    </DescriptionContainer>
  );
};

const DescriptionContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 44%;
  background: white;
  border-top: 1px solid var(--ion-color-primary);
  border-bottom: 1px solid var(--ion-color-primary);
  box-shadow: 2px 0px 13px -8px var(--ion-color-primary);
  z-index: 22;

  & .close-description-btn {
    margin: 0;
    position: absolute;
    z-index: 23;
    top: -18px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 20px;
    padding: 0;
    width: 50px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--ion-color-primary);
    color: white;
    border: 1px solid var(--ion-color-primary);
  }
`;

const SentencesContainer = styled.div`
  position: relative;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 100%;

  & .a-sentence:first-child {
    padding-top: 22px;
  }
`;

const progressAnim = keyframes`
0% {
  transform:scaleX(0)
}
100% {
  transform:scaleX(1)
}
`;

const Sentence = styled.p`
  position: relative;
  z-index: 9;
  padding: 10px 15px;
  margin-bottom: 0px;
  margin-top: 0px;

  &::after {
    content: "";
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: scaleX(0);

    transform-origin: left;
    z-index: 8;
  }

  &.active.paused {
    &::after {
      animation-play-state: paused;
    }
  }

  &.active {
    &::after {
      background: rgba(49, 125, 108, 0.11);
      transform: scaleX(0);
      animation-name: ${progressAnim};
      animation-duration: ${({ duration }) => duration + "s"};
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
  }
  &.past {
    &::after {
      background: rgba(49, 125, 108, 0.25);
      transform: scaleX(1);
    }
  }
`;

export default AudioDescription;
