import {
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { BsArrowLeft, BsFacebook, BsInstagram } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import { FaLink } from "react-icons/fa";
import styled from "styled-components";
import useLang from "../../../hooks/useLang";
import useModal from "../../../hooks/useModal";
import { intl } from "../../../translations";
import Button from "../../Common/Buttons/Button";
import LegalNotice from "../../Common/LegalNotice/LegalNotice";

const Settings = (props) => {
  const { lang, changeLang } = useLang();
  const { openModal } = useModal();

  const updateLang = (e) => {
    changeLang(e.detail.value);
  };

  const reset = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const openLegalNotice = () => {
    openModal({
      title: intl.Pages.Settings.LegalNotice.PageTitle[lang],
      content: <LegalNotice />,
    });
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <Button
              noBorder
              noArrow
              text={<BsArrowLeft />}
              onClick={() => props.history.goBack()}
            />
          </IonButtons>
          <IonTitle>{intl.Pages.Settings.Title[lang]}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <ContentContainer>
          <h2 className="section__title">{intl.Pages.Settings.LanguageChangeTitle[lang]}</h2>
          <IonSegment onIonChange={updateLang}>
            <IonSegmentButton checked={lang == "FR"} value="FR">
              <IonLabel>Français</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton checked={lang == "EN"} value="EN">
              <IonLabel>English</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton checked={lang == "CR"} value="CR">
              <IonLabel>Créole</IonLabel>
            </IonSegmentButton>
          </IonSegment>

          <h2 className="section__title">{intl.Pages.Settings.Informations[lang]}</h2>

          <div style={{ textAlign: "center" }}>
            <Button
              noArrow
              noBorder
              text={<BsFacebook />}
              href="https://www.facebook.com/ZistoirFaham"
              target="_blank"
              className="social__button"
            />
            <Button
              noArrow
              noBorder
              text={<BsInstagram />}
              href="https://www.instagram.com/zistoirfaham/"
              target="_blank"
              className="social__button"
            />
            <Button
              noArrow
              noBorder
              text={<FaLink />}
              href="https://www.karanbolaz.com/cie-karanbolaz/"
              target="_blank"
              className="social__button"
            />
            <h2 className="section__title">{intl.Pages.Settings.TeamTitle[lang]}</h2>
            <div style={{ margin: "15px 0" }}>
              <a href="mailto:marion.karanbolaz@gmail.com">marion.karanbolaz@gmail.com</a>
            </div>
          </div>
          <h2 className="section__title">{intl.Pages.Settings.PartnersTitle[lang]}</h2>
          <p style={{ textAlign: "justify" }}>{intl.Pages.Settings.PartnerText[lang]}</p>
          <img src="/assets/images/logo-partners.png" />

          <div style={{ textAlign: "center" }}>
            <h2 className="section__title">{intl.Pages.Settings.CreditsTitle[lang]}</h2>
            <div className="team__member">
              <div className="team__member__name">Sergio GRONDIN</div>
              <div className="team__member__role">Écriture et voix</div>
            </div>
            <div className="team__member">
              <div className="team__member__name">
                Cie Karanbolaz et les habitants des 10 communes partenaires
              </div>
              <div className="team__member__role">Collectage et témoignages</div>
            </div>
            <div className="team__member">
              <div className="team__member__name">Alex SORRES</div>
              <div className="team__member__role">Musique</div>
            </div>
            <div className="team__member">
              <div className="team__member__name">Mélanie CHEVALLIER</div>
              <div className="team__member__role">Visuels et peintures</div>
            </div>
            <div className="team__member">
              <div className="team__member__name">
                Les Avirons, Cilaos, Entre- Deux, Saint-Louis, Etang-Salé, Le Tampon, Saint Joseph,
                Saint-Philippe, Saint-Pierre, Petite-Ile
              </div>
              <div className="team__member__role">Communes partenaires</div>
            </div>
          </div>
        </ContentContainer>
        <SectionFooter>
          <Button
            noBorder
            small
            noArrow
            transparent
            onClick={openLegalNotice}
            text={intl.Pages.Settings.LegalNotice.ButtonTitle[lang]}
          />
          {/* <Button
            noBorder
            small
            noArrow
            transparent
            text="Réinitialiser l'onboarding"
            onClick={reset}
          /> */}
        </SectionFooter>
      </IonContent>
    </IonPage>
  );
};

const SectionFooter = styled.div`
  background: #f3f2f6;
  padding: 2rem;

  & .button {
    width: 100%;
    font-size: 14px;
    text-decoration: underline;
  }
`;

const ContentContainer = styled.div`
  padding: 2rem;

  & .social__button {
    font-size: 40px;
    padding: 0px;
    margin: 0px;
    height: 55px;
    width: 70px;
  }

  & .section__title {
    margin-bottom: 1rem;
    text-align: center;
    font-weight: 800;
    font-size: 18px;
    margin: 2rem 0;
    text-transform: uppercase;
  }

  & .section__subtitle {
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  & .team__member {
    text-align: center;
    margin: 1rem 0;

    &__name {
      font-size: 12px;
      font-weight: 800;
    }
    &__role {
      font-size: 12px;
      font-style: italic;
    }
  }
`;

export default Settings;
