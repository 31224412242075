import React from "react";
import useLang from "../../../hooks/useLang";
import { SlideText, SlideTitle } from "../../../StyledComponents/StyleComponents";
import { intl } from "../../../translations";
import GoToNextSlideButton from "./GoToNextSlideButton";
import SlideContainer from "./SlideContainer";

const HistorySlide = () => {
  const { lang } = useLang();

  return (
    <SlideContainer
      nextButton={
        <GoToNextSlideButton text={intl.Pages.Onboarding.HistorySlide.ButtonText[lang]} />
      }
      imageName="history.jpg"
    >
      <SlideTitle>{intl.Pages.Onboarding.HistorySlide.Title[lang]}</SlideTitle>
      <SlideText>
        {intl.Pages.Onboarding.HistorySlide.Text[lang]}
        <img
          src="/assets/images/onboarding/logo-faham.png"
          style={{
            width: 60,
            display: "block",
            margin: "0 auto",
          }}
        />
      </SlideText>
    </SlideContainer>
  );
};

export default HistorySlide;
