export const intl = {
  Pages: {
    Onboarding: {
      LanguageSlide: {
        Title: {
          FR: "Langue",
          EN: "Language",
          CR: "Langaz",
        },
        Text: {
          FR: "Choisissez la langue dans laquelle vous souhaitez consulter l'application.",
          EN: "Choose the language in which you want to view the application.",
          CR: "Soizi la lang ou profèr po gét laplikasion.",
        },
        ButtonText: {
          FR: "Continuer",
          EN: "Continue",
          CR: "Asuiv",
        },
      },
      HistorySlide: {
        Title: {
          FR: "Découvrir l'histoire",
          EN: "Discover the story",
          CR: "Dékouv listoir",
        },
        Text: {
          FR: "Au Sud de la Réunion, découvrez les dix zistoirs racontant dix sites du Sentié Fah’Âme.",
          EN: "In the south of Reunion, discover the ten zistoirs telling ten sites of the Sentié Fah’Âme.",
          CR: "Dann sid la Rénion, dékouv di zistoir ki rakont di landroi Santié Fah’Âme.",
        },
        ButtonText: {
          FR: "J'ai compris",
          EN: "I understand",
          CR: "Ma konpri",
        },
      },
      HowToSlide: {
        Title: {
          FR: "Scannez les QR codes",
          EN: "Scan the QR codes",
          CR: "Skan lo QR codes",
        },
        Text: {
          FR: "Une fois sur place, un QR code à scanner sur les sculptures zarboutans vous donnera accès à un contenu supplémentaire.",
          EN: "Once there, a QR code to scan on the zarbutans sculptures additional will give you access to an additional content.",
          CR: "Inn foi arivé, in QR code po skané si lo bann skiltur zarboutan va donn aou aksé in kado an plis.",
        },
        ButtonText: {
          FR: "C'est parti",
          EN: "Lets go",
          CR: "Anon",
        },
      },
    },
    Splash: {
      FR: "BIENVENUE",
      EN: "WELCOME",
      CR: "BYINVNU",
    },
    Home: {
      ListenButton: {
        FR: "ÉCOUTER",
        EN: "LISTEN",
        CR: "AKOUT",
      },
      Path: {
        FR: "Itinéraire",
        EN: "Directions",
        CR: "Itinérér",
      },
      Listened: {
        FR: "déjà écouté",
        EN: "already listened",
        CR: "fine akouté",
      },
      Visited: {
        FR: "déjà visité",
        EN: "Already visited",
        CR: "Fini oir",
      },
      ToListen: {
        FR: "à écouter",
        EN: "not listened",
        CR: "po akouté",
      },
      ToVisit: {
        FR: "scannez le QR code",
        EN: "scan the QR code",
        CR: "skan lo QR code",
      },
      ComingSoon: {
        FR: "Bientôt disponible",
        EN: "Coming soon",
        CR: "Tardra vyindra",
      },
    },
    Settings: {
      Title: {
        FR: "Paramètres",
        EN: "Settings",
        CR: "Paramét",
      },
      LanguageChangeTitle: {
        FR: "Langue",
        EN: "Language",
        CR: "Lang",
      },
      CreditsTitle: {
        FR: "Crédits",
        EN: "Credits",
        CR: "Krédi",
      },
      TeamTitle: {
        FR: "Association Karanbolaz",
        EN: "Karanbolaz association",
        CR: "Lasosiasion Karanbolaz",
      },
      PartnersTitle: {
        FR: "Nos partenaires",
        EN: "Our partners",
        CR: "Lantouraz",
      },
      Informations: {
        FR: "Infos & programmation",
        EN: "Infos & programmation",
        CR: "Zinfo ék programasion",
      },
      PartnerText: {
        FR: "Ce projet est cofinancé par l’Union Européenne et le Conseil Régional de La Réunion dans le cadre du Programme de Développement Rural de La Réunion – LEADER/FEADER (Fond Européen Agricole pour le Développement Rural).",
        EN: "This project is co-financed by the European Union and the Regional Council of Reunion within the framework of the Reunion Rural Development Program – LEADER/FEADER (Fund European Agricultural for Rural Development).",
        CR: "Projé-la lé kofinansé rant l’Union Européenne ék le Conseil Régional de La Réunion dann lo kad du Programme de Développement Rural de La Réunion - LEADER/FEADER (Fond Européen Agricole pour le Développement Rural).",
      },
      LegalNotice: {
        ButtonTitle: {
          FR: "Lire les mentions légales",
          EN: "Read the legal notice",
          CR: "Lir lo bann mansion légal",
        },
        PageTitle: {
          FR: "Mentions légales",
          EN: "Legal notice",
          CR: "Mansion légal",
        },
        Text: {
          FR: "Texte des mentions légales \n retour chariot supporté",
          EN: "Legal notice text \n line break supported",
          CR: "Téx lo bann mansion légal \n rotour shario siporté",
        },
      },
    },
    Zarboutan: {
      Duration: {
        FR: "Durée",
        EN: "Duration",
        CR: "Diré",
      },
      Minutes: {
        FR: "minutes",
        EN: "minutes",
        CR: "minit",
      },
      DisplaySubtitles: {
        FR: "Afficher le texte",
        EN: "Show text",
        CR: "Oir lo kozman",
      },
    },
  },
};
