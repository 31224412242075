import ReactAudioPlayer from "react-audio-player";
import { BiPlay } from "react-icons/bi";
import styled from "styled-components";
import useAudio from "../../../../hooks/useAudio";
import useLang from "../../../../hooks/useLang";
import useZarboutans from "../../../../hooks/useZarboutans";
import AudioControls from "./AudioControls";
import Wave from "./Wave/Wave";

const AudioPlayer = ({ onPlay = () => {} }) => {
  const { lang } = useLang();
  const { currentZarboutan } = useZarboutans();
  const {
    status,
    audioFile,
    onPlayerReady,
    loadPlayerElement,
    onPlayerPlay,
    onPlayerPause,
    onPlayerEnded,
    onPlayerListen,
    playAudioFile,
  } = useAudio({ onPlay });

  const isCurrentStoneAudioLoadedInPlayer =
    audioFile?.file?.id == currentZarboutan?.audioDescription?.file?.id;

  const loadAudio = () => {
    playAudioFile(currentZarboutan.audioDescription);
    document.querySelector(".audio-duration").style.display = "none";
    console.log("called");
  };

  return (
    <StyledAudioPlayer>
      {isCurrentStoneAudioLoadedInPlayer ? (
        <>
          <AudioControls />
          <div style={{ position: "absolute", opacity: "0", top: -500 }}>
            <ReactAudioPlayer
              ref={loadPlayerElement}
              src={`/assets/audios/${audioFile.file.url}`}
              onCanPlay={onPlayerReady}
              onPlay={onPlayerPlay}
              onPause={onPlayerPause}
              onEnded={onPlayerEnded}
              listenInterval={1000}
              onListen={onPlayerListen}
              controls
              autoPlay
              title={audioFile.audioName[lang]}
            />
          </div>
          <Wave isActive={status.isPlaying} />
        </>
      ) : (
        <button className="launch-button animate__animated animate__zoomIn" onClick={loadAudio}>
          <BiPlay />
        </button>
      )}
    </StyledAudioPlayer>
  );
};

const StyledAudioPlayer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .launch-button {
    background: var(--ion-color-transparent);
    display: flex;
    font-size: 180px;
    margin-left: 10px;
    color: var(--ion-color-primary);

    & svg {
      vertical-align: middle;
    }
  }
`;

export default AudioPlayer;
