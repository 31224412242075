import { IonLabel, IonRange } from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { utils } from "../../../../helpers/utils";
import useAudio from "../../../../hooks/useAudio";

var isUserSeeking = false;

const AudioProgressBar = () => {
  const rangeRef = useRef(null);
  const { playerElement, status } = useAudio();

  const calculateTimeFromProgress = (progressValue) => {
    var durationTime = playerElement.duration;
    return (progressValue * durationTime) / 100;
  };

  const onRangeChange = (e) => {
    if (isUserSeeking) {
      playerElement.currentTime = calculateTimeFromProgress(e.detail.value);
    }
  };

  const onPointerDown = (e) => {
    isUserSeeking = true;
  };

  const onPointerUp = (e) => {
    isUserSeeking = false;
  };

  var progressPct = (playerElement?.currentTime / playerElement?.duration) * 100 || 0;

  return (
    <StyledRange
      ref={rangeRef}
      min={0}
      max={100}
      value={progressPct}
      onIonChange={onRangeChange}
      onPointerUp={onPointerUp}
      onPointerDown={onPointerDown}
      color="secondary"
      style={{ padding: 0 }}
    >
      <TimeLabel className="text__left" slot="start">
        {utils.getFormatedTime(status.progressTime)}
      </TimeLabel>
      <TimeLabel className="text__right" slot="end">
        {utils.getFormatedTime(playerElement?.duration || 0)}
      </TimeLabel>
    </StyledRange>
  );
};

const StyledRange = styled(IonRange)`
  padding: 0px;
  --bar-background: white;

  --knob-background: var(--ion-color-primary);
`;

const TimeLabel = styled(IonLabel)`
  width: 40px;
  color: black;
  font-size: 12px;
  text-align: center;
`;

export default AudioProgressBar;
