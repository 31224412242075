import React from "react";
import styled from "styled-components";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import "./styles.css";

// import required modules
import { Pagination } from "swiper";
import LanguageSlide from "./LanguageSlide";
import HistorySlide from "./HistorySlide";
import QRCodeSlide from "./QRCodeSlide";

const OnBoarding = () => {
  return (
    <>
      <div style={{ height: "100%" }}>
        <StyledOnBoardingSlider>
          <Swiper
            pagination={{
              type: "progressbar",
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <StyledContentSlider>
              <SwiperSlide>
                <LanguageSlide />
              </SwiperSlide>
              <SwiperSlide>
                <HistorySlide />
              </SwiperSlide>
              <SwiperSlide>
                <QRCodeSlide />
              </SwiperSlide>
            </StyledContentSlider>
          </Swiper>
        </StyledOnBoardingSlider>
      </div>
    </>
  );
};

const StyledOnBoardingSlider = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  & .swiper {
    height: 100%;
  }
  & .swiper-pagination {
    position: fixed;
  }
  & .swiper-wrapper {
    height: 100%;

    & .swiper-slide {
      height: 100%;
      position: relative;
    }
  }
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  .swiper-pagination-progressbar {
    top: auto;
    bottom: 0;
    background: var(--ion-color-transparent);
    &-fill {
      background: #397c7c;
    }
  }
`;

const StyledContentSlider = styled.div`
  background-color: pink;
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
export default OnBoarding;
