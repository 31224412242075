import { BsArrowRight } from "react-icons/bs";
import styled from "styled-components";
import { useSwiper } from "swiper/react";
import Button from "../../Common/Buttons/Button";

const GoToNextSlideButton = ({ text }) => {
  const swiper = useSwiper();
  return (
    <StyledNextButton
      onClick={() => swiper.slideNext()}
      text={<BsArrowRight style={{ verticalAlign: "middle" }} />}
      noArrow
    />
  );
};

const StyledNextButton = styled(Button)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 6px;
`;

export default GoToNextSlideButton;
