import styled from "styled-components";

export const StyledHistorySlide = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

export const SlideTitle = styled.strong`
  font-size: 26px;
  padding-bottom: 1rem;
  font-family: comfortaaregular;
`;

export const SlideText = styled.p`
  margin: 1rem 0;
`;
