import { useAtom } from "jotai";
import { useEffect } from "react";
import { hasLaunchedAtom } from "../atoms/globalAtoms";
import useLang from "./useLang";

const useLaunch = () => {
  const { setLang } = useLang();
  const [hasLaunched, setHasLaunched] = useAtom(hasLaunchedAtom);

  useEffect(() => {
    if (!hasLaunched) {
      if (window.navigator?.language) {
        const navLang = window.navigator.language.split("-");
        if (navLang[0]) {
          switch (navLang[0]) {
            case "en":
              setLang("EN");
              break;
            default:
              setLang("FR");
          }
        }
      }
    }
  }, []);

  return { hasLaunched, setHasLaunched };
};

export default useLaunch;
