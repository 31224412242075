import { BsArrowLeft } from "react-icons/bs";
import useAudio from "../../../hooks/useAudio";
import useZarboutans from "../../../hooks/useZarboutans";
import Button from "../../Common/Buttons/Button";

const BackAndStopButton = ({ routerProps }) => {
  const { onCloseDescription, setIsDescriptionButtonVisible } = useZarboutans();
  const { stop } = useAudio();

  const goBack = () => {
    stop();
    onCloseDescription();
    setIsDescriptionButtonVisible(false);
    routerProps.history.push(`/home/${routerProps.match.params.id}`);
  };
  return <Button noBorder noArrow text={<BsArrowLeft />} onClick={goBack} />;
};

export default BackAndStopButton;
