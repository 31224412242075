import React, { useRef, useEffect } from "react";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route, Redirect } from "react-router-dom";
import Home from "./Components/Pages/Home/Home";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Splash from "./Components/Pages/Splash/Splash";
import useLaunch from "./hooks/useLaunch";
import OnBoarding from "./Components/Pages/OnBoarding/OnBoarding";
import "animate.css";
import Zarboutan from "./Components/Pages/Zarboutan/Zarboutan";
import useSplash from "./hooks/useSplash";
import Settings from "./Components/Pages/Settings/Settings";
import Modal from "./Components/Common/Modal/Modal";

setupIonicReact({
  rippleEffect: true,
  mode: "ios",
});
function App() {
  const routerRef = useRef(null);
  const { hasLaunched } = useLaunch();
  //const hasLaunched = true;
  const { isImagesLoaded } = useSplash();

  // const passphrase = window.prompt("Ce site n'est pas encore ouvert au public");
  // if (passphrase !== "zfh") {
  //   return null;
  // }

  return (
    <IonApp>
      {isImagesLoaded ? (
        hasLaunched ? (
          <IonReactRouter>
            <IonRouterOutlet ref={routerRef}>
              <Route path="/home" component={Home} />
              <Route path="/home/:id" component={Home} />
              <Route path="/zarboutan/:id" component={Zarboutan} />
              <Route path="/settings" component={Settings} />
              <Route exact path="/" render={() => <Redirect to={"home"} />} />
            </IonRouterOutlet>
          </IonReactRouter>
        ) : (
          <OnBoarding />
        )
      ) : (
        <Splash />
      )}
      <Modal />
    </IonApp>
  );
}

export default App;
