import React from "react";
import SlideContainer from "./SlideContainer";
import { intl } from "../../../translations";
import useLang from "../../../hooks/useLang";
import useLaunch from "../../../hooks/useLaunch";
import Button from "../../Common/Buttons/Button";
import { SlideText, SlideTitle } from "../../../StyledComponents/StyleComponents";
import styled from "styled-components";
import { BsArrowRight } from "react-icons/bs";

const QRCodeSlide = () => {
  const { lang } = useLang();
  const { setHasLaunched } = useLaunch();

  return (
    <SlideContainer
      nextButton={
        <StyledNextButton
          // text={intl.Pages.Onboarding.HowToSlide.ButtonText[lang]}
          text={<BsArrowRight style={{ verticalAlign: "middle" }} />}
          noArrow
          onClick={() => setHasLaunched(true)}
        />
      }
      nextBtnAction={() => setHasLaunched(true)}
      imageName="howto.jpg"
    >
      <SlideTitle>{intl.Pages.Onboarding.HowToSlide.Title[lang]}</SlideTitle>
      <SlideText>{intl.Pages.Onboarding.HowToSlide.Text[lang]}</SlideText>
    </SlideContainer>
  );
};

const StyledNextButton = styled(Button)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 6px;
`;

export default QRCodeSlide;
