import { IonSelect, IonSelectOption } from "@ionic/react";
import styled from "styled-components";
import useLang from "../../../../hooks/useLang";

const AudioLangSelector = () => {
  const { lang, changeLang, langName } = useLang();

  const onChangeLang = (e) => {
    changeLang(e.detail.value);
  };

  return (
    <LangSelector placeholder={lang == "EN" ? "Français" : langName} onIonChange={onChangeLang}>
      <IonSelectOption checked={lang == "FR"} value="FR">
        Français
      </IonSelectOption>
      {/* <IonSelectOption checked={lang == "EN"} value="EN">
        English
      </IonSelectOption> */}
      <IonSelectOption checked={lang == "CR"} value="CR">
        Créole
      </IonSelectOption>
    </LangSelector>
  );
};

const LangSelector = styled(IonSelect)`
  position: absolute;
  top: -44px;
  right: 10px;
`;

export default AudioLangSelector;
