import { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Parallax } from "swiper";
import styled from "styled-components";
import { useState } from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import useZarboutans from "../../../../hooks/useZarboutans";
import Slide from "./Slide";
import { bgHeight } from "./sliderConfig";
import CloudsBg from "./CloudsBg";
import Bird from "./Bird";
import { utils } from "../../../../helpers/utils";

const ParallaxSlider = ({ zarboutanId }) => {
  const { zarboutans } = useZarboutans();
  const [sliderStatus, setSliderStatus] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);

  const getZarboutanIndexFromId = () => {
    if (zarboutanId) {
      return zarboutans.findIndex((z) => z.id == zarboutanId);
    }
    return 0;
  };

  useEffect(() => {
    navigationPrevRef.current.style.opacity = sliderStatus.isBeginning ? "0" : "1";
    navigationNextRef.current.style.opacity = sliderStatus.isEnd ? "0" : "1";
  }, [sliderStatus]);

  const onSlideChange = (e) => {
    setSliderStatus({
      isBeginning: e.isBeginning,
      isEnd: e.isEnd,
    });
    const zId = zarboutans[e.activeIndex].id;
    utils.replaceUrl(`/home/${zId}`);
  };

  return (
    <SwiperContainer>
      <Swiper
        initialSlide={getZarboutanIndexFromId()}
        modules={[Parallax, Navigation, Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        pagination={{
          type: "progressbar",
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        onSlideChange={onSlideChange}
        parallax={true}
        speed={1200}
        style={{ height: "100%" }}
      >
        <ParallaxBG
          slot="container-start"
          className="parallax-bg"
          style={{
            backgroundImage: "url(/assets/images/pano-slider2.jpg)",
          }}
          data-swiper-parallax="-80%"
        />
        <BirdBg slot="container-start" data-swiper-parallax="-80%">
          <Bird />
        </BirdBg>

        <CloudsBg slot="container-start" />

        {zarboutans.map((zar, ik) => {
          return (
            <SwiperSlide key={`swik${zar.id}`}>
              <SlideContent>
                <Slide zarboutan={zar} />
              </SlideContent>
            </SwiperSlide>
          );
        })}

        <PrevBtn ref={navigationPrevRef}>
          <StyledLeftArrow />
        </PrevBtn>
        <NextBtn ref={navigationNextRef}>
          <StyledRightArrow />
        </NextBtn>
      </Swiper>
    </SwiperContainer>
  );
};

const SwiperContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: white;

  & .swiper-wrapper {
    height: 100%;

    & .swiper-slide {
      height: 100%;
      position: relative;
    }
  }

  .swiper-slide-active {
    & .zarboutan-img-container {
      & img {
        transform: scale(1);
      }
    }
  }

  .swiper-pagination-bullet {
    background: white;
    border: 1px solid black;
  }

  .swiper-pagination-progressbar {
    top: auto;
    bottom: 0;
    background: var(--ion-color-transparent);
    &-fill {
      background: #397c7c;
    }
  }
`;

const SlideContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

const StyledLeftArrow = styled(BsArrowLeft)`
  vertical-align: middle;
  filter: drop-shadow(2px 2px 6px black);
`;
const StyledRightArrow = styled(BsArrowRight)`
  vertical-align: middle;
  filter: drop-shadow(2px 2px 6px black);
`;

const ParallaxBG = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 1000%;
  height: ${bgHeight};
  background-size: 100% 100%;
  background-position: left bottom;
  background-repeat: no-repeat;

  /* &::after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: linear-gradient(0deg, white 11px, transparent 138px);
    width: 100%;
    height: 100%;
  } */
`;

const BirdBg = styled.div`
  position: absolute;
  left: 0;
  top: 7%;
  width: 1000%;
`;

const PrevBtn = styled.div`
  position: absolute;
  top: 50%;
  color: white;
  left: 20px;
  z-index: 9;
  font-size: 40px;
  width: 40px;
  height: 40px;
`;
const NextBtn = styled.div`
  position: absolute;
  top: 50%;
  color: white;
  right: 20px;
  z-index: 9;
  font-size: 40px;
  width: 40px;
  height: 40px;
`;

export default ParallaxSlider;
