import styled from "styled-components";
import { keyframes } from "styled-components";
import { bgHeight } from "./sliderConfig";

const CloudsBg = () => {
  return (
    <StyledCloudsBg>
      <img alt="" src="/assets/images/cloud1.png" style={{ "--i": 1 }} />
      <img alt="" src="/assets/images/cloud2.png" style={{ "--i": 2 }} />
      <img alt="" src="/assets/images/cloud3.png" style={{ "--i": 3 }} />
      <img alt="" src="/assets/images/cloud4.png" style={{ "--i": 4 }} />
      <img alt="" src="/assets/images/cloud5.png" style={{ "--i": 5 }} />
    </StyledCloudsBg>
  );
};

const cloudAnimation = keyframes`
 0% {
   transform:translateX(-100%)
 }
 100% {
   transform:translateX(100%)
 }
`;

const StyledCloudsBg = styled.div`
  position: absolute;
  width: 100%;
  height: ${bgHeight};
  z-index: 9;
  height: 10%;
  top: 55.1%;
  pointer-events: none;
  background: linear-gradient(
    0deg,
    white 24px,
    rgba(255, 255, 255, 0.5) 37px,
    rgba(255, 255, 255, 0.001) 55px
  );

  overflow: hidden;
  & img {
    position: absolute;
    bottom: calc(var(--i) * 3px);
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    animation: ${cloudAnimation} calc(10s * var(--i)) linear infinite;
  }
`;

export default CloudsBg;
