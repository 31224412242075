import { useEffect } from "react";
import { IonImg } from "@ionic/react";
import styled from "styled-components";
import { keyframes } from "styled-components";
import Loader from "../../Common/Loader/Loader";
import useSplash from "../../../hooks/useSplash";

var nbImagesLoaded = 0;
const assetsFolder = "/assets/images/";
const images = {
  splash: ["logo-karanbolaz.jpg", "logo-partners.png", "logo.png", "splash-bg.png"],
  home: [
    "pano-slider2.jpg",
    "cloud1.png",
    "cloud2.png",
    "cloud3.png",
    "cloud4.png",
    "cloud5.png",
    "zarboutan.png",
  ],
};

const Splash = (props) => {
  const { setIsImagesLoaded } = useSplash();

  useEffect(() => {
    loadImages("splash");
  }, []);

  const loadImages = (page) => {
    images[page].forEach((image) => {
      preloadImage(`${assetsFolder}${image}`, page);
    });
  };

  const preloadImage = (url, page) => {
    var img = new Image();
    img.src = url;
    img.addEventListener("load", () => {
      nbImagesLoaded++;
      checkAllImagesLoaded(page);
    });
  };

  const checkAllImagesLoaded = (page) => {
    if (nbImagesLoaded == images[page].length) {
      nbImagesLoaded = 0;
      if (page == "splash") {
        onSplashImagesLoaded();
        loadImages("home");
      } else {
        setTimeout(() => {
          onAllImagesLoaded();
          setTimeout(() => {
            changePage();
          }, 700);
        }, 2000);
      }
    }
  };

  const onSplashImagesLoaded = () => {
    document.querySelector("#splash-content").style.opacity = "1";
    document.querySelector("#splash-loader").style.color = "white";
    document.querySelector("#splash-logo").classList.add("animate__animated", "animate__fadeInUp");
  };
  const onAllImagesLoaded = () => {
    document.querySelector("#splash-container").classList.add("disappear");
    document.querySelector("#splash-content").classList.add("disappear");
  };

  const changePage = () => {
    setIsImagesLoaded(true);
  };

  return (
    <AnimatedDiv id="splash-container">
      <StyledLoader id="splash-loader" />

      <StyledSplash id="splash-content">
        <StyledLogo id="splash-logo" src="/assets/images/logo.png" />
        <StyledBg />
        <StyledLogoAssoc src="/assets/images/logo-karanbolaz.jpg" />
        {/* <StyledLogoPartner src="/assets/images/logo-partners.png" /> */}
      </StyledSplash>
    </AnimatedDiv>
  );
};

const StyledLoader = styled(Loader)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  z-index: 10;
  color: black;
`;

const fadeOut = keyframes`
0% {opacity:1}
100% {opacity:0}
`;

const slideOutFwdCenter = keyframes`
0% {
    transform: translateZ(0);
  }
  100% {
    transform: translateZ(600px);
  }
`;

const AnimatedDiv = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  perspective: 1000;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9;

  &.disappear {
    animation: ${fadeOut} 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
`;

const StyledSplash = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9;
  background: white;
  opacity: 0;
  transition: 400ms;
  &.disappear {
    animation: ${slideOutFwdCenter} 0.7s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  }
`;

const StyledLogo = styled(IonImg)`
  height: 24%;
`;
const StyledLogoAssoc = styled(IonImg)`
  height: 12%;
`;
const StyledLogoPartner = styled(IonImg)`
  height: 8%;
  margin-top: 5%;
`;
const StyledBg = styled.div`
  width: 100%;
  height: 46%;
  background-image: url(/assets/images/splash-bg.png);
  background-size: 100% 100%;
  background-position: bottom center;
  position: relative;
  overflow: hidden;
`;

export default Splash;
