import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { BsFillSignpostSplitFill } from "react-icons/bs";
import styled from "styled-components";
import useModal from "../../../hooks/useModal";
import CloseButton from "../Modal/CloseButton";

const DirectionMap = ({ coordinates }) => {
  const { modalTitle } = useModal();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            <BsFillSignpostSplitFill />
            <span style={{ textTransform: "uppercase", paddingLeft: "8px" }}>{modalTitle}</span>
          </IonTitle>
          <IonButtons slot="end">
            <CloseButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <iframe
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
        src={`https://maps.google.com/maps?q=${coordinates.lat},${coordinates.lng}&hl=fr&z=14&output=embed`}
      />
      <LoadingMap>Chargement de la carte...</LoadingMap>
    </IonPage>
  );
};

const LoadingMap = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
  font-weight: 600;
`;

export default DirectionMap;

// <div style="width: 100%">
//   <iframe
//     width="100%"
//     height="600"
//     frameborder="0"
//     scrolling="no"
//     marginheight="0"
//     marginwidth="0"
//     src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
//   >
//     <a href="https://www.gps.ie/marine-gps/">shipping gps</a>
//   </iframe>
// </div>;
