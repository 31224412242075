import { useAtom } from "jotai";
import { langAtom } from "../atoms/globalAtoms";

const useLang = () => {
  const [lang, setLang] = useAtom(langAtom);

  const changeLang = (newLang) => {
    setLang(newLang);
  };

  const langNames = {
    FR: "Français",
    EN: "English",
    CR: "Créole",
  };

  return {
    lang,
    setLang,
    changeLang,
    langName: langNames[lang],
  };
};

export default useLang;
