import { IonModal } from "@ionic/react";
import useModal from "../../../hooks/useModal";
import CloseButton from "./CloseButton";

const Modal = () => {
  const { isModalOpen, modalTitle, modalContent, onModalDismiss, closeModal } = useModal();

  return (
    <IonModal isOpen={isModalOpen} cssClass="my-custom-class" onDidDismiss={onModalDismiss}>
      {modalContent}
    </IonModal>
  );
};

export default Modal;
