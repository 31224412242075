import { IonButton, IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import styled from "styled-components";
import useModal from "../../../hooks/useModal";

const CloseButton = () => {
  const { closeModal } = useModal();

  return (
    <CloseBtn fill="clear" color="dark" onClick={closeModal}>
      <IonIcon icon={closeOutline} />
    </CloseBtn>
  );
};

const CloseBtn = styled(IonButton)``;

export default CloseButton;
