import { GrBackTen, GrForwardTen } from "react-icons/gr";
import styled from "styled-components";
import useAudio from "../../../../hooks/useAudio";
import AudioProgressBar from "./AudioProgressBar";
import PlayerButton from "./PlayerButton";
import PlayPauseButtons from "./PlayPauseButtons";

const AudioControls = () => {
  const { advance5Seconds, back5Seconds } = useAudio();

  return (
    <ActionButtonsContainer>
      <ActionButtonsGroup>
        <PlayerButton onClick={back5Seconds} size="small">
          <GrBackTen />
        </PlayerButton>
        <PlayPauseButtons />
        <PlayerButton onClick={advance5Seconds} size="small">
          <GrForwardTen />
        </PlayerButton>
      </ActionButtonsGroup>

      <ActionButtonsGroup style={{ padding: "0 20px" }}>
        <AudioProgressBar />
      </ActionButtonsGroup>
    </ActionButtonsContainer>
  );
};

const ActionButtonsContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 9;
`;

const ActionButtonsGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

export default AudioControls;
