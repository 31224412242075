import styled from "styled-components";

const SlideContainer = ({ imageName, nextButton, children }) => {
  return (
    <StyledSlide>
      <div className="image__block">
        <img src={`/assets/images/onboarding/${imageName}`} />
      </div>
      <div className="content__block">{children}</div>
      <div className="button__block">{nextButton}</div>
    </StyledSlide>
  );
};

const StyledSlide = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 2rem;

  & .image__block {
    min-height: 40%;
    max-height: 40%;

    display: flex;
    flex-direction: column;
    width: auto;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;

    & img {
      max-height: 100%;
    }
  }

  & .content__block {
    height: 50%;
    width: 100%;
  }

  & .button__block {
    height: 10%;
  }
`;

export default SlideContainer;
