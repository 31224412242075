import { IonButtons, IonContent, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import styled from "styled-components";
import useModal from "../../../hooks/useModal";
import CloseButton from "../Modal/CloseButton";

const LegalNotice = () => {
  const { modalTitle } = useModal();

  return (
    <IonPage>
      <IonToolbar>
        <IonTitle>
          <span style={{ textTransform: "uppercase" }}>{modalTitle}</span>
        </IonTitle>
        <IonButtons slot="end">
          <CloseButton />
        </IonButtons>
      </IonToolbar>
      <IonContent>
        <StyledParagraph>
          <p>
            Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en
            l'économie numérique, il est précisé aux utilisateurs du site Zistoir Faham l'identité
            des différents intervenants dans le cadre de sa réalisation et de son suivi.
          </p>
          <h2>Edition du site</h2>
          <p>
            Le présent site, accessible à l’URL https://www.zistoir-faham.re (le « Site »), est
            édité par l’association Compagnie Karanbolaz, enregistrée auprès de la
            préfecture/sous-préfecture de 974 - Sous-Préfecture Saint-Pierre sous le numéro
            W9R2003216, ayant son siège situé à 5 rue voltaire Saint-Joseph 97480, représentée par
            Thierry Benoit-Gonin dûment habilité(e)
          </p>
          <h2>Hébergement</h2>
          <p>
            Le Site est hébergé par la société Scalingo, située 15 AV DU RHIN 67100 STRASBOURG,
            France, (contact téléphonique ou email : +33555686363).
          </p>
          <h2>Directeur de publication</h2>
          <p>Le Directeur de la publication du Site est Sergio Grondin.</p>
          <h2>Nous contacter</h2>
          <ul>
            <li>Par téléphone : +33692495028</li>
            <li>Par email : karanbolaz@gmail.com</li>
            <li>Par courrier : 25 rue des zinnias Saint-Joseph 97480</li>
          </ul>
          <h2>Données personnelles</h2>
          <p>
            Le traitement de vos données à caractère personnel est régi par notre Charte du respect
            de la vie privée, disponible depuis la section "Charte de Protection des Données
            Personnelles", conformément au Règlement Général sur la Protection des Données 2016/679
            du 27 avril 2016 («RGPD»).
          </p>
          <p>Génération des mentions légales par Legalstart.</p>
        </StyledParagraph>
      </IonContent>
    </IonPage>
  );
};

const StyledParagraph = styled.div`
  padding: 2rem;
  white-space: pre-line;
`;

export default LegalNotice;
