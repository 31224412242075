import styled from "styled-components";

const PlayerButton = ({ children, ...props }) => {
  return <StyledPlayerButton {...props}>{children}</StyledPlayerButton>;
};

const StyledPlayerButton = styled.button`
  background: var(--ion-color-transparent);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--ion-color-primary);
  font-size: ${({ size }) => (size == "small" ? "40px" : "80px")};
  padding: 0px 5px;

  & svg {
    vertical-align: middle;

    & path {
      stroke: var(--ion-color-primary);
    }
  }
`;

export default PlayerButton;
