import { GiPsychicWaves, GiStarKey } from "react-icons/gi";
import { BsFillSignpostSplitFill } from "react-icons/bs";

export const VisitedIcon = (props = {}) => {
  return <GiStarKey {...props} />;
};

export const ListenedIcon = (props = {}) => {
  return <GiPsychicWaves {...props} />;
};
export const DirectionIcon = (props = {}) => {
  return <BsFillSignpostSplitFill {...props} />;
};
