import { BsPause } from "react-icons/bs";
import { BiPlay } from "react-icons/bi";
import useAudio from "../../../../hooks/useAudio";
import useZarboutans from "../../../../hooks/useZarboutans";
import Loader from "../../../Common/Loader/Loader";
import PlayerButton from "./PlayerButton";

const PlayPauseButtons = () => {
  const { status, play, pause } = useAudio();
  const { isDescriptionOpen } = useZarboutans();

  if (status.isAudioLoading) {
    return (
      <Loader
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "var(--ion-color-primary)",
          width: 162,
          height: 150,
        }}
        loaderProps={{ style: { width: 100, height: 100, color: "white" } }}
      />
    );
  }

  const btnFontSize = isDescriptionOpen ? 108 : 150;
  const btnOffset = isDescriptionOpen ? 4 : 10;

  return status.isPlaying ? (
    <PlayerButton onClick={pause} style={{ fontSize: btnFontSize }}>
      <BsPause />
    </PlayerButton>
  ) : (
    <PlayerButton
      className="animate__animated animate__zoomIn animate__faster"
      onClick={play}
      style={{ fontSize: btnFontSize, position: "relative", left: btnOffset }}
    >
      <BiPlay />
    </PlayerButton>
  );
};

export default PlayPauseButtons;
