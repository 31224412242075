import { useAtom } from "jotai";
import {
  audioAtom,
  isPlayingAtom,
  isPlayerOpenAtom,
  isAudioLoadingAtom,
  playerElementAtom,
  progressTimeAtom,
} from "../atoms/audioAtoms";

const useAudio = ({ onPlay = () => {} } = {}) => {
  const [playerElement, setPlayerElement] = useAtom(playerElementAtom);
  const [audioFile, setAudioFile] = useAtom(audioAtom);
  const [isPlaying, setIsPlaying] = useAtom(isPlayingAtom);
  const [isPlayerOpen, setIsPlayerOpen] = useAtom(isPlayerOpenAtom);
  const [isAudioLoading, setIsAudioLoading] = useAtom(isAudioLoadingAtom);
  const [progressTime, setProgressTime] = useAtom(progressTimeAtom);

  const playAudioFile = (audioDescription) => {
    if (audioDescription.file.id == audioFile?.file?.id) {
      play();
    } else {
      setAudioFile(audioDescription);
      setIsPlayerOpen(true);
      setIsAudioLoading(true);
    }
  };

  const loadPlayerElement = (element) => {
    setPlayerElement(element?.audioEl?.current);
  };

  const openPlayer = () => {
    setIsPlayerOpen(true);
  };

  const closePlayer = () => {
    setIsPlayerOpen(false);
    setIsPlaying(false);
    setAudioFile(null);
  };

  const pause = () => {
    if (playerElement) {
      playerElement.pause();
    }
  };
  const stop = () => {
    if (playerElement) {
      playerElement.pause();
      setAudioFile(null);
    }
  };

  const play = () => {
    if (playerElement) {
      playerElement.play();
      setIsPlaying(true);
      onPlay();
    } else {
      openPlayer();
    }
  };

  const onPlayerReady = () => {
    play();
  };

  const onPlayerPlay = () => {
    setIsAudioLoading(false);

    setIsPlaying(true);
  };

  const onPlayerPause = () => {
    setIsPlaying(false);
  };
  const onPlayerEnded = () => {
    setIsPlaying(false);
  };

  const onPlayerListen = (time) => {
    setIsPlaying(true);
    setIsAudioLoading(false);
    setProgressTime(time);
  };

  const getFormatedTime = (time) => {
    let seconds = Math.round(time);
    let minutes = Math.floor(seconds / 60);
    let secondsLeft = seconds % 60;
    secondsLeft = secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft;
    minutes = isNaN(minutes) ? "--" : minutes;
    secondsLeft = isNaN(secondsLeft) ? "--" : secondsLeft;

    return `${minutes}:${secondsLeft}`;
  };

  const getAudioDuration = () => {
    if (playerElement) {
      return playerElement.duration;
    }
  };

  const advance5Seconds = () => {
    if (playerElement) {
      const newTime = playerElement.currentTime + 10;
      playerElement.currentTime =
        newTime < playerElement.duration ? newTime : playerElement.duration;
    }
  };
  const back5Seconds = () => {
    if (playerElement) {
      const newTime = playerElement.currentTime - 10;
      playerElement.currentTime = newTime >= 0 ? newTime : 0;
    }
  };

  const goToTime = (second) => {
    if (playerElement) {
      playerElement.currentTime = second;
    }
  };

  return {
    playerElement,
    audioFile,
    status: {
      isPlayerOpen,
      isPlaying,
      isAudioLoading,
      progressTime,
    },
    setAudioFile,
    setIsPlaying,
    playAudioFile,
    openPlayer,
    closePlayer,
    play,
    pause,
    stop,
    onPlayerReady,
    loadPlayerElement,
    onPlayerPlay,
    onPlayerPause,
    onPlayerEnded,
    onPlayerListen,
    getAudioDuration,
    getFormatedTime,
    advance5Seconds,
    back5Seconds,
    goToTime,
  };
};

export default useAudio;
