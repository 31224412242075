import styled from "styled-components";
import useLang from "../../../../hooks/useLang";
import { intl } from "../../../../translations";
import Button from "../../../Common/Buttons/Button";
import { ListenedIcon, VisitedIcon } from "../../../Common/ZarboutanIcons/ZarboutanIcons";
import { BsFillSignpostSplitFill } from "react-icons/bs";
import { BiPlay } from "react-icons/bi";
import { bgHeight } from "./sliderConfig";
import useModal from "../../../../hooks/useModal";
import DirectionMap from "../../../Common/DirectionMap/DirectionMap";

const Slide = ({ zarboutan }) => {
  const { lang } = useLang();
  const { openModal } = useModal();

  const openDirectionModal = () => {
    openModal({
      title: zarboutan.name[lang],
      content: <DirectionMap coordinates={zarboutan.coordinates} />,
    });
  };

  const listenedColor = zarboutan.isListened ? "black" : "grey";
  const listenedText = zarboutan.isListened
    ? intl.Pages.Home.Listened[lang]
    : intl.Pages.Home.ToListen[lang];
  const visitedColor = zarboutan.isVisited ? "black" : "grey";
  const visitedText = zarboutan.isVisited
    ? intl.Pages.Home.Visited[lang]
    : intl.Pages.Home.ToVisit[lang];

  return (
    <div style={{ height: "100%" }}>
      <ZarboutanImgContainer
        className="zarboutan-img-container"
        data-swiper-parallax="-100"
        isActive={zarboutan.isActive}
        imageOffset={zarboutan.imageOffset}
      >
        <img
          alt={zarboutan.name[lang]}
          className="zarboutan-img"
          src={`/assets/images/zarboutan${zarboutan.isActive ? zarboutan.id : "-disabled"}.png`}
        />
      </ZarboutanImgContainer>
      <ZarboutanNameContainer isActive={zarboutan.isActive}>
        <div data-swiper-parallax="-100" className="zarboutan-name">
          <div className="zarboutan-location">{zarboutan.locationName[lang]}</div>
          <span>{zarboutan.name[lang]}</span>
        </div>
      </ZarboutanNameContainer>
      <SlideActionsContainer data-swiper-parallax="-500">
        {zarboutan.isActive ? (
          <ListenButton
            routerLink={`/zarboutan/${zarboutan.id}`}
            text={
              <div className="listen-button-play-icon">
                <BiPlay style={{ fontSize: 44 }} />
                {/* {intl.Pages.Home.ListenButton[lang]} */}
              </div>
            }
            noBorder
            noArrow
          />
        ) : (
          <div className="coming__soon">
            <div>{intl.Pages.Home.ComingSoon[lang]}</div>
            <div className="coming__at">{zarboutan.comingAt[lang]}</div>
          </div>
        )}
        {zarboutan.isActive ? (
          <div className="infos__container">
            <div>
              <div className="status__info" style={{ color: listenedColor }}>
                <ListenedIcon /> {listenedText}
              </div>
              <div className="status__info" style={{ color: visitedColor }}>
                <VisitedIcon /> {visitedText}
              </div>
            </div>
            <div>
              <Button
                onClick={openDirectionModal}
                small
                noArrow
                noBorder
                text={
                  <>
                    {<BsFillSignpostSplitFill />} {intl.Pages.Home.Path[lang]}
                  </>
                }
              />
            </div>
          </div>
        ) : null}
      </SlideActionsContainer>
    </div>
  );
};

const ListenButton = styled(Button)`
  height: 98px;
  width: 98px;
  margin: 0px;
  padding: 0px;
  border: 1px solid var(--ion-color-primary);

  border-radius: 50%;

  & .listen-button-play-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 80px;
    color: var(--ion-color-primary);
    padding-left: 7px;
  }
`;

const SlideActionsContainer = styled.div`
  position: absolute;
  top: 65%;
  width: 100%;
  left: 0;
  height: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
  padding-bottom: 2rem;
  padding-top: 0;

  & .coming__soon {
    text-align: center;

    & .coming__at {
      font-size: 14px;
    }
  }

  & .infos__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    & .status__info {
      display: flex;
      align-items: center;
      padding-bottom: 0.3rem;
      font-style: italic;
      & svg {
        margin-right: 8px;
      }
    }
  }
`;

const ZarboutanImgContainer = styled.div`
  position: absolute;
  top: calc(${bgHeight} - 264px);
  left: calc(50% - ${({ imageOffset }) => (imageOffset ? 35 + (imageOffset.x || 0) : 35)}px);
  z-index: 2;

  & img {
    width: 70px;
    /* opacity: 0; */
    transform-origin: center bottom;
    transform: scale(0.5);
    transition: 1.4s;
    /* transition-delay: 1s; */
  }
`;

const ZarboutanNameContainer = styled.div`
  position: absolute;
  top: 0%;
  text-align: center;
  font-weight: 400;
  color: black;
  font-size: 24px;
  transform: translateX(-50%);
  left: 50%;
  height: 48%;
  text-align: center;
  width: 100%;
  opacity: ${({ isActive }) => (isActive ? "1" : "0.3")};

  & .zarboutan-name {
    position: relative;
    height: 100%;
    text-transform: uppercase;

    & .zarboutan-location {
      font-size: 12px;
    }

    & span {
      /* border-bottom: 1px solid black; */
      position: relative;
      padding-bottom: 4px;
      &::before {
        content: "";
        position: absolute;
        background: black;
        width: 100%;
        height: 1px;
        top: 100%;
        left: 0;
      }
      &::after {
        content: "";
        position: absolute;
        background: linear-gradient(0deg, var(--ion-color-transparent) 40%, black);
        width: 1px;
        height: 1000%;
        left: 50%;
        top: 100%;
      }
    }
  }
`;

export default Slide;
