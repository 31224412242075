import { useAtom } from "jotai";
import { isImagesLoadedAtom } from "../atoms/globalAtoms";

const useSplash = () => {
  const [isImagesLoaded, setIsImagesLoaded] = useAtom(isImagesLoadedAtom);

  return {
    isImagesLoaded,
    setIsImagesLoaded,
  };
};

export default useSplash;
